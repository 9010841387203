import React, { useState, useEffect  } from "react";
import GuestsTable from "./GuestsTable";
import Modal from "./Modal";
import GuestPropButton from "./GuestPropButton";
import AddNewCommentButton from "./AddNewCommentButton";
import HostSelector from "./HostSelector";


const CurrentGuestsTable = ({pickedDate, hosts, apartments}) => {

    const [guests, setGuests] = useState([]);
    const [tableTitles, setTableTitles] = useState('a mai nap itt tartózkodó vendégek');
    const [commentfieldState, setCommentfieldState] = useState({show:false, rowIndex:null});
    const [openRegisterModal, setOpenRegisterModal] = useState({show:false});


    useEffect(() => {
      const pickedDateInDateFormat = new Date(pickedDate);
      pickedDateInDateFormat.setMinutes(pickedDateInDateFormat.getMinutes() - pickedDateInDateFormat.getTimezoneOffset()); 
      const pickedDateInString = pickedDateInDateFormat.toISOString().slice(0,10)

      const todayInDateFormat = new Date();
      todayInDateFormat.setMinutes(todayInDateFormat.getMinutes() - todayInDateFormat.getTimezoneOffset()); 
      const todayInString = todayInDateFormat.toISOString().slice(0,10)

      let pickedDateObj = new Date(pickedDateInString);
      let todayObj = new Date(todayInString);
            
      let guestStatus = 10;

      if (pickedDateObj < todayObj) {
        console.log('MÚLT');
        setTableTitles('ezen a napon itt tartózkodott vendégek')
      } else if (pickedDateObj > todayObj) {
        console.log('JÖVŐ');
        setTableTitles('ezen a napon itt tartózkodó vendégek')
      } else {
        console.log('JELEN');
        setTableTitles('a mai nap itt tartózkodó vendégek')
        guestStatus = 1;
      }

        let url = process.env.REACT_APP_BACKEND_LINK + "/querycurrentsguests.php";
        let bodyText = `today=${pickedDateInString}&status=${guestStatus}`; 

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
          .then((res) => res.json())
          .then((response) => {

            response.forEach((item) => {
              let apartmentName = '';
              apartments.forEach((apartment) => {
                if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                  apartmentName = apartment.short_name;
                }
              })
              item.apartment_unit_id = apartmentName;
            })

            response.forEach((item) => {                                          //commentek hozzáadása
              if(item.commentsArray !== '' && item.commentsArray !== null) {
                let commentsObj = {}
                let commentsArr = item.commentsArray.split('<>');
                let commentsIDsArr = item.commentIDsArray.split('<>');
                let targetsArr = item.targetsArray.split('<>');

                commentsArr.forEach((comment, index) => {
                  commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                })
                item['commentsObj'] = commentsObj;
              } else {
                item['commentsObj'] = {};
              }
                delete item['commentIDsArray'];
                delete item['commentsArray'];
                delete item['targetsArray'];
            })

            setGuests(response)
        })
      }, [pickedDate, apartments])

      const columns = [
        {
          Header: "rescode",
          accessor: "rescode"
        },    
        {
          Header: "personalinfos",
          accessor: "personalinfos",
          disableSortBy: true,
          Cell: ({ row })  => (<GuestPropButton row={row} setGuests={setGuests} setOpenRegisterModal={setOpenRegisterModal} />   )     
        },
        {
          Header: "name",
          accessor: "resname",
          width: 400
        },
        {
          Header: "arriving time",
          accessor: "arrivaltime"
        },
        {
          Header: "departuredate",
          accessor: "departuredate"
        },
        {
          Header: "departure time",
          accessor: "departuretime"
        },
        {
          Header: "people",
          accessor: "people",
          width: 90,
        },
        {
          Header: "apartment",
          accessor: "apartment_unit_id"
        },
        {
          Header: "host",
          accessor: "host",
          Cell: ({ row })  => { 
            
          //  console.log(row.original.host)
            
            return ( <HostSelector hosts={hosts} 
                                              res_ID={row.original.res_ID} 
                                              rowIndex={row.id}
                                              defaultHost={row.original.host}
                                              defaultTask={row.original.hosttask}
                                /> )}

        },      
        {
          Header: "",
          accessor: "comment",
          disableSortBy: true,
          Cell: ({ row })  => (<AddNewCommentButton commentfieldState={commentfieldState}
                                                    setCommentfieldState={setCommentfieldState}
                                                    row={row}
                                                      />   )     
        },      
        {
          Header: "button",
          accessor: "button",
          disableSortBy: true
        }      
      ];


      return ( 
        <>
           {openRegisterModal.show && openRegisterModal.show && (
              <Modal
                openRegisterModal={openRegisterModal}
                setOpenRegisterModal={setOpenRegisterModal}
                setGuests={setGuests}
                guests={guests}   
              />
           )}


          <h2>{tableTitles}</h2>
                  <GuestsTable
                      columns={columns}
                      data={guests}   
                      hosts={hosts}   
                      commentfieldState={commentfieldState}  
                      setCommentfieldState={setCommentfieldState}   
                      setGuests={setGuests}
                      setOpenRegisterModal={setOpenRegisterModal}
                  />
        </>
      )
      
}

export default CurrentGuestsTable;