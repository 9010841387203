import React, { useState, useCallback } from 'react';
import './DropdownMenuWithCheckboxes.css';

const DropdownMenuWithCheckboxes = ({ roles, setSelectedRoles, selectedRoles, disabled }) => {

  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);


const handleRoleSelection = useCallback((element) => {
  const value = parseInt(element.target.value);

  if (selectedRoles.includes(value)) {
    setSelectedRoles(selectedRoles.filter((item) => item !== value));
  } else {
    setSelectedRoles([...selectedRoles, value]);
  }
}, [setSelectedRoles, selectedRoles]);


//console.log(selectedRoles)
  return (
    <div className="dropdown">
      <button onClick={toggleOpen}>Dropdown</button>
      <div className={`dropdown-menu ${dropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
        {roles.map((role, index) => (
          <label key={'label' + index} className="dropdown-item">
            <input type="checkbox" 
                   name={'role' + index} 
                   value={index} 
                   checked={selectedRoles.includes(index)} 
                   onChange={handleRoleSelection} 
                   disabled={disabled}
            />            
          {role}
          </label>
          ))}        
      </div>
    </div>
  );

};

export default DropdownMenuWithCheckboxes;