import React, { useState, useEffect  } from "react";
import CleanerTaskSelector2 from "./CleanerTaskSelector2";
import CleanerSelector2 from "./CleanerSelector2";
import ApartmentModal from "./ApartmentModal";
import DeparturingGuestsPanel from "./DeparturingGuestsPanel";

const ArrivingGuestsForSupervisors = ({pickedDate, cleaners, apartmentUnits, cleaningPrices, hosts}) => {

    const [apartmentsTraffic, setApartmentsTraffic] = useState([]);
    const [smallCleanings, setSmallCleanings] = useState([]);
    const [openModal, setOpenModal] = useState({show:false});
    const [key, setKey] = useState(0);
    const [addedCleaningTasks, setAddedCleaningTasks] = useState([]);

    const pickedDateInString = pickedDate.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

    const preferredCleaningTask = "normál takarítás"

    useEffect(() => {
        setApartmentsTraffic([]);
        setSmallCleanings([]);
        setAddedCleaningTasks([]);
        setOpenModal({show:false});
        }, [pickedDate]);

        useEffect(() => {
            if (!openModal.show) {
              setKey(key + 1);
            }
          }, [openModal]);


    useEffect(() => {
        if (apartmentUnits && apartmentUnits.length !== 0) {
      
          let cleaningStatus = 10;
          let url =
            process.env.REACT_APP_BACKEND_LINK + "/query_guest_traffic_for_cleaning.php";
          let bodyText = `queryday=${pickedDateInString}`;
      
          let fetchArr = [
            fetch(url, {
              method: "POST",
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
              body: bodyText,
            }),
            fetch(`${process.env.REACT_APP_BACKEND_LINK}/querysmallcleanings.php`, {
              method: "POST",
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
              body: `queryday=${pickedDateInString}`,
            }),
            fetch(process.env.REACT_APP_BACKEND_LINK + "/query_cleaning_tasks_for_supervisors.php", {
              method: "POST",
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
              body: `date=${pickedDateInString}`,
            }),
          ];
      
          Promise.all(fetchArr)
            .then((responses) => Promise.all(responses.map((res) => res.json())))
            .then((data) => {
              let guestTraffic = data[0];
              let smallCleanings = data[1];
              let allCleaningTasks = data[2];

console.log(guestTraffic)
console.log(smallCleanings)
console.log(allCleaningTasks)


 /*
//---- érkezős/távozós takarítások              
              response.forEach((item) => {      //apartment_unit_id átírása short_name-re az érkező/távozó vendégeknél
                let apartmentName = "";
                apartmentUnits.forEach((apartment) => {
                  if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                    apartmentName = apartment.short_name;
                  }
                });
                item.apartment_short_name = apartmentName;
              });
    
              response.forEach((item) => {  //hostname átírása az érkező/távozó vendégeknél
                let hostName = "-";
                hosts.forEach((host) => {
                  if (host.employee_ID === item.host) {
                    hostName = host.name;
                  }
                });
                item.host = hostName;
              });
                               
              response.forEach((item) => {       //commentek hozzáadása az érkező/távozó vendégekhez
                if (item.commentsArray !== "" && item.commentsArray !== null) {
                  let commentsObj = {};
                  let commentsArr = item.commentsArray.split("<>");
                  let commentsIDsArr = item.commentIDsArray.split("<>");
                  let targetsArr = item.targetsArray.split("<>");
                  
                  if(targetsArr[0].includes("1")) {
                    commentsArr.forEach((comment, index) => {
                    commentsObj[commentsIDsArr[index]] = {
                      comment: comment,
                      commentID: commentsIDsArr[index],
                      target: targetsArr[index],
                    };              
                  });
                }
                  item["commentsObj"] = commentsObj;
                } else {
                  item["commentsObj"] = {};
                }
                delete item["commentIDsArray"];
                delete item["commentsArray"];
                delete item["targetsArray"];
              });
      
              let allApartments = [];
      
              apartmentUnits.forEach((apartment) => {
                let apartmentObject = {
                  apartment_short_name: apartment.short_name,
                  apartment_unit_id: apartment.apartment_unit_ID,
                  arrivals: [],
                  departures: [],
                  task_comment: {},
                  category: 1,
                  cleaning_status: 0,
                };
      
                response.forEach((item) => {
                  if (item.apartment_short_name === apartment.short_name) {
                    if (item.arrivaldate === pickedDateInString) {
                      apartmentObject.arrivals.push(item);
                    }
                    if (item.departuredate === pickedDateInString) {
                      apartmentObject.departures.push(item);
                    }
                    apartmentObject.cleaning_price = item.cleaning_price;
                  }
                });
                    
                if (apartmentObject.arrivals.length !== 0 || apartmentObject.departures.length !== 0) {
                  allApartments.push(apartmentObject);
                }
              });
      

              if(allCleaningTasks.length !== 0) {
                allApartments.forEach((apartment) => {
                  allCleaningTasks.forEach((task) => {
                    if (task.apartment_unit_id === apartment.apartment_unit_id) {
                    
                      const cleaningTaskForThisApartment = cleaningPrices.find((currTask) => currTask.apartment_unit_id === apartment.apartment_unit_id &&
                                                                                              currTask.cleaning_ID ===  task.cleaning_ID);
                      let basePrice = cleaningTaskForThisApartment.price;

                      apartment['status'] = task.status;
                      apartment['cleaning_ID'] = task.cleaning_ID;
                      apartment['cleaner_ID'] = task.employee_ID;
                      apartment['cleaning_price'] = task.price;
                      apartment['cleaning_status'] = task.status !== undefined ? parseInt(task.status) : 0;
                      apartment['cleaning_baseprice'] = basePrice;
                      apartment['comment'] = task.comment;


                        if (task.task_comments !== "" && task.task_comments !== null) {
                          let commentsObj = {};
                          let commentsArr = task.task_comments.split("<>");
                          commentsArr.forEach((comment, index) => {
                            commentsObj[index] = {
                              comment: comment,
                            };
                          });
                          apartment["task_comment"] = commentsObj;
                        } else {
                          apartment["task_comment"] = {};
                        }

                    }
                  })
                })
              }

            //  console.log(allApartments);
           // setApartmentsTraffic(allApartments);

            


  //----hozzáadott takarítások
            const filteredCleaningTasks = allCleaningTasks.filter((task) => {
              let found = false;
              allApartments.forEach((apartment) => {
                if(apartment.apartment_unit_id === task.apartment_unit_id) {
                  found = true;
                }
              })
              return !found;
            })

            let addedCleaningTasks = [];

            if(filteredCleaningTasks.length !== 0) {

              filteredCleaningTasks.forEach((item) => {      
                let apartmentName = "";
                apartmentUnits.forEach((apartment) => {
                  if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                    apartmentName = apartment.short_name;
                  }
                });
                item.apartment_short_name = apartmentName;
              });

                filteredCleaningTasks.forEach((item) => {
                  let apartmentObject = {
                    apartment_short_name: item.apartment_short_name,
                    apartment_unit_id: item.apartment_unit_id,
                    arrivals: [],
                    departures: [],
                    cleaning_status: parseInt(item.cleaning_status),
                    cleaning_ID: item.cleaning_ID,
                    cleaner_ID: item.employee_ID,
                    cleaning_price: item.price,
                    cleaning_baseprice: item.price,
                    comment: item.comment == null ? '' : item.comment,  
                    category: 2,
                  };

                  if (item.task_comments !== undefined && item.task_comments !== null) {
                    let commentsObj = {};
                    let commentsArr = item.task_comments.split("<>");
                    commentsArr.forEach((comment, index) => {
                      commentsObj[index] = {
                        comment: comment,
                      };
                    });
                    apartmentObject["task_comment"] = commentsObj;
                  } else {
                    apartmentObject["task_comment"] = {};
                  }

                  addedCleaningTasks.push(apartmentObject);
                })

                if(smallCleanings.length !== 0) {
                  addedCleaningTasks.forEach((apartment) => {
                    smallCleanings.forEach((task) => {
                      if (task.apartment_unit_id === apartment.apartment_unit_id) {
                        apartment['people'] = task.people;
                      }
                    })
                  })                  
                }



                if(allCleaningTasks.length !== 0) {
                  addedCleaningTasks.forEach((apartment) => {
                    allCleaningTasks.forEach((task) => {
                      if (task.apartment_unit_id === apartment.apartment_unit_id) {
                        apartment['cleaning_status'] = parseInt(task.status);
                      }
                    })
                  })
                }
    

                allApartments = allApartments.concat(addedCleaningTasks);
            }


  //-----------------kis takarítások      
console.log(smallCleanings)
            if(smallCleanings.length !== 0) {

              if(filteredCleaningTasks.length !== 0 || addedCleaningTasks.length !== 0) {
                let filteredSmallCleanings = [];

                smallCleanings.forEach((item) => {
                  let found = false;
                  addedCleaningTasks.forEach((addedTask) => {
                    if(parseInt(item.apartment_unit_id) === parseInt(addedTask.apartment_unit_id)) {
                      found = true;
                    }
                  })
                  if(!found) {
                    filteredSmallCleanings.push(item);
                  }
                })
                  smallCleanings = filteredSmallCleanings;               
              }


              let smallCleaningTasks = [];

              smallCleanings.forEach((item) => {   
                let apartmentName = "";
                apartmentUnits.forEach((apartment) => {
                  if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                    apartmentName = apartment.short_name;
                  }
                });
                item.apartment_short_name = apartmentName;
              });


              smallCleanings.forEach((item) => {
                let apartmentObject = {
                  apartment_short_name: item.apartment_short_name,
                  apartment_unit_id: item.apartment_unit_id,
                  arrivals: [],
                  departures: [],
                  cleaning_status: parseInt(item.cleaning_status),
                  cleaning_ID: item.cleaning_ID,
                  cleaner_ID: item.employee_ID,
                  cleaning_price: item.price,
                  cleaning_baseprice: item.price,
                  comment: item.comment == null ? '' : item.comment,
                  people: item.people,
                  departuredate: item.departuredate,   
                  category: 3,   
                };

                if (item.task_comments !== undefined && item.task_comments !== null) {
                  let commentsObj = {};
                  let commentsArr = item.task_comments.split("<>");
                  commentsArr.forEach((comment, index) => {
                    commentsObj[index] = {
                      comment: comment,
                    };
                  });
                  apartmentObject["task_comment"] = commentsObj;
                } else {
                  apartmentObject["task_comment"] = {};
                }
                
                smallCleaningTasks.push(apartmentObject);
              })

            //  setSmallCleanings(smallCleaningTasks);
            allApartments = allApartments.concat(smallCleaningTasks);

            }  

 //           console.log(allApartments)

            setApartmentsTraffic(allApartments);

*/    
            })
            .catch((error) => console.log(error));
        }
        
      }, [pickedDateInString, apartmentUnits]);
    


    const openTheModal = (apartment, type) => {
          console.log(apartment);
        
          let cleaningInfo = {};
          cleaningInfo.cleaning_ID = apartment.cleaning_ID;
          cleaningInfo.cleaning_price = apartment.cleaning_price;
          cleaningInfo.cleaning_baseprice = apartment.cleaning_baseprice;
          cleaningInfo.cleaner_ID = apartment.cleaner_ID;
          cleaningInfo.comment = apartment.comment;
          cleaningInfo.cleaning_status = parseInt(apartment.cleaning_status);
        
          let apartmentInfo = {
            apartment_unit_id: apartment.apartment_unit_id,
            apartment_short_name: apartment.apartment_short_name,
            task_comment: apartment.task_comment,
          }

          let reservationInfo = {};

          if(type === 0) {
            reservationInfo = apartment.departures[0];
           // apartmentInfo.commentsObj = apartment.departures[0].commentsObj === undefined ? {} : apartment.departures[0].commentsObj;
          }
          if(type === 1) {
            reservationInfo = apartment.arrivals[0];
          //  apartmentInfo.commentsObj = apartment.arrivals[0].commentsObj === undefined ? {} : apartment.arrivals[0].commentsObj;
          }
          
            setOpenModal(openModal => ({...openModal, show:true, apartmentInfo:apartmentInfo, reservationInfo: reservationInfo, cleaningInfo: cleaningInfo}))      
    }


    const HandleInspectButtonClick = (apartment_unit_id) => {

    setApartmentsTraffic(prevState => {
        const newApartmentsTraffic = [...prevState];
        const index = newApartmentsTraffic.findIndex((apartment) => apartment.apartment_unit_id === apartment_unit_id);
        newApartmentsTraffic[index].cleaning_status = 2;
        return newApartmentsTraffic;
    });

      let url = process.env.REACT_APP_BACKEND_LINK + "/apartmentInspectionFinish.php";
      let bodyText = `apartment_unit_ID=${apartment_unit_id}&date=${pickedDateInString}`;        

      fetch(url, {
          method: "POST",
          headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
      .then((res) => res.json() )
      .then(response => {

         // setKey(key + 1);
          
      })
      .catch(error => console.log(error));
    }



    const changeDatabase = (modifiedApartmentInfo) => {
console.log(apartmentsTraffic)
      if(modifiedApartmentInfo.cleaning_ID === undefined) {
            const cleaningTaskForThisApartment = cleaningPrices.find((task) => task.apartment_unit_id === modifiedApartmentInfo.apartment_unit_id && 
                                                                            task.cleaning_name === preferredCleaningTask);
                modifiedApartmentInfo.cleaning_ID = cleaningTaskForThisApartment.cleaning_ID;
                modifiedApartmentInfo.cleaning_price = modifiedApartmentInfo.cleaning_price === undefined ? cleaningTaskForThisApartment.price : modifiedApartmentInfo.cleaning_price;
                modifiedApartmentInfo.cleaning_baseprice = cleaningTaskForThisApartment.price;    
        }

        if(modifiedApartmentInfo.cleaner_ID !== undefined) {
            let url = process.env.REACT_APP_BACKEND_LINK + "/addnewcleaningtask.php";
            let bodyText = `apartment_unit_ID=${modifiedApartmentInfo.apartment_unit_id}&cleaning_ID=${modifiedApartmentInfo.cleaning_ID}&employee_ID=${modifiedApartmentInfo.cleaner_ID}&price=${modifiedApartmentInfo.cleaning_price}&date=${pickedDateInString}&comment=${modifiedApartmentInfo.comment}`;
            
            fetch(url, {
                method: "POST",
                headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: bodyText
                })
            .then((res) => res.json() )
            .then(response => {
                //console.log(response)

                if(!openModal.show) {
                setKey(key + 1);
                }
            })
            .catch(error => console.log(error));
        }

    }

//---------------- érkezős/távozós takarítások
    const handleCleanerChange = (event) => {
        console.log('cleaner')
        var index = event.target.selectedIndex;
        var optionElement = event.target.childNodes[index]
        var apartmentID =  optionElement.getAttribute('data-apartmentid');

        var modifiedApartmentInfo = {};
        apartmentsTraffic.forEach((apartment) => {
            if(apartment.apartment_unit_id === apartmentID) {
                modifiedApartmentInfo['apartment_unit_id'] = apartment.apartment_unit_id;
                modifiedApartmentInfo['cleaning_ID'] = apartment.cleaning_ID;
                modifiedApartmentInfo['cleaner_ID'] = event.target.value;
                modifiedApartmentInfo['cleaning_price'] = apartment.cleaning_price;
                modifiedApartmentInfo['cleaning_baseprice'] = apartment.cleaning_baseprice;
                modifiedApartmentInfo['comment'] = apartment.comment;
                modifiedApartmentInfo['task_comment'] = apartment.task_comment;                
            }
        })
//        console.log(modifiedApartmentInfo)

        setApartmentsTraffic(prevState => {
            const newApartmentsTraffic = [...prevState];
            const index = newApartmentsTraffic.findIndex((apartment) => apartment.apartment_unit_id === apartmentID);
            newApartmentsTraffic[index].cleaner_ID = event.target.value;
            return newApartmentsTraffic;
        });

        changeDatabase(modifiedApartmentInfo)
    };
    

    const handleCleanerTaskChange = (event) => {
        console.log('cleanertask')
        var index = event.target.selectedIndex;
        var optionElement = event.target.childNodes[index]
        var apartmentID =  optionElement.getAttribute('data-apartmentid');
        var price =  optionElement.getAttribute('data-price');

        let modifiedApartmentInfo = {};
        apartmentsTraffic.forEach((apartment) => {
            if(apartment.apartment_unit_id === apartmentID) {
                modifiedApartmentInfo['apartment_unit_id'] = apartment.apartment_unit_id;
                modifiedApartmentInfo['cleaning_ID'] = event.target.value;
                modifiedApartmentInfo['cleaner_ID'] = apartment.cleaner_ID;
                modifiedApartmentInfo['cleaning_price'] =  price;
                modifiedApartmentInfo['cleaning_baseprice'] = price;
                modifiedApartmentInfo['comment'] = apartment.comment;
                modifiedApartmentInfo['task_comment'] = apartment.task_comment;                
            }
        })

        setApartmentsTraffic(prevState => {
            const newApartmentsTraffic = [...prevState];
            const index = newApartmentsTraffic.findIndex((apartment) => apartment.apartment_unit_id === apartmentID);
            newApartmentsTraffic[index].cleaning_ID = event.target.value;
            newApartmentsTraffic[index].cleaning_price = price;
            newApartmentsTraffic[index].cleaning_baseprice = price;
            return newApartmentsTraffic;
        });

        changeDatabase(modifiedApartmentInfo)
    };
    
    const handleTaskPriceChange = (event) => {
      console.log('taskprice changed')
      var apartmentID = event.target.name;
      var price = event.target.value;

      let modifiedApartmentInfo = {};
      apartmentsTraffic.forEach((apartment) => {
          if(apartment.apartment_unit_id === apartmentID) {
              modifiedApartmentInfo['apartment_unit_id'] = apartment.apartment_unit_id;
              modifiedApartmentInfo['cleaning_ID'] = apartment.cleaning_ID;
              modifiedApartmentInfo['cleaner_ID'] = apartment.cleaner_ID;
              modifiedApartmentInfo['cleaning_price'] = price;
              modifiedApartmentInfo['cleaning_baseprice'] = apartment.cleaning_baseprice;
              modifiedApartmentInfo['comment'] = apartment.comment;
              modifiedApartmentInfo['task_comment'] = apartment.task_comment;                
            }
      })

      setApartmentsTraffic(prevState => {
          const newApartmentsTraffic = [...prevState];
          const index = newApartmentsTraffic.findIndex((apartment) => apartment.apartment_unit_id === apartmentID);
         newApartmentsTraffic[index].cleaning_price = price;
         return newApartmentsTraffic;
      });

      changeDatabase(modifiedApartmentInfo)
  };


  const handleCommentChange = (event) => {
    console.log('comment changed')
    var apartmentID = event.target.name;
    var comment = event.target.value;

    let modifiedApartmentInfo = {};
    apartmentsTraffic.forEach((apartment) => {
        if(apartment.apartment_unit_id === apartmentID) {
            modifiedApartmentInfo['apartment_unit_id'] = apartment.apartment_unit_id;
            modifiedApartmentInfo['cleaning_ID'] = apartment.cleaning_ID;
            modifiedApartmentInfo['cleaner_ID'] = apartment.cleaner_ID;
            modifiedApartmentInfo['cleaning_price'] = apartment.cleaning_price;
            modifiedApartmentInfo['cleaning_baseprice'] = apartment.cleaning_baseprice;
            modifiedApartmentInfo['comment'] = comment;
        }
    })

    setApartmentsTraffic(prevState => {
        const newApartmentsTraffic = [...prevState];
        const index = newApartmentsTraffic.findIndex((apartment) => apartment.apartment_unit_id === apartmentID);
       newApartmentsTraffic[index].comment = comment;
       return newApartmentsTraffic;
    });
console.log(modifiedApartmentInfo)
console.log(apartmentsTraffic)
    changeDatabase(modifiedApartmentInfo)
};


//-----------------kis takarítások

const handleCleanerChangeForSmallCleanings = (event) => {
  console.log('cleaner for small cleanings')
  var index = event.target.selectedIndex;
  var optionElement = event.target.childNodes[index]
  var apartmentID =  optionElement.getAttribute('data-apartmentid');

  const cleaningTaskForThisApartment = cleaningPrices.filter((task) => task.apartment_unit_id === apartmentID);                                        
  var smallCleaningObj = cleaningTaskForThisApartment.find((cleaningTask) => cleaningTask.cleaning_name === 'kis takarítás'); 
  var smallCleaningID = smallCleaningObj.cleaning_ID;
  var smallCleaningPrice = smallCleaningObj.price;

  var modifiedApartmentInfo = {};
  apartmentsTraffic.forEach((apartment) => {
      if(apartment.apartment_unit_id === apartmentID) {
          modifiedApartmentInfo['apartment_unit_id'] = apartment.apartment_unit_id;
          modifiedApartmentInfo['cleaning_ID'] = smallCleaningID;
          modifiedApartmentInfo['cleaner_ID'] = event.target.value;
          modifiedApartmentInfo['cleaning_price'] = smallCleaningPrice;
          modifiedApartmentInfo['cleaning_baseprice'] = apartment.cleaning_baseprice;
          modifiedApartmentInfo['comment'] = apartment.comment;
          modifiedApartmentInfo['task_comment'] = apartment.task_comment;                
        }
  })
//        console.log(modifiedApartmentInfo)

  setApartmentsTraffic(prevState => {
      const newApartmentsTraffic = [...prevState];
      const index = newApartmentsTraffic.findIndex((apartment) => apartment.apartment_unit_id === apartmentID);
      newApartmentsTraffic[index].cleaner_ID = event.target.value;
      newApartmentsTraffic[index].cleaning_ID = smallCleaningID;
      newApartmentsTraffic[index].cleaning_price = smallCleaningPrice;
      newApartmentsTraffic[index].category = 2;
      return newApartmentsTraffic;
  });

  changeDatabase(modifiedApartmentInfo)
};


//------------------------------------------------------------------------------



console.log(apartmentsTraffic);

/*
console.log(apartmentsTraffic);
console.log(smallCleanings);
console.log(addedCleaningTasks);
*/



/*
      return (
        <div key={key}>
      
      {openModal.show && openModal.show && (
                    <ApartmentModal
                      openModal={openModal}
                      setOpenModal={setOpenModal}

                      preferredCleaningTask={preferredCleaningTask}
                      cleaningPrices={cleaningPrices}

                      handleTaskPriceChange={handleTaskPriceChange}
                      handleCommentChange={handleCommentChange}
                      setApartmentsTraffic={setApartmentsTraffic}
                      pickedDateInString={pickedDateInString}
                      />
                 )}
      
      
          {Object.keys(apartmentsTraffic).length > 0 && apartmentsTraffic.map((apartment, index) => {

if(apartment.category === 1 && apartment.cleaning_status !== 2) {
              let departuringGuest = apartment.departures[0] === undefined ? undefined : apartment.departures[0];
              let arrivingGuest = apartment.arrivals[0] === undefined ? undefined : apartment.arrivals[0];
              let stayForHowManyDays = 0;
      
              if (arrivingGuest !== undefined) {
                const arrivingDate = new Date(arrivingGuest.arrivaldate);
                const departureDate = new Date(arrivingGuest.departuredate);
                const timeDiff = Math.abs(departureDate.getTime() - arrivingDate.getTime());
                stayForHowManyDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
              }
      
     
      const cleaningTaskForThisApartment = cleaningPrices.filter((task) => task.apartment_unit_id === apartment.apartment_unit_id);                                        
      
      
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "3px solid brown",
                    borderRadius: "10px",
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: apartment.cleaning_status === 0 ? 'white' : "#9df29d"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "5px"
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                        {apartment.apartment_short_name}
                    </div>



                    {apartment.cleaning_status === 0 ? (
              <>
                        <div>
                        <CleanerTaskSelector2 cleaningTaskForThisApartment={cleaningTaskForThisApartment} 
                                              onCleanerTaskChange={handleCleanerTaskChange} 
                                              assignedCleaningTask={apartment.cleaning_ID}
                                              index={index}
                                              apartment_unit_ID={apartment.apartment_unit_id}
                                              preferredCleaningTask={preferredCleaningTask}
                        />
                        </div>
                        <div>
                        <CleanerSelector2 cleaners={cleaners} 
                                          onCleanerChange={handleCleanerChange} 
                                          assignedCleaner={apartment.cleaner_ID}
                                          index={index}
                                          apartment_unit_ID={apartment.apartment_unit_id}
                                          apartmentTraffic={apartmentsTraffic}
                        />
                        </div>
              </>                        
            ) : (
              <>
                        <div>
                          <button onClick={()=>{HandleInspectButtonClick(apartment.apartment_unit_id)}}>ELLENŐRIZVE</button>
                          </div>
              </>
            )}  


                  </div>
      


                  <DeparturingGuestsPanel departuringGuest={departuringGuest} 
                                          openTheModal={openTheModal} 
                                          apartment={apartment}
                  />
      
     
      
      {arrivingGuest === undefined ? null : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid black",
                        width: "100%",
                        backgroundColor: "white"
                      }}
                      onClick={() => openTheModal(apartment, 1)}
                      >
                      <div style={{ padding: "5px", fontWeight: "bold" }}>
                        ÉRKEZÉS
                      </div>
                      <div style={{ padding: "5px", borderRight: "1px solid black" }}>
                        {arrivingGuest.resname}
                      </div>
                      <div style={{ padding: "5px", borderRight: "1px solid black" }}>
                        {arrivingGuest.people} fő
                      </div>
                      <div
                        style={{
                          padding: "5px",
                          backgroundColor:
                            stayForHowManyDays >= 5 ? "red" : "white",
                          borderRight: "1px solid black"
                        }}
                      >
                        {stayForHowManyDays} nap
                      </div>
                      <div style={{ padding: "5px" }}>
                        érkezés:{" "}
                        {arrivingGuest.arrivaltime === "" ? "-" : arrivingGuest.arrivaltime}
                      </div>
                    </div>
                  )}
      
                  {arrivingGuest !== undefined && arrivingGuest.commentsObj !== undefined &&
                    Object.keys(arrivingGuest.commentsObj).map((comment, index) => {
                      if (arrivingGuest.commentsObj[comment].target.includes("1")) {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid black",
                              backgroundColor: "lightblue",
                              width: "100%"
                            }}
                          >
                            <div
                              style={{
                                padding: "5px",
                                fontWeight: "bold",
                                width: "100%"
                              }}
                            >
                              {arrivingGuest.commentsObj[comment].comment}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
      


                    {Object.keys(apartment.task_comment).length === 0 ? null : (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          border: "1px solid black",
                                          backgroundColor: "lightblue",
                                          width: "100%"
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: "5px",
                                            fontWeight: "bold",
                                            width: "100%"
                                          }}
                                          onClick={() => openTheModal(apartment, 2)}
                                        >
                                          {Object.keys(apartment.task_comment).map((comment, index) => {
                                            return (
                                              <div key={index}>
                                                {apartment.task_comment[comment].comment}
                                              </div>
                                            )
                                          }
                                            )}
                                        </div>
                                      </div>
                                        )
                    }


                </div>
                  
              );

                  }

            })}
      

 {Object.keys(apartmentsTraffic).length > 0 && apartmentsTraffic.map((apartment, index) => {

if(apartment.category === 2 && apartment.cleaning_status !== 2) {
  const cleaningTaskForThisApartment = cleaningPrices.filter((task) => task.apartment_unit_id === apartment.apartment_unit_id);                                        

  return (
  <div>
  <p>------- hozzáadott takarítások -------</p>

          <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            border: "3px solid brown",
            borderRadius: "10px",
            width: "100%",
            marginBottom: "10px",
            backgroundColor: apartment.cleaning_status === 0 ? 'white' : "#9df29d"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px"
            }}
          >
            <div style={{ fontWeight: "bold" }}>             
              <button onClick={() => openTheModal(apartment, 2)}>  {apartment.apartment_short_name} </button>
            </div>

            <div>
              {apartment.people} fő
            </div>

            {apartment.cleaning_status === 0 ? (
              <>
                        <div>
                        <CleanerTaskSelector2 cleaningTaskForThisApartment={cleaningTaskForThisApartment} 
                                              onCleanerTaskChange={handleCleanerTaskChange} 
                                              assignedCleaningTask={apartment.cleaning_ID}
                                              index={index}
                                              apartment_unit_ID={apartment.apartment_unit_id}
                                              preferredCleaningTask={preferredCleaningTask}
                        />
                        </div>
                        <div>
                        <CleanerSelector2 cleaners={cleaners} 
                                          onCleanerChange={handleCleanerChange} 
                                          assignedCleaner={apartment.cleaner_ID}
                                          index={index}
                                          apartment_unit_ID={apartment.apartment_unit_id}
                                          apartmentTraffic={apartmentsTraffic}
                        />
                        </div>
              </>                        
            ) : (
              <>
                        <div>
                          <button onClick={()=>{HandleInspectButtonClick(apartment.apartment_unit_id)}}>ELLENŐRIZVE</button>
                          </div>
              </>
            )}            



          </div>

          {Object.keys(apartment.task_comment).length === 0 ? null : (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid black",
              backgroundColor: "lightblue",
              width: "100%"
            }}
          >            
            <div
              style={{
                padding: "5px",
                fontWeight: "bold",
                width: "100%"
              }}
              onClick={() => openTheModal(apartment, 2)}
            >
             {Object.keys(apartment.task_comment).map((comment, index) => {
                                            return (
                                              <div key={index}>
                                                {apartment.task_comment[comment].comment}
                                              </div>
                                            )
                                          }
                                            )}
            </div>
          </div>
            )}

        </div>
    </div>        
  )
}
 })}

{Object.keys(apartmentsTraffic).length > 0 && apartmentsTraffic.map((apartment, index) => {

if(apartment.category === 3 && apartment.cleaning_status !== 2) {
  return (
  <div>
  <p>------- ütemezendő kis takarítások -------</p>

          <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            border: "3px solid brown",
            borderRadius: "10px",
            width: "100%",
            marginBottom: "10px",
            backgroundColor: "#9df29d"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px"
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              {apartment.apartment_short_name}
            </div>

            <div>
              {apartment.people} fő
            </div>

            <div>
              távozás: {apartment.departuredate}
            </div>


            <div>
              <CleanerSelector2 cleaners={cleaners} 
                                  onCleanerChange={handleCleanerChangeForSmallCleanings} 
                                  assignedCleaner={apartment.cleaner_ID}
                                  index={index}
                                  apartment_unit_ID={apartment.apartment_unit_id}
                                  apartmentTraffic={apartmentsTraffic}
                />
            </div>
          </div>
        </div>
    </div>        
  )
}
 })}

        </div>
      );
 */     

}
export default ArrivingGuestsForSupervisors;

