import React, { useState, useEffect, useRef } from 'react';
import "./styles/AddNewReservationsStyles.css"
import {personalDataFields} from "./components/PersonalDataFields"
import Calendar from 'react-calendar';
import RenderPersonalData from './components/RenderPersonalData';
import ApartmentUnitSelector from './components/ApartmentUnitSelector';


function AddNewReservations() {

  const [activeTab, setActiveTab] = useState("newReservation");
  const [personalData, setPersonalData] = useState([]);

  const [inputs, setInputs] = useState({people: 1});
  const [arrivalDatePickerState, setArrivalDatePickerState] = useState(false);
  const [departureDatePickerState, setDepartureDatePickerState] = useState(false);
  const [arrivalDatePickerVisibility, setArrivalDatePickerVisibility] = useState(false);
  const [departureDatePickerVisibility, setDepartureDatePickerVisibility] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const [apartmentCapacity, setApartmentCapacity] = useState(1);
  const [peopleInputState, setPeopleInputState] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const arrivalInputRef = useRef(null);
  const departureInputRef = useRef(null);


useEffect(() => {

let arrivalObj; 
let departureObj;

    if(inputs.arrivaldate && inputs.arrivaldate.length === 10){
        arrivalObj = new Date(inputs.arrivaldate);
    }
    if(inputs.departuredate && inputs.departuredate.length === 10){
        departureObj = new Date(inputs.departuredate);
    }

    if(arrivalObj !== undefined && departureObj !== undefined) {

      departureObj < arrivalObj ? arrivalInputRef.current.style.backgroundColor = 'red' : arrivalInputRef.current.style.backgroundColor = 'white';
      arrivalObj > departureObj ? departureInputRef.current.style.backgroundColor = 'red' : departureInputRef.current.style.backgroundColor = 'white';

        if(inputs.rescode && inputs.resname && inputs.arrivaldate && inputs.departuredate && inputs.apartment && inputs.people
        && departureObj >= arrivalObj && arrivalObj <= departureObj) {
            setSubmitButtonState(false)
        } else {
            setSubmitButtonState(true)
        }
    }
  }, [inputs]);


  const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name === 'people' && value > apartmentCapacity) {
            value = apartmentCapacity;
        }
        setInputs(values => ({...values, [name]: value}))
  }


  const handleAddReservation = () => {

    let url = process.env.REACT_APP_BACKEND_LINK + "/insert_new_reservation.php";
  
    let formData = {
      'rescode' : inputs.rescode,
      'resname' : inputs.resname,
      'arrivaldate' : inputs.arrivaldate,
      'arrivaltime' : inputs.arrivaltime,
      'departuredate' : inputs.departuredate,
      'departuretime' : inputs.departuretime,
      'people' : inputs.people,
      'apartment_unit_id' : inputs.apartment
    };

    let personalDataArray = [];

personalData.map((item, index) => (
    personalDataArray.push({
        'firstname' : item.firstname,
        'lastname' : item.lastname,
        'birthdate' : item.birthdate,
        'placeofbirth' : item.placeofbirth,
        'nationality' : item.nationality,
        'zipcode' : item.zipcode,
        'country' : item.country,
        'city' : item.city,
        'address' : item.address,
        'documenttype' : item.documenttype,
        'documentnumber' : item.documentnumber
    })
))
/*
console.log(personalDataArray)
console.log(formData)
console.log(JSON.stringify({formData, personalDataArray}))
*/
     fetch(url, {
        method: 'POST',
        body: JSON.stringify({formData, personalDataArray}),
        headers: {
          'Content-type' : 'application/json; charset=UTF-8',
        }
     })
      .then((res) => res.json() )
      .then(response => {  
         
        setInputs([]);
        setPersonalData([]);
        setArrivalDatePickerState(false);
        setDepartureDatePickerState(false);
        setArrivalDatePickerVisibility(false);
        setDepartureDatePickerVisibility(false);
        setSubmitButtonState(true);
        setApartmentCapacity(1);
        setPeopleInputState(true);

//apartmentSelectRef.current.value = "0";

setSubmitClicked(true);
setTimeout(() => {
      setSubmitClicked(false);
      setInputs({"people": 1, "apartment": 1})
}, 500);
      })
      .catch(error => console.log(error));      
  };




  const dateValidator = date => {
    const [year, month, day] = date.split('-');
    const d = new Date(year, month - 1, day);
    return d.getFullYear() == year && d.getMonth() + 1 == month && d.getDate() == day;
  };

  const handleDateInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value.length >= 1) {        
        if(name === "arrivaldate") {
            setArrivalDatePickerState(!dateValidator(value))
            setArrivalDatePickerVisibility(false)
        }
        if(name === "departuredate") {
            setDepartureDatePickerState(!dateValidator(value))
            setDepartureDatePickerVisibility(false)
        }
    } else {
        if(name === "arrivaldate") {
            setArrivalDatePickerState(false)
        }
        if(name === "departuredate") {
            setDepartureDatePickerState(false)
        }
    }
    
    setInputs(values => ({...values, [name]: value}))
  }

  const dateChanger = (value, name) => {
    const arrivalsOnThisDayInDate = new Date(value);
    arrivalsOnThisDayInDate.setMinutes(arrivalsOnThisDayInDate.getMinutes() - arrivalsOnThisDayInDate.getTimezoneOffset()); 
    const arrivalsOnThisDayString = arrivalsOnThisDayInDate.toISOString().slice(0,10)

    setInputs(values => ({...values, [name]: arrivalsOnThisDayString}))
    setArrivalDatePickerVisibility(false)
    setDepartureDatePickerVisibility(false)
}


const handleApartmentChange = (event) => {

  const value = event.target.value;
    if( value === "0") {
        setInputs(values => ({...values, "people": 1}))
        setPeopleInputState(true)
        setApartmentCapacity(1)
        setSubmitButtonState(true)
        setInputs(({apartment, ...rest}) => rest);
    } else {
        setInputs(values => ({...values, "apartment": value}))

        let bodyText = `apartment_unit_id=${value}`; 
        let url = process.env.REACT_APP_BACKEND_LINK + "/queryApartmentSize.php";
        
        fetch(url, {
            method: "POST",
            headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: bodyText
        })
        .then((res) => res.text())
        .then((response) => {
            const size = Number(response);
            setApartmentCapacity(size)
            setPeopleInputState(false)
                if (size < inputs.people) {
                    setInputs(values => ({...values, "people": size}))
                }
        });  
    }     
}

useEffect(() => {
  let arr = [];
    for(let i=0; i<inputs.people; i++) {
      arr.push({});
    }
      setPersonalData(arr);
}, [inputs.people])





  return (
    <div className="reservation-form">
      <ul className="tabs">
        <li className={activeTab === "newReservation" ? "active" : ""} onClick={() => setActiveTab("newReservation")}>Új foglalás hozzáadása</li>
        <li className={activeTab === "massReservation" ? "active" : ""} onClick={() => setActiveTab("massReservation")}>Tömeges hozzáadás</li>
      </ul>

      {activeTab === "newReservation" && (
        <>
        <div className="new-reservation-section">
          <h2>Új foglalás hozzáadása</h2>
          <div className="reservation-data-section">
            <fieldset>
              <legend>Alapadatok</legend>

              <div>
                <label>Rescode*</label>
                <input type="text"  
                       name="rescode" 
                       value={inputs.rescode || ""} 
                       onChange={handleChange} 
                />
              </div>

              <div>
                <label>Reservation Name*</label>
                <input type="text"  
                       name="resname" 
                       value={inputs.resname || ""} 
                       onChange={handleChange} 
                />
              </div>

              <div>
                <label htmlFor="arrival-date">Arrival Date*:</label>
                <input 
                    type="text" 
                    name="arrivaldate" 
                    value={inputs.arrivaldate || ""} 
                    onChange={handleDateInputChange}
                    ref={arrivalInputRef}
                />
                {arrivalDatePickerVisibility && (
                    <div className="calendar-container">
                        <Calendar onChange={(value)=> {dateChanger(value, "arrivaldate")}} 
                                  value={inputs.arrivaldate} 
                                  maxDate={new Date(inputs.departuredate) || null}
                                  locale="hu-HU"
                        />
                    </div>
                )}
                <button onClick={() => arrivalDatePickerVisibility ? 
                    setArrivalDatePickerVisibility(false) : 
                    setArrivalDatePickerVisibility(true)} 
                    disabled={arrivalDatePickerState} >
                        dátumválasztó
                </button>
              </div>

              <div>
                <label>Arrival Time</label>
                <input type="text"  
                       name="arrivaltime" 
                       value={inputs.arrivaltime || ""} 
                       onChange={handleChange} 
                />
              </div>

              <div>
                <label>Departure date*</label>
                <input 
                    type="text" 
                    name="departuredate" 
                    value={inputs.departuredate || ""} 
                    onChange={handleDateInputChange}
                    ref={departureInputRef}
                />
                {departureDatePickerVisibility && (
                    <div className="calendar-container">
                        <Calendar onChange={(value)=> {dateChanger(value, "departuredate")}} 
                                  value={inputs.departuredate} 
                                  minDate={new Date(inputs.arrivaldate) || null}
                                  locale="hu-HU"
                        />
                    </div>
                )}
                <button onClick={() => departureDatePickerVisibility ? 
                                        setDepartureDatePickerVisibility(false) : 
                                        setDepartureDatePickerVisibility(true)} 
                        disabled={departureDatePickerState}>
                        dátumválasztó
                </button>
              </div>

              <div>
                <label>Departure Time</label>
                <input type="text"  
                       name="departuretime" 
                       value={inputs.departuretime || ""} 
                       onChange={handleChange} 
                />
              </div>

              <div>
                <label>Apartment*</label>
                <ApartmentUnitSelector onChange={handleApartmentChange} setToZero={submitClicked} />

              </div>

              <div>
                <label>People*</label>
                <input type="number"  
                       name="people" 
                       value={inputs.people} 
                       onChange={handleChange} 
                       min="1"
                       max={apartmentCapacity}
                       disabled={peopleInputState}
                />
                <label>Apartment capacity: {apartmentCapacity}</label>
              </div>
              
            </fieldset>

          </div>
          <button type="button" onClick={handleAddReservation} disabled={submitButtonState}>Hozzáad</button>
        </div>

            <div className="new-personal-data-section">
            <fieldset>
              <legend>Személyes adatok</legend>

                <RenderPersonalData personalDetails={personalData} 
                                    setPersonalDetails={setPersonalData}
                                    personalDataFields={personalDataFields}
                />

            </fieldset>
            <button type="button" onClick={handleAddReservation} disabled={submitButtonState}>Hozzáad</button>
            </div>
</>
      )}

      {activeTab === "massReservation" && (
        <div className="mass-reservation-section">
          <h2>Tömeges hozzáadás</h2>
          <div>
          <button type="button">Adatok importálása Excel fájlból</button>
          </div>
          <div>
          <button type="button">Adatok importálása CSV fájlból</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddNewReservations;

