import React, { useState, useEffect  } from "react";

export const CleaningPrices = () => {

    const [cleaningPrices, setCleaningPrices] = useState([]);

useEffect(() => {

  const cachedApartmentsData = JSON.parse(localStorage.getItem('apartmentUnitsCached'));
  let cachedCleaningPrices = JSON.parse(localStorage.getItem('cleaningPricesCached'));
  
    cachedCleaningPrices.forEach((element) => {
      cachedApartmentsData.map((apartment) => {
          if (apartment.apartment_unit_ID === element.apartment_unit_id) {
              element.apartment_short_name = apartment.short_name;
          }
      });
    });

    setCleaningPrices(cachedCleaningPrices);
}, []);


const handleDataChange = (cleaning_ID, newPrice) => {
    setCleaningPrices((prevState) => {
        const newData = [...prevState];
        newData.map((item) => {
            if (item.cleaning_ID === cleaning_ID) {
                item.price = newPrice;
            }
        });
        return newData;
    });       
};


const handleDataChangeBlur = (e, cleaning_ID, newPrice) => {
  let url = process.env.REACT_APP_BACKEND_LINK + "/updateCleaningPrices.php";
  let bodyText = `cleaning_ID=${cleaning_ID}&price=${newPrice}`;
    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: bodyText,
    })
    .then((res) => res.json())
    .then((response) => {
      localStorage.setItem('cleaningPricesCached', JSON.stringify(cleaningPrices));
    });
};


const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation(); // Add this line to stop event bubbling
      event.target.blur();
    }
}


let currApartment = '';
return (
  <>
    <h1>Árak</h1>
    {cleaningPrices.map((cleaningPrice, index) => {
      if (cleaningPrice.apartment_short_name !== currApartment) {
        currApartment = cleaningPrice.apartment_short_name;
        return (
            <div
            key={index}
            style={{
                display: 'flex',
                width: '100%',
            }}
            >

          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <div
              style={{
                padding: '5px',
                fontWeight: 'bold',
                width: '100%',
              }}
            >
              {currApartment}
            </div>

            <div style={{ padding: '5px', width: '100%' }}>
              {cleaningPrice.cleaning_name}
            </div>

            <div style={{ padding: '5px', width: '100%' }}>
                <input  type='text' 
                        id={cleaningPrice.apartment_short_name + index} 
                        onChange={e => handleDataChange(cleaningPrice.cleaning_ID, e.target.value)}
                        value={cleaningPrice.price}
                        onBlur={e => handleDataChangeBlur(e, cleaningPrice.cleaning_ID, e.target.value)}
                        style={{ width: '70px', textAlign: 'center' }}
                        onKeyDown={(e)=>(handleKeyDown(e))}
                        />
            </div>
          </div>
            </div>
        );
      } else {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <div style={{ padding: '5px', width: '100%' }}></div>

            <div style={{ padding: '5px', width: '100%' }}>
              {cleaningPrice.cleaning_name}
            </div>

            <div style={{ padding: '5px', width: '100%' }}>
               <input  type='text' 
                        id={cleaningPrice.apartment_short_name + index} 
                        onChange={e => handleDataChange(cleaningPrice.cleaning_ID, e.target.value)}
                        value={cleaningPrice.price}
                        onBlur={e => handleDataChangeBlur(e, cleaningPrice.cleaning_ID, e.target.value)}
                        style={{ width: '70px', textAlign: 'center' }}
                        onKeyDown={(e)=>(handleKeyDown(e))}
                />
            </div>
          </div>
        );
      }
    })}
  </>
);

}
