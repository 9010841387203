import React, { useState, useEffect  } from "react";
import Calendar from 'react-calendar';

import ArrivingGuestsTable from "./components/ArrivingGuestsTable";
import DepartingGuestsTable from "./components/DepartingGuestsTable";
import CurrentGuestsTable from "./components/CurrentGuestsTable";
import AllGuestsTable from "./components/AllGuestsTable";
import './styles/StartpageForCommunicatorsStyle.css'


export const StartPageForCommunicators = () => {

  const [pickedDate, setPickedDate] = useState(new Date());
  const [hosts, setHosts] = useState([{id:0, name:"Select a host", employee_ID:0, task:0}]);

      const [apartmentUnits, setApartmentUnits] = useState([]);
      const [datePickerVisibility, setDatePickerVisibility] = useState(false);
      const [queryDate, setQueryDate] = useState({});
      const [activeTab, setActiveTab] = useState("tab1");


useEffect(() => {

  const fetchData = async () => {
    try {
        const fetch_data = [
            { name: 'apartment_units', params: {} },
            { name: 'hosts', params: { role_ID: 1 } },
        ];
    
          fetch(`${process.env.REACT_APP_BACKEND_LINK}/fundamental_queries.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8', 
            },
            body: JSON.stringify({ fetch_data: fetch_data })
          })
            .then((res) => res.json())
            .then((data) => {
          
              const newHostsData = [];
              let num = 1;
              data.hosts.forEach((item) => {
                newHostsData.push({id: num, name: item.name, employee_ID: item.employee_ID, task: 1});
                newHostsData.push({id: num+1, name: "ügyelet " + item.name, employee_ID: item.employee_ID, task: 2});
                num = num + 2;
              });
        
              setHosts(arr => [...arr, ...newHostsData]);
              setApartmentUnits(data.apartment_units);
        
                localStorage.setItem('apartmentUnitsCached', JSON.stringify(data.apartment_units));
                localStorage.setItem('hostsCached', JSON.stringify(newHostsData));
              })
            .catch((error) => {
              console.error('Hiba történt:', error);
            });
    } catch (error) {
      console.log(error.message);
    }
  }
  /*  
  const fetchData = async () => {
    try {
      const [hostsResponse, apartmentsResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_BACKEND_LINK}/queryHosts.php`),
        fetch(`${process.env.REACT_APP_BACKEND_LINK}/queryApartments.php`)
      ]);

      const hostsData = await hostsResponse.json();
      const apartmentsData = await apartmentsResponse.json();
     
      const newHostsData = [];
      let num = 1;
      hostsData.forEach((item) => {
        newHostsData.push({id: num, name: item.name, employee_ID: item.employee_ID, task: 1});
        newHostsData.push({id: num+1, name: "ügyelet " + item.name, employee_ID: item.employee_ID, task: 2});
        num = num + 2;
      });

      setHosts(arr => [...arr, ...newHostsData]);
      setApartmentUnits(apartmentsData);

        localStorage.setItem('hostsCached', JSON.stringify(newHostsData));
        localStorage.setItem('apartmentUnitsCached', JSON.stringify(apartmentsData));
    } catch (error) {
      console.log(error.message);
    }
  };
*/
  let cachedApartmentsData = JSON.parse(localStorage.getItem('apartmentUnitsCached'));
  let cachedHostsData = JSON.parse(localStorage.getItem('hostsCached'));
      if (!cachedApartmentsData && !cachedHostsData) {
        fetchData();
      } else {
        setApartmentUnits(cachedApartmentsData);
        setHosts(cachedHostsData);
      }
}, []);


      useEffect(() => {
        const arrivalsOnThisDayInDate = new Date(pickedDate);

        const options = { weekday: 'long' };
        const dayName = arrivalsOnThisDayInDate.toLocaleDateString('hu-HU', options);

        arrivalsOnThisDayInDate.setMinutes(arrivalsOnThisDayInDate.getMinutes() - arrivalsOnThisDayInDate.getTimezoneOffset()); 
        const arrivalsOnThisDayString = arrivalsOnThisDayInDate.toISOString().slice(0,10);
        setQueryDate({date: arrivalsOnThisDayString, dayName: dayName});
      }, [pickedDate])
     

    const changer = (pickedDateValue) => {
        setPickedDate(pickedDateValue)
        setDatePickerVisibility(false)
    }


    const dayChanger = (direction = 0) => {  
      const newDate = new Date(pickedDate);
      const diff = direction === 0 ? newDate.setDate(newDate.getDate() - 1) : newDate.setDate(newDate.getDate() + 1)
      changer(new Date(diff))
    }
    



let tabC;

if(activeTab === "tab1"){
    tabC = <ArrivingGuestsTable pickedDate={pickedDate} hosts={hosts} apartments={apartmentUnits} />
}
if(activeTab === "tab2"){
  tabC = <DepartingGuestsTable pickedDate={pickedDate} hosts={hosts} apartments={apartmentUnits}/>
}
if(activeTab === "tab3"){
  tabC = <CurrentGuestsTable pickedDate={pickedDate} hosts={hosts} apartments={apartmentUnits}/>
}
if(activeTab === "tab4"){
  tabC = <AllGuestsTable pickedDate={pickedDate} hosts={hosts} apartments={apartmentUnits} />
}



return (
  <div>

<div>
  <>
{datePickerVisibility && (
        <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={pickedDate} locale="hu-HU" />
                            )}
            <button onClick={() => dayChanger()} >ELŐZŐ nap</button>
            <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
            <button onClick={() => dayChanger(1)} >KÖVETKEZŐ nap</button>
            <button onClick={() => changer(new Date())} >MAI NAPRA UGRÁS</button>
            
            {queryDate.date}
             - 
            {queryDate.dayName}
</>            
</div>

  <div className="Tabs">
    <ul className="nav">
      <li
        className={activeTab === "tab1" ? "active" : ""}
        onClick={() => setActiveTab("tab1")}
      >
        Érkező vendégek
      </li>
      <li
        className={activeTab === "tab2" ? "active" : ""}
        onClick={() => setActiveTab("tab2")}
      >
        Távozó vendégek
      </li>
      <li
        className={activeTab === "tab3" ? "active" : ""}
        onClick={() => setActiveTab("tab3")}
      >
        Ittlévő vendégek
      </li>
      <li
        className={activeTab === "tab4" ? "active" : ""}
        onClick={() => setActiveTab("tab4")}
      >
        Összes vendégforgalom
      </li>
    </ul>

  </div>
    <div className="outlet">{tabC}</div>
    </div>
    );
}