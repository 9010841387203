import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { MainFrame } from "./pages/MainFrame";
import PrivateRoutes from "./pages/PrivateRoutes";

import './App.css';

function App() {
  return (
    <div className="App">
     <Router basename={process.env.REACT_APP_BASENAME}>
        <Routes>
          <Route element={<PrivateRoutes/>}>           
            <Route path="/home/*" element={<MainFrame/>} />            
          </Route>   
                 
          <Route path="/" element={<LoginPage />} exact/>
          <Route path="*" element={<LoginPage />} /> 
       </Routes>
    </Router>
    </div>
  );
}

export default App;
