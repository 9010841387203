import { useState, useEffect } from 'react';
import ApartmentUnitSelector from './components/ApartmentUnitSelector';

export function ApartmentsInfo () {
  
  const [apartmentUnits, setApartmentUnits] = useState([]);
  const [currentApartmentUnit, setCurrentApartmentUnit] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = async event => {
    if (event.target.value !== '0') {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_LINK}/queryApartmentsInfos.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: `apartment_unit_id=${event.target.value}`,
        });
        const data = await response.json();
      //  console.log(data);
        setCurrentApartmentUnit(data);
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  };


  
  return (
    <div>
        <ApartmentUnitSelector onChange={handleChange} />



      <table>
       <thead>
           <tr>
               <td></td>
               <td></td>
               <td></td>
           </tr>
       </thead>
   
       <tbody>
           <tr>
            <td> cím: </td>
            <td> {currentApartmentUnit.address} </td>
            <td></td>
           </tr>

           <tr>
            <td> hirdetési linkek: </td>
            <td>{currentApartmentUnit.links ? currentApartmentUnit.links.split(',').map(s=> (<><a href={s} target="_blank" rel="noreferrer">{s}</a><br/></>)) : ''}</td>
           </tr>

           <tr>
            <td> ajtószám: </td>
            <td> {currentApartmentUnit.door_number} </td>
            <td></td>
           </tr>

           <tr>
           <td> férőhely: </td>
           <td> {currentApartmentUnit.people} </td>
           </tr>

           <tr>
            <td> Szobák száma: </td>
            <td> {currentApartmentUnit.rooms ? currentApartmentUnit.rooms : '-'} </td>
           </tr>

           <tr>
            <td> Ágyak száma: </td>
            <td> {currentApartmentUnit.beds ? currentApartmentUnit.beds : '-'} </td>
           </tr>

           <tr>
           <td> <p>emelet:</p> </td>
           <td>
            {(() => {
            if (currentApartmentUnit.floor === 0) {            
                return ( <p>földszint</p> )
            }           
            if (currentApartmentUnit.floor !== 0 && currentApartmentUnit.floor !== undefined) {            
                    return ( <p>{currentApartmentUnit.floor}. emelet</p> )
            }           
            if (currentApartmentUnit.floor === undefined) {            
                    return ( <p> - </p> )
            }  
            })()}
           </td>
           </tr>
   
           <tr>
            <td> <p>lift:</p> </td>
            <td>
              {currentApartmentUnit.elevator ? currentApartmentUnit.elevator : <p> - </p>}
            </td>
           </tr>

           <tr>
            <td> Kapukód: </td>
            <td> {currentApartmentUnit.gatecode ? currentApartmentUnit.gatecode : '-'} </td>
           </tr>

           <tr>
            <td> Kaputelefon: </td>
            <td> {currentApartmentUnit.intercom ? currentApartmentUnit.intercom : '-'} </td>
           </tr>
   
           <tr>
            <td> hűtés: </td>
            <td> {currentApartmentUnit.coolings ? currentApartmentUnit.coolings : '-'} </td>
           </tr>
   
           <tr>
            <td> fűtés: </td>
            <td> {currentApartmentUnit.heatings ? currentApartmentUnit.heatings : '-'} </td>
           </tr>

           <tr>
            <td> melegvíz: </td>
            <td> {currentApartmentUnit.hotwater_name ? currentApartmentUnit.hotwater_name : '-'} </td>
           </tr>

           <tr>
            <td> Felszereltség: </td>
            <td> {currentApartmentUnit.utensils ? currentApartmentUnit.utensils : '-'} </td>
           </tr>

           <tr>
            <td> Ágynemű helye: </td>
            <td> {currentApartmentUnit.bedding_placement ? currentApartmentUnit.bedding_placement : '-'} </td>
           </tr>

           <tr>
            <td> Kapcsolószekrény helye: </td>
            <td> {currentApartmentUnit.fusebox_placement ? currentApartmentUnit.fusebox_placement : '-'} </td>
           </tr>

           <tr>
            <td> megjegyzés: </td>
            <td> {currentApartmentUnit.comments ? currentApartmentUnit.comments : '-'} </td>
           </tr>

           <tr>
            <td> Wifi: </td>
            <td> {currentApartmentUnit.wifi_name ? currentApartmentUnit.wifi_name : '-'} </td>
            <td> {currentApartmentUnit.wifi_password ? currentApartmentUnit.wifi_password : '-'} </td>
           </tr>

           <tr>
            <td> Okostévé: </td>
            <td> {currentApartmentUnit.smarttv ? currentApartmentUnit.smarttv : '-'} </td>
           </tr>

           <tr>
            <td> Szeméttárolás: </td>
            <td> {currentApartmentUnit.trash ? currentApartmentUnit.trash : '-'} </td>
           </tr>




{currentApartmentUnit.nearest_parkinglots ? (
            <>
                {currentApartmentUnit.nearest_parkinglots.split('<>').map((info, index) => {
                   const [name, address, maplink] = info.split('|||');
                    return (
                  <tr key={index}>
                    <td>Legközelebbi parkolóház:</td>
                    <td>{name}</td>
                    <td>{address}</td>
                    <td><a href={maplink} target="_blank" rel="noreferrer">{maplink}</a></td>        
                  </tr>
)})}
            </>
            ) : (
              <tr>
                <td> Legközelebbi parkolóház: </td>
                <td> - </td>
              </tr>
            )}




{currentApartmentUnit.nearest_groceries ? (
            <>
                {currentApartmentUnit.nearest_groceries.split('<>').map((info, index) => {
                   const [name, address, maplink, opening_hours] = info.split('|||');
                    return (
                  <tr key={index}>
                    <td>Legközelebbi élelmiszer bolt:</td>
                    <td>{name}</td>
                    <td>{address}</td>
                    <td><a href={maplink} target="_blank" rel="noreferrer">{maplink}</a></td>        
                    <td>Nyitvatartás: {opening_hours}</td>
                 </tr>
)})}
            </>
            ) : (
              <tr>
                <td> Legközelebbi élelmiszer bolt: </td>
                <td> - </td>
              </tr>
            )}





{currentApartmentUnit.nearest_conveniences ? (
            <>
                {currentApartmentUnit.nearest_conveniences.split('<>').map((info, index) => {
                   const [name, address, maplink, opening_hours] = info.split('|||');
                    return (
                  <tr key={index}>
                    <td>Legközelebbi vegyeskereskedés:</td>
                    <td>{name}</td>
                    <td>{address}</td>
                    <td><a href={maplink} target="_blank" rel="noreferrer">{maplink}</a></td>        
                    <td>Nyitvatartás: {opening_hours}</td>
                 </tr>
)})}
            </>
            ) : (
              <tr>
                <td> Legközelebbi vegyeskereskedés: </td>
                <td> - </td>
              </tr>
            )}




{currentApartmentUnit.nearest_pharmacies ? (
            <>
                {currentApartmentUnit.nearest_pharmacies.split('<>').map((info, index) => {
                   const [name, address, maplink, opening_hours] = info.split('|||');
                    return (
                  <tr key={index}>
                    <td>Legközelebbi gyógyszertár:</td>
                    <td>{name}</td>
                    <td>{address}</td>
                    <td><a href={maplink} target="_blank" rel="noreferrer">{maplink}</a></td>        
                    <td>Nyitvatartás: {opening_hours}</td>
                 </tr>
)})}
            </>
            ) : (
              <tr>
                <td> Legközelebbi gyógyszertár: </td>
                <td> - </td>
              </tr>
            )}


{currentApartmentUnit.nearest_luggage_storages ? (
            <>
                {currentApartmentUnit.nearest_luggage_storages.split('<>').map((info, index) => {
                   const [name, address, maplink, opening_hours] = info.split('|||');
                    return (
                  <tr key={index}>
                    <td>Legközelebbi luggage storage:</td>
                    <td>{name}</td>
                    <td>{address}</td>
                    <td><a href={maplink} target="_blank" rel="noreferrer">{maplink}</a></td>        
                    <td>Nyitvatartás: {opening_hours}</td>
                 </tr>
)})}
            </>
            ) : (
              <tr>
                <td> Legközelebbi luggage storage: </td>
                <td> - </td>
              </tr>
            )}


{currentApartmentUnit.nearest_money_exchanges ? (
            <>
                {currentApartmentUnit.nearest_money_exchanges.split('<>').map((info, index) => {
                   const [name, address, maplink] = info.split('|||');
                    return (
                  <tr key={index}>
                    <td>Legközelebbi pénzváltó:</td>
                    <td>{name}</td>
                    <td>{address}</td>
                    <td><a href={maplink} target="_blank" rel="noreferrer">{maplink}</a></td>        
                 </tr>
)})}
            </>
            ) : (
              <tr>
                <td> Legközelebbi pénzváltó: </td>
                <td> - </td>
              </tr>
            )}

       </tbody>
   </table>

   {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};












