import React, { useMemo, useCallback } from 'react';
import { BsPencilSquare } from 'react-icons/bs';


function GuestPropButton({ personal_infos_completeness, guestPropButtonClick, res_ID}) {

  const handleClick = () => {
    guestPropButtonClick(res_ID);
  }

  return (
    <div style={{backgroundColor: personal_infos_completeness === "0" ? "pink" : "green"}}>
    <span className="note_icon">
      <BsPencilSquare onClick={handleClick} />  
    </span>
    </div>
)
  }
  export default GuestPropButton;




/*régi kód!!
function GuestPropButton({ row, setGuests, setOpenRegisterModal }) {
    const handleClick = useCallback(() => {

      if(!row.original.hasOwnProperty('guests')) {

      let url = process.env.REACT_APP_BACKEND_LINK + "/queryreservation.php";
      let bodyText = `res_ID=${row.original.res_ID}`; 
  
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: bodyText
      })
      .then((res) => res.json())
      .then((response) => {
        setGuests(guests => {
          const newGuests = [...guests];
          newGuests.forEach((guest) => {
            if (guest.res_ID === row.original.res_ID) {
              guest['guests'] = response;
            }
          })
          return newGuests;
        })

        setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:true, res_ID:row.original.res_ID}))

      })
      } else {
        setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:true, res_ID:row.original.res_ID})) 
      }
    

    }, [row.original, setGuests, setOpenRegisterModal]);
  


    const MemoizedExample = useMemo(() => {
      return (
        <span className="note_icon">
          <BsPencilSquare onClick={handleClick} />  
        </span>
      )

    }, [handleClick]);
  
    return MemoizedExample;
  }

export default GuestPropButton;
*/
      /*
      return (
        <div style={{backgroundColor: row.original.personal_infos_completeness === '0' ? "pink" : "green"}}>
        <span className="note_icon">
          <BsPencilSquare onClick={handleClick} />  
        </span>
        </div>
      )
      */
     /*
       return (
    <span className="note_icon">
      <BsPencilSquare onClick={handleClick} />  
    </span>
  )
*/