import { useEffect, useState } from "react";

const FinishedCleanings = ({pickedDate, cleaners, apartmentUnits, cleaningPrices}) => {

    const [finishedCleanings, setFinishedCleanings] = useState([]);

    const pickedDateInString = pickedDate.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_LINK + "/querycleaningtasks.php", {
            method: "POST",
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: `date=${pickedDateInString}&status=2`,
          })
            .then((response) => response.json())
            .then((response) => {


                response.forEach((item) => {      //apartment_unit_id átírása short_name-re az érkező/távozó vendégeknél
                    let apartmentName = "";
                    apartmentUnits.forEach((apartment) => {
                      if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                        apartmentName = apartment.short_name;
                      }
                    });
                    item.apartment_short_name = apartmentName;
                  });

                  response.forEach((item) => {      
                    let cleanerName = "";
                    cleaners.forEach((cleaner) => {
                      if (cleaner.employee_ID === item.employee_ID) {
                        cleanerName = cleaner.name;
                      }
                    });
                    item.cleaner_name = cleanerName;
                  });  
                  
                  response.forEach((item) => {
                    let cleaning_name = "";
                    cleaningPrices.forEach((cleaningPrice) => {
                        if (cleaningPrice.cleaning_ID === item.cleaning_ID) {
                            cleaning_name = cleaningPrice.cleaning_name;
                        }
                    });
                    item.cleaning_name = cleaning_name;
                });


                response.forEach((item) => {  
                    if (item.task_comments !== "" && item.task_comments !== null) {
                        let commentsObj = {};
                        let commentsArr = item.task_comments.split("<>");
                        commentsArr.forEach((comment, index) => {
                          commentsObj[index] = {
                            comment: comment,
                          };
                        });
                        item["task_comment"] = commentsObj;
                      } else {
                        item["task_comment"] = {};
                      }

                setFinishedCleanings(response);
                }
            );
        });
    }, [pickedDateInString, cleaners, apartmentUnits, cleaningPrices]);


return (
    <div>
        <h1>Finished Cleanings</h1>
        {finishedCleanings.map((cleaning, index) => (
            <div
                key={index}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "3px solid brown",
                    borderRadius: "10px",
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "#9df29d"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px"
                    }}
                >
                    <div style={{ fontWeight: "bold" }}>
                        {cleaning.apartment_short_name}
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                        {cleaning.cleaner_name}
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                        {cleaning.cleaning_name}
                    </div>
                </div>

                
                

                {Object.keys(cleaning.task_comment).length === 0 ? null : (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          border: "1px solid black",
                                          backgroundColor: "lightblue",
                                          width: "100%"
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: "5px",
                                            fontWeight: "bold",
                                            width: "100%"
                                          }}
                                          //onClick={() => openTheModal(apartment, 2)}
                                        >
                                          {Object.keys(cleaning.task_comment).map((comment, index) => {
                                            return (
                                              <div key={index}>
                                                {cleaning.task_comment[comment].comment}
                                              </div>
                                            )
                                          }
                                            )}
                                        </div>
                                      </div>
                                        )
                    }

            </div>
        ))}
    </div>
)
};
export default FinishedCleanings;
