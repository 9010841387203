import React, { useState, memo  } from "react";

const HostSelector = ({ hosts, res_ID, rowIndex, defaultHost, defaultTask }) => {

  let def = 0;
    Object.keys(hosts).map((host, index) => {
      if(parseInt(hosts[host].employee_ID) === parseInt(defaultHost) && parseInt(hosts[host].task) === parseInt(defaultTask)){
        def = hosts[host].id;
      }
    })


   const [selected, setSelected] = useState({ ['id_' + rowIndex]: def }); 


    function handleChange(event){
  
 var index = event.target.selectedIndex;
  var optionElement = event.target.childNodes[index]
  var employee =  optionElement.getAttribute('data-employee');
  var task =  optionElement.getAttribute('data-task');


        setSelected({...selected, [event.target.id]:event.target.value});

        let url = process.env.REACT_APP_BACKEND_LINK + "/updateguest.php";
        let formData = {
          'res_ID' : parseInt(res_ID),
          'host' : parseInt(employee),
          'hosttask' : parseInt(task)
        };
  
        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-type' : 'application/json; charset=UTF-8',
          },
          body: JSON.stringify(formData)
        })
        .then((res) => res.json() )
        .then(response => {  
          console.log(response)
         })
        .catch(error => console.log(error));
       
    }
    
    
    return(
        <>
            <select  id={'id_'+rowIndex}  
                     value={selected['id_'+rowIndex] || '0'}
                     onChange={(event)=>(handleChange(event))}
                     style={{width: '100%'}}
            >

                  {Object.keys(hosts).map((host, index) => (
                      <option key={index} value={hosts[host].id} data-employee={hosts[host].employee_ID} data-task={hosts[host].task}>
                        {hosts[host].name}
                      </option>
                    ))}

            </select>
        </>
    )
}

export default memo(HostSelector);

