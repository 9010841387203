import { Routes, Route, Outlet } from "react-router-dom";
import { StartPage } from "./StartPage";
import { StartPageForHosts } from "./StartPageForHosts";
import { StartPageForCommunicators } from "./StartPageForCommunicators";
import { Payment } from "./Payment";
import { Settings } from "./Settings";
import { Textbook } from "./Textbook" 
import { ApartmentsInfo } from "./ApartmentsInfo" 
import { Logout } from "./Logout";
import { MainNavbar } from "./components/MainNavbar";
import AddNewReservations from "./AddNewReservations";
import { CalendarPage } from "./CalendarPage";
import { Tours } from "./Tours";
import { Tasks } from "./Tasks";
import { StartPageForSupervisor } from "./StartPageForSupervisor";
import { StartPageForCleaners } from "./StartPageForCleaner";
import { CleaningPrices } from "./CleaningPrices";
import { AddNewCleaningTask } from "./AddNewCleaningTask";
import { ApartmentCodePage } from "./ApartmentCodePage";
import { WeeklyCleaningSchedule } from "./WeeklyCleaningSchedule";
import "./styles/HomePageStyle.css";




export const MainFrame = () => {

let userName = localStorage.getItem('name');
let menu = localStorage.getItem('menu');


function Frame() {
  return (
    <>
       <div className="navbarrow">
         <MainNavbar user={userName} menu={menu} />
       </div>
      <Outlet />
    </>
      );
}

/*
mivel egyenlőre nem tudom hányféle szerep lesz és milyen menük kellenek hozzá, ezért egyelőre ez statikus!
az employees táblában van egy startpage mező, az szabályozza.
0: admin
1: host
2: vendégkommunikátor
3: night manager

WIP:
* majd az employee_roles táblából gyűjti ki a jogosultságokat, és az alapján állítja össze a menüt
* vagy majd az installálásos résznél lehet megadni
*/


if (menu === "0"){                                                                     // admin
  return (
    <>
      <Routes>
        <Route path="/" element={<Frame />} >
        <Route path="/" element={<StartPage />} ></Route>
              <Route path="home" element={<StartPage />} ></Route>
              <Route path="payment" element={<Payment />} ></Route>
              <Route path="settings" element={<Settings />} ></Route>
              <Route path="tours" element={<Settings />} ></Route>
              <Route path="logout" element={<Logout />} ></Route>
        </Route>
      </Routes>
    </>
  );
  }

  if (menu === "1"){                                                                     // host                 
    return (
      <>
        <Routes>
          <Route path="/" element={<Frame />} >
          <Route path="/" element={<StartPageForHosts />} ></Route>
                <Route path="home" element={<StartPageForHosts />} ></Route>
                <Route path="apartments" element={<ApartmentsInfo />} ></Route>
                <Route path="payment" element={<Payment />} ></Route>
                <Route path="settings" element={<Settings />} ></Route>
                <Route path="tours" element={<Tours />} ></Route>
                <Route path="tasks" element={<Tasks />} ></Route>
               <Route path="logout" element={<Logout />} ></Route>
          </Route>
        </Routes>
      </>
    );
    }

  if (menu === "2"){                                                                   // vendégkommunikátor                  
     return (
       <>
         <Routes>
           <Route path="/" element={<Frame />} >
            <Route path="/" element={<StartPageForCommunicators />} ></Route>
            <Route path="home" element={<StartPageForCommunicators />} ></Route>
            <Route path="calendar" element={<CalendarPage />} ></Route>
            <Route path="addreservation" element={<AddNewReservations />} ></Route>                 
            <Route path="payment" element={<Payment />} ></Route>
            <Route path="apartments" element={<ApartmentsInfo />} ></Route>
            <Route path="tours" element={<Tours />} ></Route>
            <Route path="textbook" element={<Textbook />} ></Route>
            <Route path="tasks" element={<Tasks />} ></Route>
            <Route path="logout" element={<Logout />} ></Route>
           </Route>
         </Routes>
       </>
     );
  }

  if (menu === "3"){                                                                     // supervisor                 
    return (
      <>
        <Routes>
          <Route path="/" element={<Frame />} >
          <Route path="/" element={<StartPageForSupervisor />} ></Route>
            <Route path="home" element={<StartPageForSupervisor />} ></Route>
            <Route path="weeklycleaningschedule" element={<WeeklyCleaningSchedule />} ></Route>
            <Route path="addnewcleaningtask" element={<AddNewCleaningTask />} ></Route>
            <Route path="cleaningprices" element={<CleaningPrices />} ></Route>
            <Route path="codes" element={<ApartmentCodePage />} ></Route>
               <Route path="logout" element={<Logout />} ></Route>
          </Route>
        </Routes>
      </>
    );
    }  

    if (menu === "4"){                                                                     // supervisor                 
      return (
        <>
          <Routes>
            <Route path="/" element={<Frame />} >
            <Route path="/" element={<StartPageForCleaners />} ></Route>
                  <Route path="home" element={<StartPageForCleaners />} ></Route>
                 <Route path="logout" element={<Logout />} ></Route>
            </Route>
          </Routes>
        </>
      );
      }  
  

}