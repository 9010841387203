import React, { useState, useEffect  } from "react";
import Calendar from 'react-calendar';

class Day {
    constructor(dayName, dayNumber, fullDate, obj) {
        this.dayName = dayName;
        this.dayNumber = dayNumber;
        this.fullDate = fullDate;
        this.obj = obj;
    }
}

const days = ["Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"];
const months = ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"];

  function ISO8601_week_no(dt) {
     var tdt = new Date(dt.valueOf());
     var dayn = (dt.getDay() + 6) % 7;
     tdt.setDate(tdt.getDate() - dayn + 3);
     var firstThursday = tdt.valueOf();
     tdt.setMonth(0, 1);
     if (tdt.getDay() !== 4) 
       {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
     return 1 + Math.ceil((firstThursday - tdt) / 604800000);
  }


const todayInDateFormat = new Date();
todayInDateFormat.setHours(0, 0, 0, 0);
const todayObj = new Date(todayInDateFormat.getFullYear(), todayInDateFormat.getMonth(), todayInDateFormat.getDate());


const HostCalendar = () => {

    const [pickedDate, setPickedDate] = useState(new Date());
    const [datePickerVisibility, setDatePickerVisibility] = useState(false);
    const [monthName, setMonthName] = useState(months[new Date().getMonth()]);
    const [weekNumber, setWeekNumber] = useState(ISO8601_week_no(new Date()));
    const [mondays, setMondays] = useState();
    const [sundays, setSundays] = useState();
    const [weekHook, setWeekHook] = useState({});
    const [shifts, setShifts] = useState([]);
    
    let userId = localStorage.getItem('id');
    let userName = localStorage.getItem('name');
    let userRole = localStorage.getItem('menu');


    useEffect(() => {
      const fetchData = async () => {
        let week = {};
    
        const date = new Date(pickedDate);
        const dayOfWeek = date.getDay();
        const daysSinceMonday = (dayOfWeek + 6) % 7;
        const mondays2 = new Date(date.getTime() - daysSinceMonday * 24 * 60 * 60 * 1000);
        mondays2.setUTCHours(0, 0, 0, 0);
        setMondays(mondays2);
    
        let sundays2 = new Date(mondays2.getFullYear(), mondays2.getMonth(), mondays2.getDate() + 7);
        sundays2.setUTCHours(0, 0, 0, 0);
        setSundays(new Date(sundays2.getTime()));
    
        for (let i = 0; i < 7; i++) {
          let currDayName = days[i];
          let nextDay = new Date(mondays2.getFullYear(), mondays2.getMonth(), mondays2.getDate() + i + 1);
          let newDay = new Day(currDayName, nextDay.getDate() - 1, nextDay.toISOString().substring(0, 10), []);
          week[i] = newDay;
        }
    
        let startDate = mondays2.toISOString().substring(0, 10);
        let endDate = sundays2.toISOString().substring(0, 10);
    
        const url1 = process.env.REACT_APP_BACKEND_LINK + "/queryfinishedwork.php";
        const bodyText1 = `userid=${userId}&startdate=${startDate}&enddate=${endDate}`;
        const url2 = process.env.REACT_APP_BACKEND_LINK + "/queryshifts3.php";
        const bodyText2 = `employee_ID=${userId}&startdate=${startDate}&enddate=${endDate}&shiftrole=${userRole}`;
    
        const [response1, response2] = await Promise.all([
          fetch(url1, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: bodyText1,
          }).then((res) => res.json()),
          fetch(url2, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: bodyText2,
          }).then((res) => res.json()),
        ]);
    
        const hostedGuests = response1;
        const shiftsResponse = response2.shifts;
        const employeesResponse = response2.employees;
        const allShiftsResponse = response2.allShifts;
    
        let shiftObj = allShiftsResponse;
    
        allShiftsResponse.map((item, index) => {
          shiftObj[index] = item;
          shiftObj[index].shifts = [];
        });
    
        Object.keys(shiftObj).map((currShiftID, index) => {
          shiftsResponse.map((item, index) => {
            if (item.shift_ID === shiftObj[currShiftID].shift_ID) {
              item.name = "";
              shiftObj[currShiftID].shifts.push(item);
            }
          });
        });
    
        Object.keys(shiftObj).map((currShiftID, index) => {
          let shifts = shiftObj[currShiftID].shifts;
          shifts.map((shift, index) => {
            employeesResponse.map((item, index) => {
              if (item.employee_ID === shift.employee_ID) {
                shift.name = item.name;
              }
            });
          });
        });


            //vendégfogadás beletevése
          Object.keys(shiftObj).map((currShiftID, index) => {
            if (shiftObj[currShiftID].pay_per_amount === "1") {
              let hostingShift = shiftObj[currShiftID].shifts;
              hostedGuests.map((item, index) => {
                  let hostedguest = { date: item.arrivaldate, employee_ID: userId, name: item.resname, shift_ID: shiftObj[currShiftID].shift_ID, status: item.status };
                  hostingShift.push(hostedguest);                
              });
            }
          });


        setWeekHook(week);
        setShifts(shiftObj);
      };
    
      fetchData();
    }, [userId, pickedDate]);




    const changer = (pickedDateValue) => {
        setPickedDate(pickedDateValue)
        setDatePickerVisibility(false)
        setMonthName(months[pickedDateValue.getMonth()])
        setWeekNumber(ISO8601_week_no(pickedDateValue)) 
    }
    

    const weekChanger = (direction) => {    
      const newDate = new Date(pickedDate);
        if(direction === 0){
        const diff = newDate.getDate() - 7;
        changer(new Date(newDate.setDate(diff)))
        }
        if(direction === 1){
        const diff = newDate.getDate() + 7;
        changer(new Date(newDate.setDate(diff)))
        }
        if(direction === 2){
            changer(new Date())
        }
    }
    

    function handleChange(shiftToUpdate, dateToUpdate, signEvent){

        let url = process.env.REACT_APP_BACKEND_LINK + "/shiftchanger.php";
        let formData = {
            'employee_ID' : userId,
            'date' : dateToUpdate,
            'shift_ID' : parseInt(shiftToUpdate),
            'action' : parseInt(signEvent)
        };
    
        fetch(url, {
            method: 'PUT',
            headers: {
            'Content-type' : 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formData)
        })
        .then((res) => res.json() )
        .then(response => {
    
    if (signEvent === 0) {
        setShifts(prev => {
          let newShifts = [...prev];
          newShifts.map((item, index) => {
            if (item.shift_ID === shiftToUpdate) {
              item.shifts = item.shifts.filter(item2 => !(item2.date === dateToUpdate && item2.employee_ID === userId));
            }
          })
          return newShifts;
        });
      }
    
    
    if (signEvent === 1) {
      setShifts(prev => {
        let newShifts = [...prev];
        newShifts.map((item, index) => {
          if (item.shift_ID === shiftToUpdate) {
    
            let brandNewShift = {
                date: dateToUpdate,
                employee_ID: userId,
                name: userName,
                shift_ID: shiftToUpdate,
                status: "0"
            }
    
            item.shifts.push(brandNewShift);
    
            }
    
        })
        return newShifts;
      });
    
    }
    
        })
        .catch(error => console.log(error));
    
    }
    
    
    return (
        <>
          {datePickerVisibility && (
            <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={[mondays, sundays]} locale="hu-HU" />
          )}
    
            <div>
                <button onClick={() => weekChanger(0)} >ELŐZŐ HÉT</button>
                <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
                <button onClick={() => weekChanger(1)} >KÖVETKEZŐ HÉT</button>
                <button onClick={() => weekChanger(2)} >MAI HÉTRE UGRÁS</button>
            </div> 
    
            <div>
                <h2>{monthName}</h2> <h2>{weekNumber}. hét</h2>
            </div>
    <div>
    <table>
        <thead>
            <tr>
                <td style={{border:"2px solid black"}}>Beosztások</td>
                {Object.keys(weekHook).map((item, index) => {
                    return(
                        <td key={"calheader"+index} style={{border:"2px solid black"}}>{weekHook[item].dayName +"   " + weekHook[item].dayNumber}</td>
                    )
                })}          
            </tr>
        </thead>

        <tbody>






{Object.keys(shifts).map((shiftKey, index) => {
  const currshift = shifts[shiftKey];
  const currShiftObj = shifts[shiftKey].shifts;

  if(currshift.pay_per_amount === "1"){

    return (
      <tr key={"shift"+index} style={{border:"2px solid blue"}}>
        <td style={{border:"2px solid black"}}>  
          {currshift.shift_name}  
        </td>

        {Object.keys(weekHook).map((weekDay, dayIndex) => {

const currDayInDateFormat = new Date(weekHook[weekDay].fullDate);
const currDayObj = new Date(currDayInDateFormat.getFullYear(), currDayInDateFormat.getMonth(), currDayInDateFormat.getDate());
const currentDayCell = weekHook[weekDay].fullDate;

  if (currDayObj.getTime() < todayObj.getTime()) {                                     // MÚLT
    const currWorkShift = currShiftObj.filter(item => item.date === currentDayCell && item.status === "1");
      if(currWorkShift.length > 0){
      return (
        <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"grey"}}>
          <div key={"calitem"+index}>
            <ul>
            {currWorkShift.map((item, index) => (
              <li key={index}>
                {item.name}
                </li>
                ))
                } 
            </ul>
          </div>
        </td>
      )
      } else {
        return (
          <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"grey"}}>
          </td>
        )
      }  
  }

  if(currDayObj.getTime() === todayObj.getTime()){              /// JELEN

    const currWorkShift = currShiftObj.filter(item => item.date === currentDayCell && item.employee_ID === userId && item.name !== userName && item.status === "1");
    if(currWorkShift.length > 0){
      return (
        <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"red"}}>
          <div key={"calitem"+index}>
            <ul>
            {currWorkShift.map((item, index) => (
              <li key={index}>
                {item.name}
                </li>
                ))
                } 
            </ul>
          </div>
        </td>
      )
      } else {
        return (
          <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"red"}}>
          </td>
        )
      } 
  } 

  if(currDayObj.getTime() > todayObj.getTime()){              /// JÖVŐ
    const currWorkShift = currShiftObj.filter(item => item.date === currentDayCell && item.employee_ID === userId && item.name !== userName);
    if(currWorkShift.length > 0){
      return (
        <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"green"}}>
          <div key={"calitem"+index}>
            <ul>
            {currWorkShift.map((item, index) => (
              <li key={index}>
                {item.name}
                </li>
                ))
                } 
            </ul>
          </div>
        </td>
      )
              } else {

              const currWorkShift2 = currShiftObj.filter(item => item.date === currentDayCell && item.employee_ID === userId && item.name === userName);
             
if(currWorkShift2.length > 0){
  return (
    <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:currWorkShift2[0].status === "0" ? "blue" : "green"}}>
      <div key={"calitem"+index}>
      {currWorkShift2[0].name} 
      </div>
        {currWorkShift2[0].status === "0" ? (
              <div><button onClick={() => handleChange(currshift.shift_ID, weekHook[weekDay].fullDate, 0)}>LEIRATKOZÁS</button></div>
            ) : (
              <div></div>  
            )
        }
            </td>
          )
  } else {
    return (
      <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"blue"}}>               
                  <button onClick={() => handleChange(currshift.shift_ID, weekHook[weekDay].fullDate, 1)}>FELIRATKOZÁS</button>
      </td>
    )
  }  

  }
}

})}
</tr>
        )


  } else {

  return (
    <tr key={"shift"+index} style={{border:"2px solid blue"}}>
      <td style={{border:"2px solid black"}}>  
        {currshift.shift_name}  
      </td>

      {Object.keys(weekHook).map((weekDay, dayIndex) => {

const currDayInDateFormat = new Date(weekHook[weekDay].fullDate);
const currDayObj = new Date(currDayInDateFormat.getFullYear(), currDayInDateFormat.getMonth(), currDayInDateFormat.getDate());
const currentDayCell = weekHook[weekDay].fullDate;

if (currDayObj.getTime() < todayObj.getTime()) {                                     // MÚLT
    const currWorkShift = currShiftObj.find(item => item.date === currentDayCell && item.status === "1");
    if (currWorkShift) {
      return (
        <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"grey"}}>
          <div key={"calitem"+index}>
            {currWorkShift.name} 
          </div>
        </td>
      )
    } else {
      return (
        <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"grey"}}>
        </td>
      )
    }
  }

  if(currDayObj.getTime() === todayObj.getTime()){              /// JELEN
    return(
        <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"red"}}>
            {currshift.shifts.map((item, index) => {
                if(item.date === weekHook[weekDay].fullDate){   
                    return(
                        <div key={"calitem"+index}>
                            {item.name} 
                        </div>
                    )
                }
            })}
        </td>
    )
} 

  if(currDayObj.getTime() > todayObj.getTime()){              /// JÖVŐ

    const currWorkShift = currShiftObj.find(item => item.date === currentDayCell && item.status === "1");
    if (currWorkShift) {
        return (
            <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"green"}}>
            <div key={"calitem"+index}>
                {currWorkShift.name} 
            </div>
            </td>
        )
        } else {
            return (
                <td key={"calbody"+dayIndex} style={{border:"2px solid black", backgroundColor:"blue"}}>
                  <ul>
                    {currshift.shifts
                      .filter(item => item.date === weekHook[weekDay].fullDate)
                      .map((item, index) => (
                        <li key={index}>
                          {item.name}
                        </li>
                      ))
                    }
                  </ul>
                  {currshift.shifts.some(item => item.date === weekHook[weekDay].fullDate && item.employee_ID === userId) ? (
                    <button onClick={() => handleChange(currshift.shift_ID, weekHook[weekDay].fullDate, 0)}>LEIRATKOZÁS</button>
                  ) : (
                    <button onClick={() => handleChange(currshift.shift_ID, weekHook[weekDay].fullDate, 1)}>FELIRATKOZÁS</button>
                  )}
                </td>
              );
        }
  }

        })}

    </tr>

        )
      }


        })}




        </tbody>
    </table>
        </div>












        <div>
  <h2>Heti Fizetés</h2>
    <h4>
    {Object.keys(shifts).reduce((total, shiftKey) => {
        const currshift = shifts[shiftKey];
        const currShiftObj = currshift.shifts;

if(currshift.pay_per_amount === "1"){
  currShiftObj.map((workShift, index) => {
    if(workShift.status === "1" && workShift.name !== userName){
      total += parseInt(currshift.price_in_weekends);
  }})

} else {


        currShiftObj.map((workShift, index) => {
          
          if(workShift.employee_ID === userId){
            const currDayInDateFormat = new Date(workShift.date);
            currDayInDateFormat.setHours(0, 0, 0, 0);

            const currDayObj = new Date(currDayInDateFormat.getFullYear(), currDayInDateFormat.getMonth(), currDayInDateFormat.getDate());
            const currDayOfWeek = currDayInDateFormat.getDay();
            const isWeekend = currDayOfWeek === 0 || currDayOfWeek === 6;

            if(currDayObj.getTime() < todayObj.getTime()){              /// MÚLT
              if(workShift.status === "1"){
              total += parseInt(isWeekend ? currshift.price_in_weekends : currshift.price_in_weekdays);
              }
            }

            if(currDayObj.getTime() === todayObj.getTime()){              /// JELEN    
              total += parseInt(isWeekend ? currshift.price_in_weekends : currshift.price_in_weekdays);
            }

            if(currDayObj.getTime() > todayObj.getTime()){              /// JÖVŐ
              total += parseInt(isWeekend ? currshift.price_in_weekends : currshift.price_in_weekdays);
            }

          }
        })
    }        

       return total;
      }, 0)}
    </h4>
</div>

        </>
    )
}
export default HostCalendar;   