import React, { useState, useEffect  } from "react";
import GuestsTable from "./GuestsTable";
import Modal from "./Modal";
import ArrivalButton from "./ArrivalButton";
import GuestPropButton from "./GuestPropButton";
import { BsPencilSquare } from 'react-icons/bs';


const ArrivingGuestsTableForHosts = ({pickedDate, currhost, apartments}) => {

    const [guests, setGuests] = useState([]);
    const [tableTitles, setTableTitles] = useState('a mai nap érkező vendégek');
    const [commentfieldState, setCommentfieldState] = useState({show:false, rowIndex:null});
    const [openRegisterModal, setOpenRegisterModal] = useState({show:false});

    useEffect(() => {
        if (apartments.length !== 0) {
          setCommentfieldState({show:false, rowIndex:null});
  
        const pickedDateInDateFormat = new Date(pickedDate);
        pickedDateInDateFormat.setMinutes(pickedDateInDateFormat.getMinutes() - pickedDateInDateFormat.getTimezoneOffset()); 
        const pickedDateInString = pickedDateInDateFormat.toISOString().slice(0,10)
  
        const todayInDateFormat = new Date();
        todayInDateFormat.setMinutes(todayInDateFormat.getMinutes() - todayInDateFormat.getTimezoneOffset()); 
        const todayInString = todayInDateFormat.toISOString().slice(0,10)
  
        let pickedDateObj = new Date(pickedDateInString);
        let todayObj = new Date(todayInString);
              
        let guestStatus = 10;
  
        if (pickedDateObj < todayObj) {
          console.log('MÚLT');
          setTableTitles('ezen a napon érkezett vendégek')
        } else if (pickedDateObj > todayObj) {
          console.log('JÖVŐ');
          setTableTitles('ezen a napon érkező vendégek')
        } else {
          console.log('JELEN');
          setTableTitles('a mai nap érkező vendégek')
          guestStatus = 0;
        }
        
          let url = process.env.REACT_APP_BACKEND_LINK + "/queryguestsbyhost.php";
          let bodyText = `userid=${currhost}&arrivaldate=${pickedDateInString}&status=${guestStatus}`; 
  
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: bodyText
            })
            .then((res) => res.json())
            .then((response) => {
  
              response.forEach((item) => {    //apartment_unit_id átírása short_name-re
                let apartmentName = '';
                apartments.forEach((apartment) => {
                  if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                    apartmentName = apartment.short_name;
                  }
                })
                item.apartment_unit_id = apartmentName;
              })
  
              response.forEach((item) => {                                          //commentek hozzáadása
                if(item.commentsArray !== '' && item.commentsArray !== null) {
                  let commentsObj = {}
                  let commentsArr = item.commentsArray.split('<>');
                  let commentsIDsArr = item.commentIDsArray.split('<>');
                  let targetsArr = item.targetsArray.split('<>');
  
                  commentsArr.forEach((comment, index) => {
                    commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                  })
                  item['commentsObj'] = commentsObj;
                } else {
                  item['commentsObj'] = {};
                }
                  delete item['commentIDsArray'];
                  delete item['commentsArray'];
                  delete item['targetsArray'];
              })
  
              //console.log(response);
          
              setGuests(response)
              //console.log('guests: ', guests);
          })
  
        }
          
        }, [pickedDate, apartments, currhost])


        const columns = [
            {
              Header: "resname",
              accessor: "resname",
              width: 400,
            },
            {
                Header: "personalinfos",
                accessor: "personalinfos",
                disableSortBy: true,
                Cell: ({ row })  => { 
                    return row.original.status === "0" ?
                    (<GuestPropButton row={row} setGuests={setGuests} setOpenRegisterModal={setOpenRegisterModal} />   ) 
                    :
                    ( <span className="note_icon" style={{color: "grey"}}><BsPencilSquare  /></span>);                
                }    
            },
            {
              Header: "arrivaldate",
              accessor: "arrivaldate"
            },
            {
              Header: "people",
              accessor: "people"
            },
            {
              Header: "apartment",
              accessor: "apartment_unit_id"
              },
              {
                Header: "button",
                accessor: "button",
                disableSortBy: true,
                Cell: ({ row })  => { 
                        return row.original.status === "0" ?
                            (<ArrivalButton row={row} setGuests={setGuests} />) 
                            : 
                            (<button disabled>Bejelentkeztetve</button>);
                    }
              }
              ];


              return ( 
                <>
                   {openRegisterModal.show && openRegisterModal.show && (
                      <Modal
                        openRegisterModal={openRegisterModal}
                        setOpenRegisterModal={setOpenRegisterModal}
                        setGuests={setGuests}
                        guests={guests}   
                      />
                   )}
        
        
                  <h2>{tableTitles}</h2>
                          <GuestsTable
                              columns={columns}
                              data={guests}   
                              commentfieldState={commentfieldState}  
                              setCommentfieldState={setCommentfieldState}   
                              setGuests={setGuests}
                          />
                </>
              )
}
export default ArrivingGuestsTableForHosts;