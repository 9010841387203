import React from 'react';

const ReservationInfoTab = ({ apartment }) => {

    return (
        <div>
            <div>
                <h2>Foglalás adatai</h2>
            </div>
            <div>
                <p>rescode</p>
                <input type="text" name="rescode" value={apartment.rescode} readOnly/>
            </div>
            <div>
                <p>resname</p>
                <input type="text" name="resname" value={apartment.resname} readOnly/>
            </div>
            <div>
                <p>arrival date</p>
                <input type="text" name="arrivaldate" value={apartment.arrivaldate} readOnly/>
            </div>
            <div>
                <p>departure date</p>
                <input type="text" name="departuredate" value={apartment.departuredate} readOnly/>
            </div>
            <div>
                <p>arrival time</p>
                <input type="text" name="arrivaltime" value={apartment.arrivaltime} readOnly/>
            </div>
            <div>
                <p>departure time</p>
                <input type="text" name="departuretime" value={apartment.departuretime} readOnly/>
            </div>
            <div>
                <p>apartment</p>
                <input type="text" name="apartment_unit_id" value={apartment.apartment_unit_id} readOnly/>
            </div>
            <div>
                <p>people</p>
                <input type="text" name="people" value={apartment.people} readOnly/>
            </div>
            <div>
                <p>host</p>
                <input type="text" name="host" value={apartment.host}  readOnly/>
            </div>




            </div>
    )
}
export default ReservationInfoTab;

/*sztem ez már nem kell ide
<div>
                <h2>megjegyzések</h2>
                {Object.keys(apartment.commentsObj).map((currComment, index) => {
                  
                  return (
                    <div>
                        <input type="text" key={index} name="commentID" value={apartment.commentsObj[currComment].comment} readOnly/>
                    </div>                    
                )
            })}


</div>
*/
