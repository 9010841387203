import React, { useState, useEffect  } from "react";
import Calendar from 'react-calendar';
import ArrivingGuestsForSupervisors from "./components/ArrivingGuestsForSupervisors";
import CurrentGuestsForSupervisors from "./components/CurrentGuestsForSupervisors";
import FinishedCleanings from "./components/FinishedCleanings";

export function StartPageForSupervisor(){
    
    const [pickedDate, setPickedDate] = useState(new Date());
    const [cleaners, setCleaners] = useState([]);
    const [cleaningPrices, setCleaningPrices] = useState([]);
    const [hosts, setHosts] = useState([]);
  
        const [apartmentUnits, setApartmentUnits] = useState([]);
        const [datePickerVisibility, setDatePickerVisibility] = useState(false);
        const [queryDate, setQueryDate] = useState({});
        const [activeTab, setActiveTab] = useState("tab1");
  
  
  useEffect(() => {
 
    const fetchData = async () => {
      try {
            const fetch_data = [
                { name: 'apartment_units', params: {} },
                { name: 'cleaning_prices', params: {} },
                { name: 'hosts', params: { role_ID: 1 } },
                { name: 'employees', params: { role_ID: 4 } },    
              ];
      
            fetch(`${process.env.REACT_APP_BACKEND_LINK}/fundamental_queries.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8', 
              },
              body: JSON.stringify({ fetch_data: fetch_data })
            })
              .then((res) => res.json())
              .then((data) => {
            
                setCleaners(data.employees);
                setApartmentUnits(data.apartment_units);
                setCleaningPrices(data.cleaning_prices);
                setHosts(data.hosts);
          
                  localStorage.setItem('cleanersCached', JSON.stringify(data.employees));
                  localStorage.setItem('apartmentUnitsCached', JSON.stringify(data.apartment_units));
                  localStorage.setItem('cleaningPricesCached', JSON.stringify(data.cleaning_prices));
                  localStorage.setItem('hostsCached', JSON.stringify(data.hosts));  
              })
              .catch((error) => {
                console.error('Hiba történt:', error);
              });
        } catch (error) {
          console.log(error.message);
        }
    }


    const runCleaningTasks = async () => {
      try {
        fetch(`${process.env.REACT_APP_BACKEND_LINK}/runcleaningtasks.php`)
      } catch (error) {
        console.log(error.message);
      }
    };

  
    let cachedApartmentsData = JSON.parse(localStorage.getItem('apartmentUnitsCached'));
    let cachedCleanersData = JSON.parse(localStorage.getItem('cleanersCached'));
    let cachedCleaningPrices = JSON.parse(localStorage.getItem('cleaningPricesCached'));
    let cachedHostsData = JSON.parse(localStorage.getItem('hostsCached'));

        if (!cachedApartmentsData && !cachedCleanersData && !cachedCleaningPrices && !cachedHostsData) {
          fetchData();
          runCleaningTasks();
        } else {
          setApartmentUnits(cachedApartmentsData);
          setCleaners(cachedCleanersData);
          setCleaningPrices(cachedCleaningPrices);
          setHosts(cachedHostsData);
        }
  }, []);
  
  
        useEffect(() => {
          const arrivalsOnThisDayInDate = new Date(pickedDate);
  
          const options = { weekday: 'long' };
          const dayName = arrivalsOnThisDayInDate.toLocaleDateString('hu-HU', options);
  
          arrivalsOnThisDayInDate.setMinutes(arrivalsOnThisDayInDate.getMinutes() - arrivalsOnThisDayInDate.getTimezoneOffset()); 
          const arrivalsOnThisDayString = arrivalsOnThisDayInDate.toISOString().slice(0,10);
          setQueryDate({date: arrivalsOnThisDayString, dayName: dayName});
        }, [pickedDate])
       
  
      const changer = (pickedDateValue) => {
          setPickedDate(pickedDateValue)
          setDatePickerVisibility(false)
      }
  
  
      const dayChanger = (direction = 0) => {  
        const newDate = new Date(pickedDate);
        const diff = direction === 0 ? newDate.setDate(newDate.getDate() - 1) : newDate.setDate(newDate.getDate() + 1)
        changer(new Date(diff))
      }
      
  
  
  
  let currentTab;
  
  if(activeTab === "tab1"){

    currentTab = <ArrivingGuestsForSupervisors pickedDate={pickedDate} 
                                               cleaners={cleaners} 
                                               apartmentUnits={apartmentUnits} 
                                               cleaningPrices={cleaningPrices}
                                                hosts={hosts}
                />             
  }
  if(activeTab === "tab2"){
    currentTab = <FinishedCleanings pickedDate={pickedDate}
                                    cleaners={cleaners} 
                                    apartmentUnits={apartmentUnits} 
                                    cleaningPrices={cleaningPrices}
                />
   }
   if(activeTab === "tab3"){
    currentTab = <CurrentGuestsForSupervisors pickedDate={pickedDate} 
                                              apartmentUnits={apartmentUnits}
                 />
   }
    
  
  
  return (
    <div>
  
  <div>
    <>
  {datePickerVisibility && (
          <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={pickedDate} locale="hu-HU" />
                              )}
              <button onClick={() => dayChanger()} >ELŐZŐ nap</button>
              <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
              <button onClick={() => dayChanger(1)} >KÖVETKEZŐ nap</button>
              <button onClick={() => changer(new Date())} >MAI NAPRA UGRÁS</button>
              
              {queryDate.date}
               - 
              {queryDate.dayName}
  </>            
  </div>
  
    <div className="Tabs">
      <ul className="nav">
        <li
          className={activeTab === "tab1" ? "active" : ""}
          onClick={() => setActiveTab("tab1")}
        >
          Takarítandó szobák
        </li>
        <li
          className={activeTab === "tab2" ? "active" : ""}
          onClick={() => setActiveTab("tab2")}
        >
         Kész szobák
        </li>
        <li
          className={activeTab === "tab3" ? "active" : ""}
          onClick={() => setActiveTab("tab3")}
        >
         Ittlévő vendégek
        </li>
      </ul>
  
    </div>
      <div className="outlet">{currentTab}</div>
      </div>
      );
    }

