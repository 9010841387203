import React, { Component } from 'react'
import moment from 'moment'

import Timeline, {TodayMarker, TimelineMarkers, CustomMarker, DateHeader, TimelineHeaders, SidebarHeader, CustomHeader} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import 'moment/locale/hu';
import './CalendarPageC.css'





export default class CalendarPageC extends Component {
  constructor(props) {
    super(props)
 //   console.log(props.apartmentGroup);
//    console.log(props.reservations);


const defaultTimeStart = moment().locale('hu').startOf('month').toDate();
const defaultTimeEnd = moment().locale('hu').startOf("month").add(1, "month").toDate();


const shiftedItems = props.reservations.map(item => ({
  ...item,
  id: item.id + props.reservations.length,
  title: '',
  start_time: moment(item.start_time).add(12, 'hour'),
  end_time: moment(item.end_time).add(10, 'hour'),
}));

const items = [...props.reservations, ...shiftedItems];


//      items: props.reservations,

    this.state = {
      groups: props.apartmentGroup,
      items: items,
     // items: props.reservations,
      defaultTimeStart,
      defaultTimeEnd,
      openGroups: {},
      setOpenRegisterModal: props.setOpenRegisterModal,
      handleClick: props.handleClick
    }
  }



  toggleGroup = id => {
    const { openGroups } = this.state
    this.setState({
      openGroups: {
        ...openGroups,
        [id]: !openGroups[id]
      }
    })
  }





  render() {
    const {
      groups,
      items,
      defaultTimeStart,
      defaultTimeEnd,
      openGroups,
        setOpenRegisterModal,
        handleClick
    } = this.state



const newGroups = groups.map(group => {
    return Object.assign({}, group, {
        title: (
            <div
                style={{ cursor: 'pointer' }}
                title={group.tip}
            >
                 {group.title}
            </div>
        )  
    })
})

const onItemClick = (itemId) => {
    console.log("onItemClick", itemId);
    };

    const itemRenderer = ({
        item,
        itemContext,
        getItemProps,
        getResizeProps
      }) => {
       // const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()


       const props = getItemProps({
        style: {
      //    color: item.color,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 4,
          borderLeftWidth: itemContext.selected ? 3 : 1,
          borderRightWidth: itemContext.selected ? 3 : 1,
        },
        onMouseDown: () => {
          handleClick(item.res_ID);
        }
      });
      props.style.height = item.title === '' ? 25 : 25;
      props.style.marginTop = item.title === '' ? 0 : 24;



      return (
        <div {...props}>
  
          <div
            style={{
              maxHeight: `${itemContext.dimensions.height}`
            }}
          >
            {itemContext.title}
          </div>
  
        </div>
      );

/*
        return (
          <div {...getItemProps({
            style: {
                backgroundColor: itemContext.selected ? 'yellow' : 'blue',
               marginTop: item.title === '' ? 0 : 24,
            },
            onMouseDown: () => {
              console.log(item);
              handleClick(item.res_ID);
            }
          })}
          >
      
            <div
              className="rct-item-content"
              style={{ maxHeight: `${itemContext.dimensions.height}` }}
            >
              {itemContext.title}
            </div>
      
          </div>
        )
*/        
      }
    
    

    return (
        <Timeline
        groups={newGroups}
        items={items}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        onItemClick={onItemClick}
        sidebarWidth={250}
        canResize={true}
        lineHeight={30}
        onItemSelect = {onItemClick}
        itemRenderer={itemRenderer}
      >
  <TimelineHeaders >
  <DateHeader unit="primaryHeader" />
  <DateHeader
        unit="day"  
        labelFormat="dd, DD"
      >
      
      </DateHeader>
  
  </TimelineHeaders>
  
  
  
          <TimelineMarkers>
          <CustomMarker date={Date.now()}>
              {({ styles, date }) => {
              const customStyles = {
                  ...styles,
                  backgroundColor: 'red',      
              };
              return <div date={date} style={customStyles} />;
              }}
      </CustomMarker>
      </TimelineMarkers>
          </Timeline>
      )
  }
}















/*
import Timeline, {TodayMarker, TimelineMarkers, CustomMarker, DateHeader, TimelineHeaders, SidebarHeader} from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import React, { useState, useEffect } from "react";
import 'moment/locale/hu';

const CalendarPageC = ({apartmentGroup}) => {
   // console.log(apartmentGroup);
const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]

const items = [
    {
      id: 1,
      group: 1,
      title: 'item 1',
      start_time: moment("07/07/2023", "MM/DD/YYYY"),
      end_time: moment("07/10/2023", "MM/DD/YYYY"),
      canMove: false,
    },
    {
      id: 2,
      group: 2,
      title: 'item 2',
      start_time: moment().add(-0.5, 'hour'),
      end_time: moment().add(0.5, 'hour')
    },
    {
      id: 3,
      group: 1,
      title: 'item 3',
      start_time: moment().add(2, 'hour'),
      end_time: moment().add(3, 'hour')
    }
  ]
  
const defaultTimeStart = moment().locale('hu').startOf('month').toDate();
const defaultTimeEnd = moment().locale('hu').startOf("month").add(1, "month").toDate();

      const onItemClick = (itemId) => {
        console.log("onItemClick", itemId);
        };


     


    return (
        <>
  <div>
    Rendered by react!
    <Timeline
      groups={apartmentGroup}
      items={items}
      defaultTimeStart={defaultTimeStart}
      defaultTimeEnd={defaultTimeEnd}
      onItemClick={onItemClick}
      sidebarWidth={250}
      canResize={true}
    >
<TimelineHeaders >
<DateHeader unit="primaryHeader" />
<DateHeader
      unit="day"
      labelFormat="dd, DD"
      
    />

</TimelineHeaders>



        <TimelineMarkers>
        <TodayMarker>
            {({ styles, date }) => {
            const customStyles = {
                ...styles,
                backgroundColor: 'red',
                width: 20,
margin: 0,          
            };
            return <div date={date} style={customStyles} />;
            }}
    </TodayMarker>
    </TimelineMarkers>
        </Timeline>
  </div>
  </>
)

}

export default CalendarPageC;
*/