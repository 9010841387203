
import React, { useState  } from "react";
import DropdownMenuWithCheckboxes from "./DropdownMenuWithCheckboxes";

const AddNewCommentPanel = ({ setCommentfieldState, data, setGuests, item, rowIndex }) => {

    const roles = ["vendégkommunikátor", "takarító"];

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [newComment, setNewComment] = useState('');

    const addNewComment = (id, rowindex) => { 
        
        console.log(selectedRoles)
        
        //    setAddNewCommentFieldVisibility(false);
        setCommentfieldState({show:false, rowIndex:null});
        
            let url = process.env.REACT_APP_BACKEND_LINK + "/addnewcomment.php";
            let bodyText = `resID=${id}&comment=${newComment}&target=${selectedRoles}`; 
          
            fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: bodyText
                })
              .then((res) => res.json() )
              .then(response => {  
               const updatedGuests = [...data];
               updatedGuests[rowindex].commentsObj[response] = {comment: newComment, commentID: response, target: selectedRoles};
        
               setGuests(updatedGuests);   
                setSelectedRoles([]);  
                setNewComment('');
                //setSelectedIndex();             
               })
              .catch(error => console.log(error));   
          
          };

          const handleCancelAddNewComment = () => {
            setCommentfieldState({show:false, rowIndex:null});
            setSelectedRoles([]);
          };

    return(
        <>
            <input type="text" 
            id={"addnewinputfield" + item.res_ID} 
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
            />

            <DropdownMenuWithCheckboxes 
            roles={roles} 
            setSelectedRoles={setSelectedRoles} 
            selectedRoles={selectedRoles}
            />

            <button onClick={() => handleCancelAddNewComment()}>
            Cancel
            </button>
            <button onClick={() => addNewComment(item.res_ID, rowIndex)}
                disabled={selectedRoles.length === 0 || !newComment}
            >
            Add Comment
            </button>
        </>

    )
}

export default AddNewCommentPanel;