import {
    START_YEAR,
    NUM_OF_YEARS,
    MONTH_NAMES,
    MONTHS_PER_YEAR,
    QUARTERS_PER_YEAR,
    MONTHS_PER_QUARTER,
    NUM_OF_MONTHS,
    MAX_TRACK_START_GAP,
    MAX_ELEMENT_GAP,
    MAX_MONTH_SPAN,
    MIN_MONTH_SPAN,
    MAX_NUM_OF_SUBTRACKS,
  } from "./constants";
  
  import {
    fill,
    hexToRgb,
    colourIsLight,
    addMonthsToYear,
    addMonthsToYearAsDate,
    nextColor,
    randomTitle,
  } from "./utils";
  
  export const buildQuarterCells = () => {
    const v = [];
    for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
      const quarter = (i % 4) + 1;
      const startMonth = i * MONTHS_PER_QUARTER;
      const s = addMonthsToYear(START_YEAR, startMonth);
      const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER);
      v.push({
        id: `${s.year}-q${quarter}`,
        title: `Q${quarter} ${s.year}`,
        start: new Date(`${s.year}-${s.month}-01`),
        end: new Date(`${e.year}-${e.month}-01`),
      });
    }
    return v;
  };
  
  export const buildMonthCells = () => {
    const v = [];
    for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
      const startMonth = i;
      const start = addMonthsToYearAsDate(START_YEAR, startMonth);
      const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1);
      v.push({
        id: `m${startMonth}`,
        title: MONTH_NAMES[i % 12],
        start,
        end,
      });
    }
    return v;
  };
  
  export const buildTheCurrentMonthCells = () => {
    const v = [];
    const currentDate = new Date();
const currentMonth = currentDate.getUTCMonth();
const currentYear = currentDate.getUTCFullYear();
const daysInMonth = new Date(Date.UTC(currentYear, currentMonth + 1, 0)).getUTCDate();

const dayShortNames = ["V", "H", "K", "Sze", "Cs", "P", "Szo"];

for (let i = 1; i <= daysInMonth; i++) {
    const currDate = new Date(Date.UTC(currentYear, currentMonth, i));
    const nextDate = new Date(Date.UTC(currentYear, currentMonth, i));
    nextDate.setDate(currDate.getDate() + 1);
   v.push({  
      id: `m${i}`,
      title: dayShortNames[currDate.getUTCDay()] + i, 
      start: currDate,
      end: nextDate,
     });
}
//console.log(v)
    return v;
  };

  export const buildTimebar = () => [
    {
      id: "quarters",
      title: "Quarters",
      cells: buildQuarterCells(),
      style: {},
    },
    {
      id: "months",
      title: "Months",
      cells: buildMonthCells(),
      useAsGrid: true,
      style: {},
    },
  ];

/*eredeti
  export const buildTimebar = () => [
    {
      id: "quarters",
      title: "Quarters",
      cells: buildQuarterCells(),
      style: {},
    },
    {
      id: "months",
      title: "Months",
      cells: buildMonthCells(),
      useAsGrid: true,
      style: {},
    },
  ];
*/

/*eredeti  
  export const buildElement = ({ trackId, start, end, i }) => {
    const bgColor = nextColor();
    const color = colourIsLight(...hexToRgb(bgColor)) ? "#000000" : "#ffffff";
    return {
      id: `t-${trackId}-el-${i}`,
      title: randomTitle(),
      start,
      end,
      style: {
        backgroundColor: `#${bgColor}`,
        color,
        borderRadius: "4px",
        boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
        textTransform: "capitalize",
      },
    };
  };
*/  
export const buildElement = ({ trackId, start, end, i }) => {
  const bgColor = nextColor();
  const color = colourIsLight(...hexToRgb(bgColor)) ? "#000000" : "#ffffff";
  return {
    id: `t-${trackId}-el-${i}`,
    title: randomTitle(),
    start,
    end,
    style: {
      backgroundColor: `#${bgColor}`,
      color,
      borderRadius: "4px",
      boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
      textTransform: "capitalize",
    },
  };
};
  export const buildTrackStartGap = () =>
    Math.floor(Math.random() * MAX_TRACK_START_GAP);

  export const buildElementGap = () =>
    Math.floor(Math.random() * MAX_ELEMENT_GAP);
  

/*eredeti
  export const buildElements = (trackId) => {
    const v = [];
    let i = 1;
    let month = buildTrackStartGap();
  
    while (month < NUM_OF_MONTHS) {
      let monthSpan =
        Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) +
        MIN_MONTH_SPAN;
  
      if (month + monthSpan > NUM_OF_MONTHS) {
        monthSpan = NUM_OF_MONTHS - month;
      }
  
      const start = addMonthsToYearAsDate(START_YEAR, month);
      const end = addMonthsToYearAsDate(START_YEAR, month + monthSpan);
      v.push(
        buildElement({
          trackId,
          start,
          end,
          i
        })
      );
      const gap = buildElementGap();
      month += monthSpan + gap;
      i += 1;
    }
  
    return v;
  };
*/

export const buildElements = (trackId, quantity) => {
  const v = [];
  let i = 1;
  let month = buildTrackStartGap();

 while (month < NUM_OF_MONTHS) {
      let monthSpan =
      Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) +
      MIN_MONTH_SPAN;

    if (month + monthSpan > NUM_OF_MONTHS) {
      monthSpan = NUM_OF_MONTHS - month;
    }

    const start = addMonthsToYearAsDate(START_YEAR, month);
    const end = addMonthsToYearAsDate(START_YEAR, month + monthSpan);
    v.push(
      buildElement({
        trackId,
        start,
        end,
        i
      })
    );
    const gap = buildElementGap();
    month += monthSpan + gap;
    i += 1;
  }

  return v;
};

  
  export const buildSubtrack = (trackId, subtrackId, quantity) => ({
    id: `track-${trackId}-${subtrackId}`,
    title: `Subtrack ${subtrackId}`,
    elements: buildElements(subtrackId, quantity),
  });
  


  export const buildTrack = (trackId, apartmentUnit, reservations) => {
    const tracks = Object.keys(reservations).map(
      (i) => buildSubtrack(trackId, i + 1, Object.keys(reservations).length)
    );
    return {
      id: `track-${trackId}`,
      title: apartmentUnit.short_name,
      elements: buildElements(trackId, Object.keys(reservations).length),
      tracks,
      // link: 'www.google.com',
      isOpen: false,
    };
  };


  /*ez beírja a title-t
    export const buildTrack = (trackId, apartmentUnit) => {
    const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1).map(
      (i) => buildSubtrack(trackId, i + 1)
    );
    return {
      id: `track-${trackId}`,
      title: apartmentUnit.short_name,
      elements: buildElements(trackId),
      tracks,
      // link: 'www.google.com',
      isOpen: false,
    };
  };
  */

  /*eredeti
  export const buildTrack = (trackId) => {
    const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1).map(
      (i) => buildSubtrack(trackId, i + 1)
    );
    return {
      id: `track-${trackId}`,
      title: `Track ${trackId}`,
      elements: buildElements(trackId),
      tracks,
      // link: 'www.google.com',
      isOpen: false,
    };
  };
  */