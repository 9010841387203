import { countrySuggestions } from "./CountrySuggestions"
import { nationalitySuggestions } from "./NationalitySuggestions"
import { citySuggestions } from "./CitySuggestions"

export const personalDataFields = [
    {
        field: "firstname",
        label: "First Name",
        type: "text",
        suggestions: [],
      },
      {
        field: "lastname",
        label: "Last Name",
        type: "text",
        suggestions: [],
      },
        {
      field: "birthdate",
      label: "Birth Date",
      type: "date",
      suggestions: [],
    },
    {
      field: "placeofbirth",
      label: "Place of Birth",
      type: "text",
      suggestions: [],
    },
    {
      field: "nationality",
      label: "Nationality",
      type: "text",
      suggestions: nationalitySuggestions,
    },
    {
      field: "zipcode",
      label: "Zip Code",
      type: "text",
      suggestions: [],
    },
    {
      field: "country",
      label: "Country",
      type: "text",
      suggestions: countrySuggestions,
    },
    {
      field: "city",
      label: "City",
      type: "text",
      suggestions: citySuggestions,
    },
    {
      field: "address",
      label: "Address",
      type: "text",
      suggestions: [],
    },
    {
      field: "documenttype",
      label: "Document Type",
      type: "select",
      options: ["select one", "ID", "Passport", "Driver Licence", "Other"]
    },
    {
      field: "documentnumber",
      label: "Document Number",
      type: "text",
      suggestions: [],
    }
  ];



  
  export default {
    personalDataFields,
}

/*
const personalDataFieldsDB = {
    personalDataFields,
  };
  
  export default personalDataFieldsDB;
  */