import React, { useEffect, useState } from 'react';


export const ApartmentUnitSelector = ({ onChange, setToZero }) => {

    const [apartmentUnits, setApartmentUnits] = useState([]);
    const [state, setState] = useState(0);

    useEffect(() => {
        const storedData = localStorage.getItem('apartmentUnitsCached');
        setApartmentUnits(prevState => [...prevState, ...JSON.parse(storedData)]);
    }, []);

const handleChange = (event) => {
    setState(event.target.value);
    onChange(event);
}

useEffect(() => {
    setState(0);
}, [setToZero])

    return (
        <>
            <select onChange={handleChange}
    value={state}
            >
                <option value="0">válassz apartmant</option>
                    {apartmentUnits.map(apartmentUnit => (
                    <option key={apartmentUnit.apartment_unit_ID} value={apartmentUnit.apartment_unit_ID}>
                        {apartmentUnit.long_name} ({apartmentUnit.short_name})
                    </option>
                    ))}
            </select>
        </>
    );
};

export default ApartmentUnitSelector;