import React, { useState, useEffect  } from "react";
import Calendar from 'react-calendar';

const ArrivingGuestsForCleaners = ({pickedDate, apartmentUnits, currCleaner, cleaningPrices}) => {

    const [cleanerTasks, setCleanerTasks] = useState([]);


  useEffect(() => {
    if (apartmentUnits && apartmentUnits.length !== 0) {

        const pickedDateInDateFormat = new Date(pickedDate);
        pickedDateInDateFormat.setMinutes(pickedDateInDateFormat.getMinutes() - pickedDateInDateFormat.getTimezoneOffset()); 
        const pickedDateInString = pickedDateInDateFormat.toISOString().slice(0,10)
  
        const todayInDateFormat = new Date();
        todayInDateFormat.setMinutes(todayInDateFormat.getMinutes() - todayInDateFormat.getTimezoneOffset()); 
        const todayInString = todayInDateFormat.toISOString().slice(0,10)

        let pickedDateObj = new Date(pickedDateInString);
        let todayObj = new Date(todayInString);
              
  
        if (pickedDateObj < todayObj) {
          console.log('MÚLT');
        } else if (pickedDateObj > todayObj) {
          console.log('JÖVŐ');
        } else {
          console.log('JELEN');
        }

    const fetchData = async () => {
      try {
        const bodyText = `date=${pickedDateInString}&employee_ID=${currCleaner}`;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_LINK}/queryCleanerTasks.php`, {
          method: 'POST',
          headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
        });
        const data = await response.json();

          data.forEach((item) => {                        //apartment_unit_id átírása short_name-re
            let apartmentName = "";
            apartmentUnits.forEach((apartment) => {
              if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                apartmentName = apartment.short_name;
              }
            });
            item.apartment_short_name = apartmentName;
          });

          data.forEach((item) => {
            let cleaningPrice = 0;
            let cleaningID = 0;
            cleaningPrices.forEach((price) => {
                if (price.cleaning_ID === item.cleaning_ID) {
                    cleaningPrice = price.price;
                    cleaningID = price.cleaning_name;
                }
            });
            item.base_price = cleaningPrice;
            item.cleaning_ID = cleaningID;
          });

          setCleanerTasks(data);
      } catch (error) {
        console.log(error.message);
      }
    };
  
    fetchData();
}
  }, [pickedDate, apartmentUnits, currCleaner, cleaningPrices])

    const handleClick = (apartment_unit_id, date) => {
        console.log(apartment_unit_id + '  '+ date)
        const bodyText = `apartment_unit_id=${apartment_unit_id}&date=${date}&employee_ID=${currCleaner}`;
        fetch(`${process.env.REACT_APP_BACKEND_LINK}/updateCleanerTask.php`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: bodyText
        });

        const newCleanerTasks = cleanerTasks.filter((task) => {
            return task.apartment_unit_id !== apartment_unit_id;
        })
        setCleanerTasks(newCleanerTasks);
    }



return (
    <div>
        {cleanerTasks.map((task, index) => {

      return (  
    <div
    key={index}
    style={{
      display: "flex",
      flexDirection: "column",
      border: "3px solid brown",
      borderRadius: "10px",
      width: "100%",
      marginBottom: "10px",
      backgroundColor:  'white' 
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px"
      }}
    >
      <div style={{ fontWeight: "bold" }}>
        {task.apartment_short_name}
      </div>
      <div>
        feladat: {task.cleaning_ID}
        </div>
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px"
      }}
    >
      <div>
         Alapár: {task.base_price} Ft / Összeg: {task.price} Ft
      </div>
      <div>
        <button onClick={()=>{handleClick(task.apartment_unit_id, task.date)}}>KITAKARÍTVA</button>
      </div>
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px"
      }}
    >
      <div>
        Bejutás: {task.entry_type} / Kulcsszéf kódja: {task.key_safe_code}
      </div>
</div>


    </div>
    )
})}

    </div>
    );

}
export default ArrivingGuestsForCleaners;

