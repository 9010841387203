import React, { useState, useRef } from "react";

const TimeInputField = ({row, setGuests, action}) => {
    const [selected, setSelected] = useState({});
    const inputRef = useRef(null);

let defaultTimevalue = row.original[action];

const handleChange = (event) => {
    setSelected({...selected, [event.target.id]:event.target.value});
}

const handleBlur = (event) => {
    setGuests(guests => {
        const newGuests = [...guests];
        newGuests.forEach((guest) => {
          if (guest.res_ID === row.original.res_ID) {
            guest[''+action+''] = event.target.value;
          }
        })
        return newGuests;
      })

    if(action !== undefined){
      let url = process.env.REACT_APP_BACKEND_LINK + "/updatereservationtimes.php";

      let formData = {};
      formData['res_ID'] = parseInt(row.original.res_ID);
      formData[action] = event.target.value;

      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-type' : 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(formData)
      })
      .then((res) => res.json() )
      .then(response => {  
        console.log(response)
       })
      .catch(error => console.log(error));
    }    
}

const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation(); // Add this line to stop event bubbling
      handleBlur(event);
    }
  }

    return(
        <>
            <input  type="text" 
                    id={action+'_'+row.index} 
                    value={selected[action+'_'+row.index] || defaultTimevalue}
                    onChange={(event)=>(handleChange(event))}
                    style={{ width: '100px',  textAlign: 'center' }}
                    ref={inputRef}
                    key={row.index}
                    onBlur={(event)=>(handleBlur(event))}
                    onKeyDown={(event)=>(handleKeyDown(event))}
            />
        </>
    )
}
export default TimeInputField;