import React, { useState, useEffect  } from "react";
import ReservationInfoTab from "./ReservationInfoTab";
import CleaningModalCleaningTab from "./CleaningModalCleaningTab";

const CleaningModal = ({ openModal, setOpenModal }) => {

    const [activeTab, setActiveTab] = useState("tab1");

    const MODAL_STYLES = {
        position: "absolute",
        backgroundColor: "#FFF",
        padding: "15px",
        zIndex: "1000",
        width: "75%",
        borderRadius: ".5em",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
       /* boxShadow: "0 5px 10px rgba(0,0,0, .3)",*/
        maxHeight: "90vh",
        overflowY: "auto"        
      };
      const OVERLAY_STYLE = {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0, .8)",
        zIndex: "1000",
        overflowY: "auto"
      };


      let tabC;

if(activeTab === "tab1"){
    let pickedDate = new Date(openModal.pickedDate);
    tabC = <CleaningModalCleaningTab apartment_unit_id={openModal.apartment_unit_id} 
                                     dateOfTheJob={pickedDate}
            />
}
if(activeTab === "tab2"){
    tabC = <ReservationInfoTab apartment={openModal.job} />
}

const closeModal = () => {
  setOpenModal(openModal => ({...openModal, show:false}))
}

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal()
    }
  };

  document.addEventListener('keydown', handleKeyDown);

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, [setOpenModal]);




    return (
        <>
          <div style={OVERLAY_STYLE}>
            <div style={MODAL_STYLES}>

            <div className="Tabs">
            <ul className="nav">
                <li
                className={activeTab === "tab1" ? "active" : ""}
                onClick={() => setActiveTab("tab1")}
                >
                Takarítás
                </li>
                <li
                className={activeTab === "tab2" ? "active" : ""}
                onClick={() => setActiveTab("tab2")}
                >
                Foglalás adatai
                </li>
            </ul>  
            </div>
            

            <div>{tabC}</div>


            <div>
            <button onClick={closeModal}>Bezár</button>
            </div>

            </div>
          </div>
        </>
    )
      
}
export default CleaningModal;
