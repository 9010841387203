
export const countrySuggestions = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
];

export default {
    countrySuggestions,
}

