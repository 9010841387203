
export const citySuggestions = [
    "Budapest",
    "Debrecen",
    "Szeged",
    "Miskolc",
    "Pécs",   
];

export default {
    citySuggestions,
}
