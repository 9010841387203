
import { useState, useRef, useCallback, useEffect } from 'react';
import "./styles/TextbookStyle.css"


export function Textbook() {
   
  const [textbooks, setTextbooks] = useState([]);
  const [currentTextbook, setCurrentTextbook] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [editedIndex, setEditedIndex] = useState(-1);
  const [editedName, setEditedName] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const inputRef = useRef(null);

  const userId = localStorage.getItem('id');


      const fetchTextbooksFromDatabase = useCallback(() => {
        let url = process.env.REACT_APP_BACKEND_LINK + "/querytextbook.php";
        let bodyText = `owner=${userId}`; 
    
        fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: bodyText
            })
            .then((res) => res.json())
            .then((response) => {
               setTextbooks(response)
          })
      }, [userId]);

      useEffect(() => {
        fetchTextbooksFromDatabase();
        }, [fetchTextbooksFromDatabase]);
      
        useEffect(() => {
          const timer = setTimeout(() => {
            setErrorMessage('')
          }, 1000);
          return () => clearTimeout(timer);
        }, [errorMessage])
  
  const handleEdit = useCallback((index) => {
    setEditedIndex(index);
    setEditedName(textbooks[index].title);
    setCurrentTextbook(textbooks[index]);
  }, [textbooks]);

  const handleSave = useCallback((index) => {
    if (editedName.trim() !== '') {
      setTextbooks((list) => {
        const newList = [...list];
        newList[index].title = editedName;
        saveTitleChanges(newList[index]);
        return newList;
      });
    }
    setEditedIndex(-1);
    setEditedName('');
  }, [editedName]);

  const handleCancel = useCallback(() => {
    setEditedIndex(-1);
    setEditedName('');
    setNewItemName('');
  }, []);

  const handleNewItemClick = useCallback(() => {
    setEditedIndex(textbooks.length);
    setNewItemName('');
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  }, [textbooks]);


  const deleteTitle = useCallback((id) => {
    let url = process.env.REACT_APP_BACKEND_LINK + "/deletetextbook.php";
    let bodyText = `titleid=${id}`; 
  
    fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: bodyText
        })
      .then((res) => res.json() )
      .then(response => {  
        //fetchTextbooksFromDatabase();
  
        let newTextbookArray = [];
  
        textbooks.map((field) => {
          if(field.id !== id){
            newTextbookArray.push(field)
          }
          return null;
        })
  
        setTextbooks(newTextbookArray)
  
       })
      .catch(error => console.log(error));
  
  }, [textbooks]);

  const addNewTitle = useCallback((title, content) => {
    let url = process.env.REACT_APP_BACKEND_LINK + "/addnewtextbook.php";
    let bodyText = `userId=${userId}&title=${title}&content=${content}`; 
  
    fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: bodyText
        })
      .then((res) => res.json() )
      .then(response => {  
        fetchTextbooksFromDatabase();
       })
      .catch(error => console.log(error)); 
  }, [fetchTextbooksFromDatabase, userId]);

  const handleNewItemSave = useCallback(() => {
    if (newItemName.trim() !== '') {
      setTextbooks((list) => {
        const newList = [...list];
        newList.push({ id: Date.now(), title: newItemName, content: "" });
        return newList;
      });
      addNewTitle(newItemName, "new content");
    }
    setEditedIndex(-1);
    setNewItemName('');
  }, [newItemName, addNewTitle]);


  const handleSaveTextbookContentChange = (e) => {
     console.log('Mentés:', currentTextbook.id, currentTextbook);

     let url = process.env.REACT_APP_BACKEND_LINK + "/updatetextbookcontent.php";
     let bodyText = `titleid=${currentTextbook.id}&textbookcontent=${currentTextbook.content}`; 
 
     fetch(url, {
         method: "POST",
         headers: {
           "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
         },
         body: bodyText
         })
       .then((res) => res.json() )
       .then(response => {  
 
         if(response.insert === "success"){
           setErrorMessage("Sikerült elmenteni a változásokat")
         
           textbooks.map((field) => {
             if(field.id === currentTextbook.id){
                 field.content = currentTextbook.content;
             }
             return null;
           })
         } else {
           setErrorMessage("Nem sikerült elmenteni a változásokat!")
         }   
        })
       .catch(error => console.log(error));
   };

   const handleTextbookTitleClick = (currTextbook) => {
    currTextbook.isEditing = false;
    setCurrentTextbook(currTextbook);
  };


  const saveTitleChanges = (currTextbook) => {

    let titleID = currTextbook.id;
    let newTitle = currTextbook.title;

    let url = process.env.REACT_APP_BACKEND_LINK + "/updatetextbooktitle.php";
    let bodyText = `titleid=${titleID}&title=${newTitle}`; 

    fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: bodyText
        })
      .then((res) => res.json() )
      .then(response => {  
        if(response.insert === "success"){
          setErrorMessage("Sikerült elmenteni a változásokat")
       //  fetchTextbooksFromDatabase()

  
       setTextbooks((prevFields) =>
         prevFields.map((field) =>
           field.id === titleID ? { ...field, title: newTitle,  isEditing: false } : field
         )
       );  


        } else {
          setErrorMessage("Nem sikerült elmenteni a változásokat!")
        } 
       })
      .catch(error => console.log(error));
  };

  const handleDelete = useCallback((index) => {
    if (editedIndex !== index) {
      deleteTitle(textbooks[index].id)
      setTextbooks((list) => {
        const newList = [...list];
        newList.splice(index, 1);
        return newList;
      });
    }
  }, [editedIndex, textbooks, deleteTitle]);


  const disableButtons = editedIndex !== -1;

  return (
    <div className="flex-parent-element">
    {errorMessage && <div className="error"> {errorMessage} </div>}
    <div className="flex-child-element magenta">
    <table >
      <tbody>

  {textbooks.map((item, index) => (
      <tr style={{  display: "flex", flexDirection: "row", justifyContent: "space-between",  width: "450px", border: "1px black solid", margin: "0 auto" }} key={item.id}>

      {editedIndex === index ? (
          <td>
          <div style={{border:"2px solid red"}} key={item.id}>
  
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  autoFocus
                />
                <button onClick={() => handleSave(index)} disabled={!editedName.trim()}>
                  Mentés
                </button>
                <button onClick={handleCancel}>
                  Mégse
                </button>
              </div>
              </td>
            ) : (
              <td>
              <div style={{border:"2px solid red"}} key={item.id} onClick={() =>  disableButtons ? console.log("dd") : handleTextbookTitleClick(item)}>
                    {item.title}
                {
                  <>
                  <button onClick={() => handleEdit(index)} disabled={disableButtons}> Módosít </button>
                  <button onClick={() => handleDelete(index)} disabled={disableButtons}> Törlés </button>
                  </>
                }
              </div>
              </td>
            )}

      </tr>        ))}
    

      <tr style={{ border: "1px black solid" }} key="01">
            <td>
      {editedIndex === textbooks.length ? (
        <>
          <input
            type="text"
            value={newItemName}
            onChange={(e) => setNewItemName(e.target.value)}
            ref={inputRef}
          />
          <button onClick={handleNewItemSave} disabled={!newItemName.trim()}>
            Mentés
          </button>
          <button onClick={handleCancel}>
            Mégse
          </button>
          </>
      ) : (
        <>
        <button onClick={handleNewItemClick} disabled={disableButtons}>
          Új elem hozzáadása
        </button>
        </>
      )}
              </td>
        </tr>

          </tbody>
          </table>
          </div>



          <div className="flex-child-element green">
        <textarea className="textarea"
          value={currentTextbook.content}
          onChange={e => {
            setCurrentTextbook(prevValues => ({
              ...prevValues,
              content: e.target.value
            }));             
          } }
          onBlur={e => {handleSaveTextbookContentChange(e.target.value)} }
        />
        </div>
          </div>
  );
}
