import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import { useState} from "react"
import "./MainNavbar.css"

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


export function MainNavbar(data){

    const [expanded, setExpanded] = useState(false);

    const [show, setShow] = useState(false);
    const handleToggle = () => {
      setShow((prev) => !prev);
    };



    function NavbarMenu({ type }) {
      if (type === "0") {  //admin
        return (             
        <Nav className="mx-1 d-lg-flex">
        
        <Nav.Link as={Link} to="home" onClick={() => setExpanded(false)}>
        <i className="bi bi-house-fill d-block d-sm-none" style={{fontSize:'30px'}}></i>        
        <span className="d-none d-sm-block" ><i className="bi bi-house-fill d-none d-md-inline"></i>Kezdőlap</span>
        </Nav.Link>
        <Nav.Link as={Link} to="payment" onClick={() => setExpanded(false)}>
          <i className="bi bi-list d-block d-sm-none" style={{fontSize:'30px'}}></i>
          <span className="d-none d-sm-block"><i className="bi bi-list d-none d-md-inline"></i>Ajánlatok</span>
        </Nav.Link>
        <Nav.Link as={Link} to="settings" onClick={() => setExpanded(false)}>
          <i className="bi bi-journal-text d-block d-sm-none" style={{fontSize:'30px'}}></i>
          <span className="d-none d-sm-block"><i className="bi bi-journal-text d-none d-md-inline"></i>Könyvelés</span>
        </Nav.Link>
        <Nav.Link as={Link} to="settings" onClick={() => setExpanded(false)}>
          <i className="bi bi-houses-fill d-block d-sm-none" style={{fontSize:'30px'}}></i>
          <span className="d-none d-sm-block"><i className="bi bi-houses-fill d-none d-md-inline"></i>Apartmaninfók</span>
          </Nav.Link>
        <Nav.Link as={Link} to="settings" onClick={() => setExpanded(false)}>
          <i className="bi bi-gear d-block d-sm-none" style={{fontSize:'30px'}}></i>
          <span className="d-none d-sm-block"><i className="bi bi-gear d-none d-md-inline"></i>Kódok</span>
        </Nav.Link>
        </Nav>
        )
    }

      /*régi. biztmás.
        if (type == 0) {
            return (             
            <Nav className="mx-1 d-lg-flex">
            
            <Nav.Link as={Link} to="home" className="d-block d-sm-none" onClick={() => setExpanded(false)}><i className="bi bi-house-fill"></i></Nav.Link>
            <Nav.Link as={Link} to="home" className="d-none d-sm-block" onClick={() => setExpanded(false)}>Kezdőlap</Nav.Link>


            <Nav.Link as={Link} to="payment" onClick={() => setExpanded(false)}><i className="bi bi-list"></i>Ajánlatok</Nav.Link>
            <Nav.Link as={Link} to="settings" onClick={() => setExpanded(false)}><i className="bi bi-journal-text"></i>Könyvelés</Nav.Link>
            <Nav.Link as={Link} to="settings" onClick={() => setExpanded(false)}><i className="bi bi-houses-fill"></i>Apartmaninfók</Nav.Link>
            <Nav.Link as={Link} to="settings" onClick={() => setExpanded(false)}><i className="bi bi-gear"></i>Kódok</Nav.Link>
            </Nav>
            )
        }
        */
        if (type === "1") { //vendégfogadó
            return (                 
            <Nav className="mx-1 d-none d-lg-flex">
            <Nav.Link as={Link} to="home" onClick={() => setExpanded(false)}>Vendégforgalom</Nav.Link>
            <Nav.Link as={Link} to="apartments" onClick={() => setExpanded(false)}>Apartmaninfók</Nav.Link>
            <Nav.Link as={Link} to="payment" onClick={() => setExpanded(false)}>Fizetés</Nav.Link>
            <Nav.Link as={Link} to="tours" onClick={() => setExpanded(false)}>Ajánlatok</Nav.Link>
            <Nav.Link as={Link} to="tasks" onClick={() => setExpanded(false)}>Feladatok</Nav.Link>
            </Nav>                
            )
        }
        if (type === "2") { //vendégkommunikátor
          return  ( 
              <Nav className="mx-1 d-none d-lg-flex fonttest">
              <Nav.Link as={Link} to="home" onClick={() => setExpanded(false)}>Vendégforgalom</Nav.Link>
              <Nav.Link as={Link} to="calendar" onClick={() => setExpanded(false)}>Naptárnézet</Nav.Link>
              <Nav.Link as={Link} to="addreservation" onClick={() => setExpanded(false)}>Új Vendég hozzáadása</Nav.Link>
              <Nav.Link as={Link} to="payment" onClick={() => setExpanded(false)}>Beosztás</Nav.Link>
              <Nav.Link as={Link} to="apartments" onClick={() => setExpanded(false)}>Apartmaninfók</Nav.Link>
              <Nav.Link as={Link} to="tours" onClick={() => setExpanded(false)}>Ajánlatok</Nav.Link>
              <Nav.Link as={Link} to="textbook" onClick={() => setExpanded(false)}>Jegyzettömb</Nav.Link>
              <Nav.Link as={Link} to="tasks" onClick={() => setExpanded(false)}>Feladatok</Nav.Link>
              </Nav>
          )
      }
      if (type === "3") { //supervisor
        return  ( 
            <Nav className="mx-1 d-none d-lg-flex fonttest">
            <Nav.Link as={Link} to="home" onClick={() => setExpanded(false)}>Vendégforgalom</Nav.Link>
            <Nav.Link as={Link} to="weeklycleaningschedule" onClick={() => setExpanded(false)}>Heti Beosztás</Nav.Link>
            <Nav.Link as={Link} to="addnewcleaningtask" onClick={() => setExpanded(false)}>Új takarítás hozzáadása</Nav.Link>
            <Nav.Link as={Link} to="cleaningprices" onClick={() => setExpanded(false)}>Takarítási árak</Nav.Link>
            <Nav.Link as={Link} to="codes" onClick={() => setExpanded(false)}>Kódok</Nav.Link>
            </Nav>
        )
    }
    if (type === "4") { //takarító
      return  ( 
          <Nav className="mx-1 d-none d-lg-flex fonttest">
          <Nav.Link as={Link} to="home" onClick={() => setExpanded(false)}>Vendégforgalom</Nav.Link>
          </Nav>
      )
  }
}

const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      <Nav className="mx-1 popupmenu">
                <Nav.Link as={Link} to="home" onClick={handleToggle}>Kezdőlap</Nav.Link>
                <Nav.Link as={Link} to="payment" onClick={handleToggle}>Apartmaninfók</Nav.Link>
                <Nav.Link as={Link} to="settings" onClick={handleToggle}>Ajánlatok</Nav.Link>
                <Nav.Link as={Link} to="settings" onClick={handleToggle}>Jegyzetek</Nav.Link>
                </Nav>
      </Popover.Body>
    </Popover>
  );

return(
    <Container fluid>
    <Navbar   fixed="top" className="px-lg-5 px-md-2 test" expanded={expanded} >

    <Navbar.Brand>
    <img src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" className="d-none d-sm-inline" alt=""/>
    </Navbar.Brand>
    <Navbar.Brand className="d-none d-md-block">HostHeaven</Navbar.Brand>


   
      
    <NavbarMenu type={data.menu}/>
      
      <Nav className="ms-auto d-none d-md-block">
          <NavDropdown title={data.user} id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Beállítások</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="logout">Kijelentkezés</NavDropdown.Item>
          </NavDropdown>
      </Nav>
  


      <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose show={show}
        onToggle={handleToggle}>
    <Button variant="success" className="d-md-none ms-auto" onClick={()=>setShow(true)}>Menu</Button>
  </OverlayTrigger>
  

</Navbar>
</Container>
    );

}
