import React, { useState, useEffect  } from "react";
import { useNavigate  } from "react-router-dom";
//import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.css';
import "./styles/LoginPageStyle.css"

export const LoginPage = () => {

 const [errorMessages, setErrorMessages] = useState("");

 //const [username, setUserName] = useState();
 //const [password, setPassword] = useState();
 const [data, setData] = useState({name:"", pass:""});

 useEffect(() => {
  localStorage.clear();
 }, [])


 const navigate = useNavigate();
// const { search } = useLocation();
 //const parameters = new URLSearchParams(search);

const processLoginInfos = () => {

  let bodyText = `username=${data.name}&password=${data.pass}`; 

let url = process.env.REACT_APP_BACKEND_LINK + "/queryEployees.php";

    fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: bodyText
  })
  .then((res) => res.json())
  .then((response) => {
      if(response.length === 0){          
          setErrorMessages( "invalid" );
      } else {
        localStorage.setItem('id', response[0].employee_ID);   
        localStorage.setItem('name', response[0].name);   
        localStorage.setItem('menu', response[0].startpage);   

        navigate("/home", { replace: true });    
      }
  });  
}


const handleSubmit = (event) => {
  event.preventDefault();
  processLoginInfos();
};


  return (
    <div className="back">
    <div className="div-center">


      <div className="content">


        <h3>Login</h3>
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Username</label>
            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Username" onChange={e => setData(existingValues => ({...existingValues, name: e.target.value,}))   } />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={e => setData(existingValues => ({...existingValues, pass: e.target.value,}))   } />
          </div>
          <span className="error">{errorMessages}</span>
          <button type="submit" className="btn btn-primary">Login</button>
          <hr />
        </form>

      </div>

      </div>
      </div>
  );
}