import React, { useState  } from "react";

function CleanerSelector2({ cleaners, onCleanerChange, assignedCleaner, index, apartment_unit_ID }) {

    const [selected, setSelected] = useState({['id_' + index]: assignedCleaner || '0'});
  
    const changer = (event) => {
        setSelected({['id_' + index]: event.target.value});
        onCleanerChange(event);
    }


  return (
    <div>
        <select  value={selected['id_'+index] || assignedCleaner || '0'}
                 onChange={changer}
        >

        <option key={'01'} value='0' data-apartmentid={apartment_unit_ID}>
                        Válassz takarítót
                    </option>
                  {Object.keys(cleaners).map((cleaner, index) => (
                      <option key={index+1} value={cleaners[cleaner].employee_ID} data-apartmentid={apartment_unit_ID}>
                        {cleaners[cleaner].name}
                      </option>
                    ))}
        </select>
    </div>
  );
}

export default CleanerSelector2;