
export const nationalitySuggestions = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
];

export default {
    nationalitySuggestions,
}
