import React, { useState } from "react";
import { useTable, useSortBy, useFlexLayout } from "react-table";
import { BsFillArrowDownSquareFill, BsFillArrowUpSquareFill } from "react-icons/bs";
import "../styles/GuestsTableStyles.css";
import CommentRow from "./CommentRow";
import AddNewCommentPanel from "./AddNewCommentPanel";


const GuestsTable = ({ columns, data, commentfieldState, setCommentfieldState, setGuests }) => {
    

    const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
    },

    useSortBy,
    useFlexLayout
  );



const [dropdown, setDropdown] = useState(false);
const toggleOpen = () => setDropdown(!dropdown);

  return (
<>


<div className="dropdown">
      <button onClick={toggleOpen}>Dropdown</button>
      <div className={`dropdown-menu ${dropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
        {allColumns.map((column) => (
          <div className="cb action" key={column.id}>
      <label>
        <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
        <span>{column.Header}</span>
      </label>
    </div>
          ))}        
      </div>
    </div>





    <table {...getTableProps()} className="tableComponent">
      <thead className="theadComponent" {...getTableBodyProps()}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="trComponent">
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="headercomponent"
              >

                <p className="pcomponent">
                {column.render("Header")}
                {!column.disableSortBy ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort_icon">
                        <BsFillArrowDownSquareFill />
                      </span>
                    ) : (
                      <span className="sort_icon">
                        <BsFillArrowUpSquareFill />
                      </span>
                    )
                  ) : (
                    <span className="sort_icon sortable">
                      <BsFillArrowUpSquareFill />
                      <BsFillArrowDownSquareFill />
                    </span>
                  )
                ) : null}
              </p>

              </th>
            ))}
          </tr>
        ))}
      </thead>



      <tbody {...getTableBodyProps()} className="bodyComponent">
        {rows.map((row, rowIndex) => {
          prepareRow(row);

          const res_ID = row.original.res_ID;

          return (
            <>
            <tr {...row.getRowProps()} className="bodyTr"  >

              {row.cells.map((cell, indexx) => {
                    return (
                    <td
                      {...cell.getCellProps({
                        style: { backgroundColor: row.original.cleaning_status === "0" ?  'red' : '#31dd31' },
                     })}
                      className="tdcomponent" 
                      key={"td" + indexx}
                    >
                      <p>
                        {cell.column.Header === "#"
                          ? rowIndex + 1
                          : cell.render("Cell")}
                      </p>
                    </td>
                );
            })}
            </tr>

              {data.map((item) => {
                if (item.res_ID === res_ID) {
                      return (
                        <React.Fragment>

                         {Object.keys(item.commentsObj).length !== 0 && (                          
                          <tr>
                            <td className="comment-container">


                                  {Object.keys(item.commentsObj).map((currComment, index) => (
                                    <React.Fragment>
                                      <CommentRow commentID={item.commentsObj[currComment].commentID} 
                                                  target={item.commentsObj[currComment].target} 
                                                  comment={item.commentsObj[currComment].comment}
                                                  rowIndex={rowIndex}
                                                  data={data}
                                                  setGuests={setGuests}
                                      />
                                    </React.Fragment>
                                  ))}

                 
                            </td>
                          </tr>
                        )}

                        {commentfieldState.show && commentfieldState.rowIndex === rowIndex && (
                          <tr>
                            <td colSpan={5}>

                              <AddNewCommentPanel setCommentfieldState={setCommentfieldState}
                                                  data={data}
                                                  setGuests={setGuests}
                                                  item={item}
                                                  rowIndex={rowIndex}
                              />

                            </td>
                          </tr>
                        )}
                          </React.Fragment>
                      );
                }
              return null;
                })}
            </>
          );


        })}
      </tbody>
    </table>

</>    
  );
};
export default GuestsTable;

