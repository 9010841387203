import React, { useState, useEffect  } from "react";
import GuestsTable from "./GuestsTable";
import Modal from "./Modal";
import GuestPropButton from "./GuestPropButton";
import AddNewCommentButton from "./AddNewCommentButton";
import HostSelector from "./HostSelector";
import DepartureButton from "./DepartureButton";
import ArrivalButton from "./ArrivalButton";


const AllGuestsTable = ({pickedDate, hosts, apartments}) => {

    const [arrivingGuests, setArrivingGuests] = useState([]);
    const [departingGuests, setDepartingGuests] = useState([]);
    const [currentGuests, setCurrentGuests] = useState([]);

    const [tableTitles, setTableTitles] = useState({arrivalTableTitle: 'a mai nap érkező vendégek', departureTableTitle: 'a mai nap távozó vendégek', currentTableTitle: 'a mai nap itt tartózkodó vendégek'});

    const [commentfieldState, setCommentfieldState] = useState({show:false, rowIndex:null});

    const [openArrivingModal, setOpenArrivingModal] = useState({show:false});
    const [openDepartingModal, setOpenDepartingModal] = useState({show:false});
    const [openCurrentsModal, setOpenCurrentsModal] = useState({show:false});

    const [isDisabled, setIsDisabled] = useState([]);

    useEffect(() => {
        const pickedDateInDateFormat = new Date(pickedDate);
        pickedDateInDateFormat.setMinutes(pickedDateInDateFormat.getMinutes() - pickedDateInDateFormat.getTimezoneOffset()); 
        const pickedDateInString = pickedDateInDateFormat.toISOString().slice(0,10)

        const todayInDateFormat = new Date();
        todayInDateFormat.setMinutes(todayInDateFormat.getMinutes() - todayInDateFormat.getTimezoneOffset()); 
        const todayInString = todayInDateFormat.toISOString().slice(0,10)

        let arrivalStatus = 10;
        let departureStatus = 10;
        let currentsStatus = 10;
 


//console.log(pickedDateInDateFormat + "     " + todayInDateFormat)

let pickedDateObj = new Date(pickedDateInString);
let todayObj = new Date(todayInString);

if (pickedDateObj < todayObj) {
  console.log('MÚLT');
  setTableTitles({arrivalTableTitle: 'ezen a napon érkezett vendégek',  departureTableTitle: 'ezen a napon távozott vendégek', currentTableTitle: 'ezen a napon itt tartózkodott vendégek'})
} else if (pickedDateObj > todayObj) {
  console.log('JÖVŐ');
  setTableTitles({arrivalTableTitle: 'ezen a napon érkező vendégek',  departureTableTitle: 'ezen a napon távozó vendégek', currentTableTitle: 'ezen a napon itt tartózkodó vendégek'})
} else {
  console.log('JELEN');
  setTableTitles({arrivalTableTitle: 'a mai nap érkező vendégek',  departureTableTitle: 'a mai nap távozó vendégek', currentTableTitle: 'a mai nap itt tartózkodó vendégek'})
  arrivalStatus = 0;
  currentsStatus = 1;
}


      
        let arrivingUrl = process.env.REACT_APP_BACKEND_LINK + "/queryarrivingguests.php";
        let arrivingBodyText = `today=${pickedDateInString}&status=${arrivalStatus}`; 

        fetch(arrivingUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: arrivingBodyText
          })
          .then((res) => res.json())
          .then((response) => {

            response.forEach((item) => {
              let apartmentName = '';
              apartments.forEach((apartment) => {
                if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                  apartmentName = apartment.short_name;
                }
              })
              item.apartment_unit_id = apartmentName;
            })
            
            response.forEach((item) => {                                          //commentek hozzáadása
              if(item.commentsArray !== '' && item.commentsArray !== null) {
                let commentsObj = {}
                let commentsArr = item.commentsArray.split('<>');
                let commentsIDsArr = item.commentIDsArray.split('<>');
                let targetsArr = item.targetsArray.split('<>');

                commentsArr.forEach((comment, index) => {
                  commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                })
                item['commentsObj'] = commentsObj;
              } else {
                item['commentsObj'] = {};
              }
                delete item['commentIDsArray'];
                delete item['commentsArray'];
                delete item['targetsArray'];
            })

            setArrivingGuests(response)
        })


        let departingUrl = process.env.REACT_APP_BACKEND_LINK + "/querydepartingguests.php";
        let departingBodyText = `today=${pickedDateInString}&status=${departureStatus}`; 

        fetch(departingUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: departingBodyText
          })
          .then((res) => res.json())
          .then((response) => {

            response.forEach((item) => {
              let apartmentName = '';
              apartments.forEach((apartment) => {
                if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                  apartmentName = apartment.short_name;
                }
              })
              item.apartment_unit_id = apartmentName;
            })

            response.forEach((item) => {                                          //commentek hozzáadása
              if(item.commentsArray !== '' && item.commentsArray !== null) {
                let commentsObj = {}
                let commentsArr = item.commentsArray.split('<>');
                let commentsIDsArr = item.commentIDsArray.split('<>');
                let targetsArr = item.targetsArray.split('<>');

                commentsArr.forEach((comment, index) => {
                  commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                })
                item['commentsObj'] = commentsObj;
              } else {
                item['commentsObj'] = {};
              }
                delete item['commentIDsArray'];
                delete item['commentsArray'];
                delete item['targetsArray'];
            })

            response.forEach((item) => {   
              if(item.status === "0") {
                setIsDisabled(oldArray => [...oldArray, item.res_ID]);
              }
            })
            
            setDepartingGuests(response)
        })


        let currentsUrl = process.env.REACT_APP_BACKEND_LINK + "/querycurrentsguests.php";
        let currentsBodyText = `today=${pickedDateInString}&status=${currentsStatus}`; 
        
        fetch(currentsUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: currentsBodyText
          })
          .then((res) => res.json())
          .then((response) => {

            response.forEach((item) => {
              let apartmentName = '';
              apartments.forEach((apartment) => {
                if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                  apartmentName = apartment.short_name;
                }
              })
              item.apartment_unit_id = apartmentName;
            })

            response.forEach((item) => {                                          //commentek hozzáadása
              if(item.commentsArray !== '' && item.commentsArray !== null) {
                let commentsObj = {}
                let commentsArr = item.commentsArray.split('<>');
                let commentsIDsArr = item.commentIDsArray.split('<>');
                let targetsArr = item.targetsArray.split('<>');

                commentsArr.forEach((comment, index) => {
                  commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                })
                item['commentsObj'] = commentsObj;
              } else {
                item['commentsObj'] = {};
              }
                delete item['commentIDsArray'];
                delete item['commentsArray'];
                delete item['targetsArray'];
            })

            setCurrentGuests(response)
        })
       
      }, [pickedDate, apartments])





      const columnsForArrivalTable = [
        {
          Header: "rescode",
          accessor: "rescode"
        },    
        {
          Header: "personalinfos",
          accessor: "personalinfos",
          disableSortBy: true,
          Cell: ({ row })  => (<GuestPropButton row={row} setGuests={setArrivingGuests} setOpenRegisterModal={setOpenArrivingModal} />   )     
        },
        {
          Header: "name",
          accessor: "resname",
          width: 400
        },
        {
          Header: "arriving time",
          accessor: "arrivaltime"
        },
        {
          Header: "departuredate",
          accessor: "departuredate"
        },
        {
          Header: "departure time",
          accessor: "departuretime"
        },
        {
          Header: "people",
          accessor: "people",
          width: 90,
        },
        {
          Header: "apartment",
          accessor: "apartment_unit_id"
        },
        {
          Header: "host",
          accessor: "host",
          Cell: ({ row })  => { 
            
          //  console.log(row.original.host)
            
            return ( <HostSelector hosts={hosts} 
                                              res_ID={row.original.res_ID} 
                                              rowIndex={row.id}
                                              defaultHost={row.original.host}
                                              defaultTask={row.original.hosttask}
                                /> )}

        },      
        {
          Header: "",
          accessor: "comment",
          disableSortBy: true,
          Cell: ({ row })  => (<AddNewCommentButton commentfieldState={commentfieldState}
                                                    setCommentfieldState={setCommentfieldState}
                                                    row={row}
                                                      />   )     
        },      
        {
          Header: "button",
          accessor: "button",
          disableSortBy: true,
          Cell: ({ row })  => ( <ArrivalButton row={row} 
                                               setGuests={setArrivingGuests} 
                                />   )
        }      
      ];

      const columnsForDepartingTable = [
        {
          Header: "rescode",
          accessor: "rescode"
        },    
        {
          Header: "personalinfos",
          accessor: "personalinfos",
          disableSortBy: true,
          Cell: ({ row })  => (<GuestPropButton row={row} setGuests={setDepartingGuests} setOpenRegisterModal={setOpenDepartingModal} />   )     
        },
        {
          Header: "name",
          accessor: "resname",
          width: 400
        },
        {
          Header: "arriving time",
          accessor: "arrivaltime"
        },
        {
          Header: "departuredate",
          accessor: "departuredate"
        },
        {
          Header: "departure time",
          accessor: "departuretime"
        },
        {
          Header: "people",
          accessor: "people",
          width: 90,
        },
        {
          Header: "apartment",
          accessor: "apartment_unit_id"
        },
        {
          Header: "host",
          accessor: "host",
          Cell: ({ row })  => { 
            
          //  console.log(row.original.host)
            
            return ( <HostSelector hosts={hosts} 
                                              res_ID={row.original.res_ID} 
                                              rowIndex={row.id}
                                              defaultHost={row.original.host}
                                              defaultTask={row.original.hosttask}
                                /> )}

        },      
        {
          Header: "",
          accessor: "comment",
          disableSortBy: true,
          Cell: ({ row })  => (<AddNewCommentButton commentfieldState={commentfieldState}
                                                    setCommentfieldState={setCommentfieldState}
                                                    row={row}
                                                      />   )     
        },      
        {
          Header: "button",
          accessor: "button",
          disableSortBy: true,
          Cell: ({ row })  => ( <DepartureButton row={row} setGuests={setDepartingGuests} isDisabled={isDisabled} setIsDisabled={setIsDisabled} />   )
        }      
      ];
      
        
      const columnsForCurrentsTable = [
        {
          Header: "rescode",
          accessor: "rescode"
        },    
        {
          Header: "personalinfos",
          accessor: "personalinfos",
          disableSortBy: true,
          Cell: ({ row })  => (<GuestPropButton row={row} setGuests={setCurrentGuests} setOpenRegisterModal={setOpenCurrentsModal} />   )     
        },
        {
          Header: "name",
          accessor: "resname",
          width: 400
        },
        {
          Header: "arriving time",
          accessor: "arrivaltime"
        },
        {
          Header: "departuredate",
          accessor: "departuredate"
        },
        {
          Header: "departure time",
          accessor: "departuretime"
        },
        {
          Header: "people",
          accessor: "people",
          width: 90,
        },
        {
          Header: "apartment",
          accessor: "apartment_unit_id"
        },
        {
          Header: "host",
          accessor: "host",
          Cell: ({ row })  => { 
            
          //  console.log(row.original.host)
            
            return ( <HostSelector hosts={hosts} 
                                              res_ID={row.original.res_ID} 
                                              rowIndex={row.id}
                                              defaultHost={row.original.host}
                                              defaultTask={row.original.hosttask}
                                /> )}

        },      
        {
          Header: "",
          accessor: "comment",
          disableSortBy: true,
          Cell: ({ row })  => (<AddNewCommentButton commentfieldState={commentfieldState}
                                                    setCommentfieldState={setCommentfieldState}
                                                    row={row}
                                                      />   )     
        },      
        {
          Header: "button",
          accessor: "button",
          disableSortBy: true,
          Cell: ({ row })  => ( <DepartureButton row={row} setGuests={setCurrentGuests} isDisabled={isDisabled} setIsDisabled={setIsDisabled} />   )
        }      
      ];









return ( 
<div>
{openArrivingModal.show && openArrivingModal.show && (
              <Modal
                openRegisterModal={openArrivingModal}
                setOpenRegisterModal={setOpenArrivingModal}
                setGuests={setArrivingGuests}
                guests={arrivingGuests}   
              />
           )}

    <h1>{tableTitles.arrivalTableTitle}</h1>
    <GuestsTable
            columns={columnsForArrivalTable}
            data={arrivingGuests}   
            hosts = {hosts}        
            commentfieldState={commentfieldState}  
            setCommentfieldState={setCommentfieldState}   
            setGuests={setArrivingGuests}
            setOpenRegisterModal={setOpenArrivingModal}
    />



{openDepartingModal.show && openDepartingModal.show && (
              <Modal
                openRegisterModal={openDepartingModal}
                setOpenRegisterModal={setOpenDepartingModal}
                setGuests={setDepartingGuests}
                guests={departingGuests}   
              />
           )}

<h1>{tableTitles.departureTableTitle}</h1>
<GuestsTable
            columns={columnsForDepartingTable}
            data={departingGuests}   
            hosts = {hosts}  
            commentfieldState={commentfieldState}  
            setCommentfieldState={setCommentfieldState}   
            setGuests={setDepartingGuests}
            setOpenRegisterModal={setOpenDepartingModal}
    />



{openCurrentsModal.show && openCurrentsModal.show && (
              <Modal
                openRegisterModal={openCurrentsModal}
                setOpenRegisterModal={setOpenCurrentsModal}
                setGuests={setCurrentGuests}
                guests={currentGuests}   
              />
           )}

<h1>{tableTitles.currentTableTitle}</h1>
<GuestsTable
            columns={columnsForCurrentsTable}
            data={currentGuests}   
            hosts = {hosts}  
            commentfieldState={commentfieldState}  
            setCommentfieldState={setCommentfieldState}   
            setGuests={setCurrentGuests}
            setOpenRegisterModal={setOpenCurrentsModal}

    />

</div>
)

}

export default AllGuestsTable;