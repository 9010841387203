import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import CleaningModal from "./components/CleaningModal";

const todayInDateFormat = new Date();
const todayObj = new Date(Date.UTC(todayInDateFormat.getUTCFullYear(), todayInDateFormat.getUTCMonth(), todayInDateFormat.getUTCDate()));
//const todayInString = todayObj.toISOString().slice(0,10)
const todayInString = todayObj.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');



export function WeeklyCleaningSchedule() {

//  const [apartmentUnits, setApartmentUnits] = useState([]);
const [week, setWeek] = useState([]);
const [pickedDate, setPickedDate] = useState(new Date());
const [tableData, setTableData] = useState([]);
  const [datePickerVisibility, setDatePickerVisibility] = useState(false);
  const [openModal, setOpenModal] = useState({show:false});

 


useEffect(() => {
const apartmentUnits = JSON.parse(localStorage.getItem('apartmentUnitsCached'));
//console.log(apartmentUnits);


let week = [];

const dayNames = ["Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap" ];
const dayLetters = ["H", "K", "Sze", "Cs", "P", "Szo", "V"];

const date = new Date(pickedDate);
const dayOfWeek = date.getDay();
const daysSinceMonday = (dayOfWeek + 6) % 7;
const mondays2 = new Date(date.getTime() - daysSinceMonday * 24 * 60 * 60 * 1000);
mondays2.setUTCHours(0, 0, 0, 0);



for (let i = 0; i < 7; i++) {
  let currDayName = dayNames[i];
  let nextDay = new Date(mondays2.getFullYear(), mondays2.getMonth(), mondays2.getDate() + i);
  const nextDayInString = nextDay.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
  let dayNum = nextDay.getDate();
  let newDay = {dayName: currDayName, dayDate: nextDayInString, dayNumber: dayNum, dayLetter: dayLetters[i]};
  week[i] = newDay;
}

setWeek(week);

const sundays2 = new Date(mondays2.getTime() + 6 * 24 * 60 * 60 * 1000);
sundays2.setUTCHours(0, 0, 0, 0);

const startDate = mondays2.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
const endDate = sundays2.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');


let url = process.env.REACT_APP_BACKEND_LINK + "/queryweeklycleaningjobs.php";
let bodyText = `startdate=${startDate}&enddate=${endDate}`; 

fetch(url, {
  method: "POST",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
  body: bodyText
  })
  .then((res) => res.json())
  .then((response) => {

//const tableData = createTableData(apartmentUnits, week, response); //---túl bonyolult nekem :D

let tableData = [];


for (let i = 0; i < Object.keys(apartmentUnits).length; i++) {
    let apartmentUnit = apartmentUnits[i];
    let tableRow = {
        header: {apartment_short_name: apartmentUnit.short_name, 
                 apartment_long_name: apartmentUnit.long_name,
                 apartment_unit_id: apartmentUnit.apartment_unit_ID
                },
        cells: []
    };

    week.forEach((day) => {
        let cell = {date: day.dayDate, jobs: []};

        response.forEach((reservation) => {
            if (parseInt(reservation.apartment_unit_id) === parseInt(apartmentUnit.apartment_unit_ID)) {
//                if(reservation.arrivaldate === day.dayDate || reservation.departuredate === day.dayDate) {
                if(reservation.departuredate === day.dayDate) { //--->ez csak TÁVOZÁS, de lesz itt még más meló is!!
                        cell.jobs.push(reservation);
                }
            }
        });

        tableRow.cells.push(cell);
    });

    tableData.push(tableRow);
}

console.log(tableData);


setTableData(tableData);
  })

}, [pickedDate]);


const changer = (pickedDateValue) => {
    setPickedDate(pickedDateValue)
    setDatePickerVisibility(false)
}


const weekChanger = (direction) => {    
    const newDate = new Date(pickedDate);
      if(direction === 0){
      const diff = newDate.getDate() - 7;
      changer(new Date(newDate.setDate(diff)))
      }
      if(direction === 1){
      const diff = newDate.getDate() + 7;
      changer(new Date(newDate.setDate(diff)))
      }
      if(direction === 2){
          changer(new Date())
      }
  }



    const openTheModal = (apartment_unit_id, job, pickedDate) => {
      //  console.log(job);
        setOpenModal(openModal => ({...openModal, show:true, job: job, apartment_unit_id: apartment_unit_id, pickedDate: pickedDate}))      
    }



  function isWeekend(dateString) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }



  return (
    <div>

        {openModal.show && openModal.show && (
            <CleaningModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
        )}


      <h1>Calendar Page</h1>

      {datePickerVisibility && (
        <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={pickedDate} locale="hu-HU" />
                            )}
        <div>
        <button onClick={() => weekChanger(0)} >ELŐZŐ HÉT</button>
                <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
                <button onClick={() => weekChanger(1)} >KÖVETKEZŐ HÉT</button>
                <button onClick={() => weekChanger(2)} >MAI HÉTRE UGRÁS</button>
        </div>                            

        <div>
        <table style={{ border:"2px solid black", width: "100%" }}>
            <thead>
                <tr>
                    <td style={{border:"2px solid black"}}>    </td>
                    <td colSpan={Object.keys(week).length} style={{border:"2px solid black"}}> { pickedDate.toLocaleString("hu-HU", { month: "long" }) } </td>
                </tr>
                <tr>
                    <td style={{border:"2px solid black"}}> Apartmanok </td>
                    {Object.keys(week).map((day, index) => {         
                        
                      let colBackgroundColor = "white";
                        if(week[day].dayLetter === "Szo" || week[day].dayLetter === "V"){
                            colBackgroundColor = "lightgrey";
                        }

                        if(week[day].dayDate === todayInString){
                            colBackgroundColor = "lightpink";
                           }

                        return(
                            <td key={"calheader"+index} style={{border:"2px solid black", 
                            background: colBackgroundColor, minWidth:'20px', maxWidth:'20px'}}>
                                        <div>{week[day].dayLetter}</div>
                                        <div>{week[day].dayNumber}</div>
                            </td>
                        )
                    })}          
                </tr>
            </thead>




        <tbody>

{tableData !== undefined && Object.keys(tableData).map((row, rowIndex) => {

let currRow = tableData[row];

return (
        
            <tr key={"row"+rowIndex} style={{border:"2px solid black"}}>
                            <td key={"apnames"+rowIndex} style={{border:"2px solid black", width:"300px"}}>
                                <div key={"calapartments"+rowIndex}>
                                {currRow.header.apartment_long_name} ({currRow.header.apartment_short_name})
                                </div>
                            </td>



            {currRow.cells.map((cell, cellIndex) => {
                const isWeekenda = isWeekend(cell.date); 
                let colBackgroundColor = "white";
                isWeekenda   ? colBackgroundColor = "lightgrey" : colBackgroundColor = "white";

                if(cell.date === todayInString){
                    colBackgroundColor = "lightpink";
                   }
             

                if(cell.jobs.length > 0) {
                    return (
                        <td key={"calheader"+cellIndex} style={{border:"2px solid black", background: colBackgroundColor}}>

                            {cell.jobs.map((job, index) => {
                                                    return (
                                                        <td key={"calheader"+cellIndex} style={{border:"2px solid black", background: colBackgroundColor}}>
                                                            <div style={{ minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}
                                                                 onClick={() => openTheModal(currRow.header.apartment_unit_id, job, cell.date)}
                                                            >                            
                                                            vvv</div>
                                                        </td>                
                                                    )
                                

                            })}

                        </td>                
                    ) 
                } else {
                    return (
                        <td key={"calheader"+cellIndex} style={{border:"2px solid black", background: colBackgroundColor}}>
                            <div style={{ minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>                            
                            </div>
                        </td>                
                    )
                }

            })}

            </tr>
)})}



        </tbody>
      </table>
    </div>
    </div>
  );
}



/* üres cella. biztmás
                   return (
                        <td key={"calheader"+cellIndex} style={{border:"2px solid black", background: colBackgroundColor}}>
                            <div style={{ minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            </div>
                        </td>                
                )
    
*/
                

/* eddigi legjobb, de nem jó
            return (
                <React.Fragment key={cellIndex}>
                    <td key={"calheader"+cellIndex} style={{border:"2px solid black", background: colBackgroundColor}}>
                        <div style={{ minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center",
                    background: cell.am ? "#8686e6" : "" }}>
                        {cell.am}
                        </div>
                        <div style={{ minHeight: "30px", display: "flex", alignItems: "center", justifyContent: "center", 
                    background: cell.pm ? "#8686e6" : "" }}>
                        {cell.pm}
                        </div>
                    </td>                
                </React.Fragment>
            )
*/   