export function Settings(){
    return (
        <>
    <h1>Settings</h1>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia numquam deserunt possimus exercitationem veritatis sapiente natus omnis nam, consequatur molestiae voluptates inventore maiores, provident quidem, ex ut! Autem, similique aut.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae dicta architecto sed atque aspernatur? Excepturi repellat quia sunt aspernatur iure culpa cum, quae obcaecati, ex et, voluptate animi. Nesciunt, possimus.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus totam ea eum dolorum quisquam cupiditate, sed culpa beatae. A nemo sint consectetur, quidem voluptatibus quos quas cum optio maiores deleniti.</p>
    </>
    );
}