import React, { useState, useEffect  } from "react";

const CurrentGuestsForSupervisors = ({ pickedDate, apartmentUnits}) => {

    const [occupiedApartments, setOccupiedApartments] = useState([]);

    const pickedDateInString = pickedDate.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

    useEffect(() => {
        let guestStatus = 10;
        let url = process.env.REACT_APP_BACKEND_LINK + "/querycurrentsguests.php";
        let bodyText = `today=${pickedDateInString}&status=${guestStatus}`; 

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
          .then((res) => res.json())
          .then((response) => {

            response.forEach((item) => {
                let apartmentName = '';
                apartmentUnits.forEach((apartment) => {
                  if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                    apartmentName = apartment.short_name;
                  }
                })
                item.apartment_short_name = apartmentName;
              })
  
              response.forEach((item) => {                                          //commentek hozzáadása
                if(item.commentsArray !== '' && item.commentsArray !== null) {
                  let commentsObj = {}
                  let commentsArr = item.commentsArray.split('<>');
                  let commentsIDsArr = item.commentIDsArray.split('<>');
                  let targetsArr = item.targetsArray.split('<>');
  
                  commentsArr.forEach((comment, index) => {
                    commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                  })
                  item['commentsObj'] = commentsObj;
                } else {
                  item['commentsObj'] = {};
                }
                  delete item['commentIDsArray'];
                  delete item['commentsArray'];
                  delete item['targetsArray'];
              })
  
              setOccupiedApartments(response)
  
          })
        }, [pickedDate, apartmentUnits])


    return (   
        <div>
            <h1>CurrentGuestsForSupervisors</h1>


            {Object.keys(occupiedApartments).length > 0 && occupiedApartments.map((apartment, index) => {
                return (
                    <div
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        border: "3px solid brown",
                        borderRadius: "10px",
                        width: "100%",
                        marginBottom: "10px",
//                        backgroundColor: apartment.status !== '1' ? 'white' : "#9df29d"
                    }}
                    >
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px"
                        }}
                    >
                        <div style={{ fontWeight: "bold" }}>
                        {apartment.apartment_short_name}
                        </div>
                        <div>
                        {apartment.resname}
                        </div>
                        <div>
                        {apartment.people} fő
                        </div>
                        <div>
                            <label>távozás: </label>
                        {apartment.departuredate}
                        </div>
                        
                        </div>



                        {apartment.commentsObj !== undefined &&
                            Object.keys(apartment.commentsObj).map((comment, index) => {
                            if (apartment.commentsObj[comment].target.includes("1")) {
                                return (
                                <div
                                    key={index}
                                    style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    border: "1px solid black",
                                    backgroundColor: "lightblue",
                                    width: "100%"
                                    }}
                                >
                                    <div
                                    style={{
                                        padding: "5px",
                                        fontWeight: "bold",
                                        width: "100%"
                                    }}
                                    >
                                    {apartment.commentsObj[comment].comment}
                                    </div>
                                </div>
                                );
                            }
                            return null;
                            })
                        }

                        </div>
                )
            })}

        </div>
    )
}
export default CurrentGuestsForSupervisors