import React, { useMemo, useCallback } from 'react';

const ArrivalButton = ({ row, setGuests }) => {

    const handleClick = useCallback(() => {

        let url = process.env.REACT_APP_BACKEND_LINK + "/setstatus.php";
        let formData = {
          'res_ID' : parseInt(row.original.res_ID),
          'status' : 1
        };
  
        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-type' : 'application/json; charset=UTF-8',
          },
          body: JSON.stringify(formData)
        })
        .then((res) => res.json() )
        .then(response => {  
          console.log(response)

          setGuests(guests => {
            const newGuests = [...guests];
            newGuests.forEach((guest) => {
              if (guest.res_ID === row.original.res_ID) {
                guest['status'] = 1;
              }
            })
            return newGuests;
          })

          setGuests(guests => {
            const newGuests = guests.filter(guest => guest.status !== 1);
            return newGuests;
          });
        

          
         })
        .catch(error => console.log(error));


    }, [row.original, setGuests]);


    return (
        <button onClick={handleClick}>Bejelentkeztetés</button>
    )
}

export default ArrivalButton;
