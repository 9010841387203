import React, { useState, useEffect  } from "react";
import Calendar from 'react-calendar';
import ArrivingGuestsTableForHosts from "./components/ArrivingGuestsTableForHosts";


export function StartPageForHosts(){

  const [apartmentUnits, setApartmentUnits] = useState([]);
  const [datePickerVisibility, setDatePickerVisibility] = useState(false);
  const [queryDate, setQueryDate] = useState({});
  const [pickedDate, setPickedDate] = useState(new Date());

  let userId = localStorage.getItem('id');


  useEffect(() => {
    
    const fetchData = async () => {
      try {
            const fetch_data = [
              { name: 'apartment_units', params: {} },
            ];
      
            fetch(`${process.env.REACT_APP_BACKEND_LINK}/fundamental_queries.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8', 
              },
              body: JSON.stringify({ fetch_data: fetch_data })
            })
            .then((res) => res.json())
            .then((apartmentsData) => {
                setApartmentUnits(apartmentsData);
                localStorage.setItem('apartmentUnitsCached', JSON.stringify(apartmentsData));
            })
            .catch((error) => {
                console.error('Hiba történt:', error);
            });
      } catch (error) {
        console.log(error.message);
      }
    }

      let cachedApartmentsData = JSON.parse(localStorage.getItem('apartmentUnitsCached'));
          if (!cachedApartmentsData) {
            fetchData();
          } else {
            setApartmentUnits(cachedApartmentsData);
          }

    }, []);



useEffect(() => {
  const arrivalsOnThisDayInDate = new Date(pickedDate);

  const options = { weekday: 'long' };
  const dayName = arrivalsOnThisDayInDate.toLocaleDateString('hu-HU', options);

  arrivalsOnThisDayInDate.setMinutes(arrivalsOnThisDayInDate.getMinutes() - arrivalsOnThisDayInDate.getTimezoneOffset()); 
  const arrivalsOnThisDayString = arrivalsOnThisDayInDate.toISOString().slice(0,10);
  setQueryDate({date: arrivalsOnThisDayString, dayName: dayName});
}, [pickedDate])

          const changer = (pickedDateValue) => {
            setPickedDate(pickedDateValue)
            setDatePickerVisibility(false)
        }
    
    
        const dayChanger = (direction = 0) => {  
          const newDate = new Date(pickedDate);
          const diff = direction === 0 ? newDate.setDate(newDate.getDate() - 1) : newDate.setDate(newDate.getDate() + 1)
          changer(new Date(diff))
        }



      return (
        <div>

        <div>
          <>
        {datePickerVisibility && (
                <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={pickedDate} locale="hu-HU" />
                                    )}
                    <button onClick={() => dayChanger()} >ELŐZŐ nap</button>
                    <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
                    <button onClick={() => dayChanger(1)} >KÖVETKEZŐ nap</button>
                    <button onClick={() => changer(new Date())} >MAI NAPRA UGRÁS</button>
                    
                    {queryDate.date}
                     - 
                    {queryDate.dayName}
        </>            
        </div>

            <ArrivingGuestsTableForHosts pickedDate={pickedDate} 
                                        currhost={userId} 
                                        apartments={apartmentUnits} 
            />
        </div>
      );   
}