
const RenderPersonalData = ({personalDetails, setPersonalDetails, personalDataFields, setDeletedGuests, setReservationDetails}) => {

if(personalDetails.length !== 0){

    const personalData = [];

    const handlePersonalDataChange = (index, field, value) => { 
        setPersonalDetails((prevState) => {
            const newData = [...prevState];
            newData[index] = {...newData[index]}
            newData[index][field] = value;
            return newData;
        }); 
       // console.log(personalDetails)
      };

  const personNumber = Object.keys(personalDetails).length;
  //console.log(personalDetails);


const deletePerson = (index) => {

  const deletedPerson = [];

  setPersonalDetails((prevState) => {
    const newData = [...prevState];
    deletedPerson.push(personalDetails[index]);
  newData.splice(index, 1);
    return newData;
  });

  setDeletedGuests((prevState) => {
    const newData = [...prevState];
    newData.push(deletedPerson[0]);
    return newData;
  });
/*
  setReservationDetails((prevState) => {
    const newData = [...prevState];
  // newData.guests = newData.guests - 1;
  //newData.people = personalDetails.length - 1;
  //newData.people = newData.people - 1;  
    return newData;
  });
*/
setReservationDetails((prevState) => {
  const newData = {...prevState};
  newData.people = newData.people - 1;  
  return newData;
});

};



  for (let i = 0; i < personNumber; i++) {
    personalData.push(
      <>
        <h2>guest {i + 1}</h2> 


<button onClick={()=>{deletePerson(i)}}>delete</button>


        {personalDataFields.map((field, index) => (
          <div key={field.field}>
            <label htmlFor={`${field.field}-${i}`}>{field.label}</label>
  
            {field.type === "select" ? (
              <select
                id={`${field.field}-${i}`}
                name={`${field.field}-${i}`}
                onChange={(e) =>
                  handlePersonalDataChange(i, field.field, e.target.value)
                }
                defaultValue={personalDetails[i][field.field] || ""}
              >
                {field.options.map((option, index) => (
                  <option key={option} value={index}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (

              <>
              <input
                type={field.type}
                id={`${field.field}${i}`}
                name={`${field.field}${i}`}
                onChange={(e) =>
                  handlePersonalDataChange(i, field.field, e.target.value)
                }
                value={personalDetails[i][field.field]}
                list={`options-${field.field}`}
              />

              {field.suggestions.length > 0 ? (
                <datalist id={`options-${field.field}`}>
                  {field.suggestions.map((suggestion) => (
                    <option key={suggestion} value={suggestion} />
                  ))}
                </datalist>
              ) : null}
            </>

            )}
          </div>
        ))}
      </>
    );
  } 

          return personalData;
}
  };

  export default RenderPersonalData;