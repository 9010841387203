import React, { useState, useEffect  } from "react";
import { BsTrashFill, BsPencilSquare } from "react-icons/bs";
import DropdownMenuWithCheckboxes from "./DropdownMenuWithCheckboxes";

const CommentRow = ({commentID, target, comment, rowIndex, data, setGuests}) => {

    const roles = ["vendégkommunikátor", "takarító"];

    const [editMode, setEditMode] = useState(false);
    const [commentText, setCommentText] = useState(comment);
    const [localSelectedRoles, setLocalSelectedRoles] = useState([]);
  
    useEffect(() => {
      if(target.includes(',')) {
        let targetsArr = target.split(',');
          targetsArr.map((item) => 
            setLocalSelectedRoles(prev => [...prev, parseInt(item)])      
          );
      } else {
        setLocalSelectedRoles([parseInt(target)]);
      }
    }, [target]);
  
    const handleEditComment = () => {
      setEditMode(true);
    };
    const handleSaveComment = () => {
      setEditMode(false);
      saveEditedComment2(commentID, rowIndex, commentText, localSelectedRoles.toString());
    };
    
    const saveEditedComment2 = (comment_ID, rowIndex, comment, target) => {
        let url = process.env.REACT_APP_BACKEND_LINK + "/updatecomment.php";
        let bodyText = `comment_ID=${comment_ID}&comment=${comment}&target=${target}`;
      
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
        .then((res) => res.json() )
        .then(response => {
          const updatedGuests = [...data];
          updatedGuests[rowIndex].commentsObj[comment_ID].comment = comment;
          updatedGuests[rowIndex].commentsObj[comment_ID].target = target;
          setGuests(updatedGuests); 
      
      
        })
        .catch(error => console.log(error));
      };
    
      const handleDeleteComment = (comment_ID, rowIndex) => {
        let url = process.env.REACT_APP_BACKEND_LINK + "/deletecomment.php";
        let bodyText = `comment_ID=${comment_ID}`;
      
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
        .then((res) => res.json() )
        .then(response => {
          const updatedGuests = [...data];
            delete updatedGuests[rowIndex].commentsObj[comment_ID];
          setGuests(updatedGuests); 
        })
        .catch(error => console.log(error));
      };
  
    return (
      <div>
        {editMode ? (
          <div>
            <textarea value={commentText} 
                      onChange={(e) => { setCommentText(e.target.value)}}
                      autoFocus>
            </textarea>
  
            <DropdownMenuWithCheckboxes 
                roles={roles} 
                setSelectedRoles={setLocalSelectedRoles} 
                selectedRoles={localSelectedRoles}
                disabled={false}
            />
                <button onClick={handleSaveComment}
                        disabled={localSelectedRoles.length === 0 || !commentText}
                >
                  Save
                </button>
                <button onClick={() => {setEditMode(false); setCommentText(comment)}}>
                  Cancel
                </button>              
  
          </div>
        )
        :
        (
            <div>
              <p id={"paragraph" + commentID}>* {target} {comment}</p>
              <span className="note_icon">
                <BsPencilSquare onClick={handleEditComment} />
              </span>
              <span className="note_icon">
                <BsTrashFill onClick={()=>{handleDeleteComment(commentID, rowIndex)}} />
              </span>
  
              <DropdownMenuWithCheckboxes 
                                      roles={roles} 
                                      setSelectedRoles={setLocalSelectedRoles} 
                                      selectedRoles={localSelectedRoles}
                                      disabled={true}
                                      />
            </div>
            )
            }
      </div>
    );
  };
  
  export default CommentRow;