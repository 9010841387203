import React, { useEffect, useState, useRef } from 'react';
import "./Modal.css"
import RenderPersonalData from './RenderPersonalData';
import {personalDataFields} from "./PersonalDataFields"


const Modal = (props) => {

  const [reservationDetails, setReservationDetails] = useState([]);
  const [personalDetails, setPersonalDetails] = useState([]);
  const [deletedGuests, setDeletedGuests] = useState([]);

  let cachedApartmentsData = JSON.parse(localStorage.getItem('apartmentUnitsCached'));

let apartmentUnitId = useRef(null);
let limit = useRef(1);


useEffect(() => {
  props.guests.forEach((guest) => {
    if (guest.res_ID === props.openRegisterModal.res_ID) {
      setReservationDetails(guest);
      setPersonalDetails(guest.guests);
      apartmentUnitId.current = guest.apartment_unit_id;


      cachedApartmentsData.forEach((apartment) => {
        if (apartment.short_name === apartmentUnitId.current) {
          limit.current = parseInt(apartment.people);
        }
      })

    }
  })
}, [props.openRegisterModal.res_ID, props.guests])




useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      props.setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:false}))
    }
  };

  document.addEventListener('keydown', handleKeyDown);

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, [props]);




      const MODAL_STYLES = {
        position: "absolute",
        backgroundColor: "#FFF",
        padding: "15px",
        zIndex: "1000",
        width: "75%",
        borderRadius: ".5em",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
       /* boxShadow: "0 5px 10px rgba(0,0,0, .3)",*/
        maxHeight: "90vh",
        overflowY: "auto"        
      };
      const OVERLAY_STYLE = {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0, .8)",
        zIndex: "1000",
        overflowY: "auto"
      };

//console.log(reservationDetails)

const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setReservationDetails(values => ({...values, [name]: value}))

//wip!!!
/*
if (name === 'people') {

  const personalDetailsCopy = [...personalDetails]; // create a copy of the existing personalDetails
  const newArr = []; // create a new array for the combined values
  
  for (let i = 0; i < value; i++) {
    if (i < personalDetailsCopy.length) {
      // if there is an existing value, add it to the new array
      newArr.push(personalDetailsCopy[i]);
    } else {
      // if there is no existing value, add a new, blank object to the new array
      newArr.push({
        guest_ID: '',
        firstname: '',
        lastname: '',
        birthdate: '',
        placeofbirth: '',
        nationality: '',
        zipcode: '',
        country: '',
        city: '',
        address: '',
        documenttype: '',
        documentnumber: ''
      });
    }
  }
  
  setPersonalDetails(newArr);
}
*/

}





const closeModal = () => {

  const personalDataProperties0 = ['guest_ID', 'firstname', 'lastname', 'birthdate', 'placeofbirth', 'nationality', 'zipcode', 'country', 'city', 'address', 'documenttype', 'documentnumber'];

  let isPersonalDataValid0 = '1';
  
  personalDetails.map((data) => {

    personalDataProperties0.forEach((property) => {
      if (!data[property]) {
        isPersonalDataValid0 = '0';
      }
    });
    
  });

  

  props.setGuests(guests => {
    const newGuests = [...guests];
    newGuests.forEach((guest) => {
      if (guest.res_ID === props.openRegisterModal.res_ID) {
        guest['rescode'] = reservationDetails.rescode;
        guest['resname'] = reservationDetails.resname;
        guest['arrivaldate'] = reservationDetails.arrivaldate;
        guest['departuredate'] = reservationDetails.departuredate;
        guest['arrivaltime'] = reservationDetails.arrivaltime;
        guest['departuretime'] = reservationDetails.departuretime;
        guest['apartment_unit_id'] = reservationDetails.apartment_unit_id;
        guest['people'] = reservationDetails.people;
        guest['host'] = reservationDetails.host;
        guest['comment'] = reservationDetails.comment;
        guest['guests'] = personalDetails;

        guest['personal_infos_completeness'] = isPersonalDataValid0;
      }
    })
    return newGuests;
  })



//------


let formData = {}
let personalDataArray = [];

props.guests.forEach((item) => {
  if (item.res_ID === props.openRegisterModal.res_ID) {
    formData = {
      'res_ID' : item.res_ID,
      'rescode' : item.rescode,
      'resname' : item.resname,
      'arrivaldate' : item.arrivaldate,
      'arrivaltime' : item.arrivaltime,
      'departuredate' : item.departuredate,
      'departuretime' : item.departuretime,
      'people' : item.people,
      'apartment_unit_id' : item.apartment_unit_id
    }  

    item.guests.forEach((guest) => {
      personalDataArray.push({
        'guest_ID' : guest.guest_ID,
        'firstname' : guest.firstname,
        'lastname' : guest.lastname,
        'birthdate' : guest.birthdate,
        'placeofbirth' : guest.placeofbirth,
        'nationality' : guest.nationality,
        'zipcode' : guest.zipcode,
        'country' : guest.country,
        'city' : guest.city,
        'address' : guest.address,
        'documenttype' : guest.documenttype,
        'documentnumber' : guest.documentnumber
      })
    })
    
  }
})


/*
console.log(formData)
  console.log(personalDataArray)
  console.log(JSON.stringify({formData, personalDataArray}))
*/

const personalDataProperties = ['guest_ID', 'firstname', 'lastname', 'birthdate', 'placeofbirth', 'nationality', 'zipcode', 'country', 'city', 'address', 'documenttype', 'documentnumber'];

let isPersonalDataValid = 1;

personalDataArray.forEach((data) => {
  personalDataProperties.forEach((property) => {
    if (!data[property]) {
      isPersonalDataValid = 0;
    }
  });
});


  let url = process.env.REACT_APP_BACKEND_LINK + "/updatereservation.php";

  fetch(url, {
    method: 'POST',
    body: JSON.stringify({formData, personalDataArray, deletedGuests, personalInfosCompleteness: isPersonalDataValid}),
    headers: {
      'Content-type' : 'application/json; charset=UTF-8',
    }
 })
  .then((res) => res.json() )
  .then(response => {  
  //  console.log(response)
    })
  .catch(error => console.log(error))

props.setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:false}))
//props.setKey(key => key + 1)

}


const addANewGuest = () => {

  if(personalDetails.length < limit.current) {
  setPersonalDetails(personalDetails => [...personalDetails, {  
    guest_ID: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    placeofbirth: '',
    nationality: '',
    zipcode: '',
    country: '',
    city: '',
    address: '',
    documenttype: '',
    documentnumber: ''
  }])

  setReservationDetails((prevState) => {
    const newData = {...prevState};
   // newData.people = newData.people + 1;
    newData.people = personalDetails.length + 1;
    return newData;
  });

}
}






return (
  <>
    <div style={OVERLAY_STYLE}>
      <div style={MODAL_STYLES}>



      <div className='modal-container'>
<div>
  <h2 className='section-header'>alapadatok</h2>
  <label>rescode</label>
  <input type="text" name="rescode" value={reservationDetails.rescode} onChange={handleChange}></input>
  <label>resname</label>
  <input type="text" name="resname" value={reservationDetails.resname} onChange={handleChange}></input>
  <label>arrival date</label>
  <input type="text" name="arrivaldate" value={reservationDetails.arrivaldate} onChange={handleChange}></input>
  <label>departure date</label>
  <input type="text" name="departuredate" value={reservationDetails.departuredate} onChange={handleChange}></input>
  <label>arrival time</label>
  <input type="text" name="arrivaltime" value={reservationDetails.arrivaltime} onChange={handleChange}></input>
  <label>departure time</label>
  <input type="text" name="departuretime" value={reservationDetails.departuretime} onChange={handleChange}></input>
  <label>apartment</label>
  <input type="text" name="apartment_unit_id" value={reservationDetails.apartment_unit_id} onChange={handleChange}></input>

    <label>people</label>
    <input type="text" 
          name="people" 
          value={reservationDetails.people} 
          readonly="readonly"
          style={{  pointerEvents: "none",  }}
    />
  
  <label>host</label>
  <input type="text" name="host" value={reservationDetails.host} onChange={handleChange}></input>
</div>

<div>
  <h2 className='section-header'>personalinfos</h2>
    <RenderPersonalData personalDetails={personalDetails} 
                        setPersonalDetails={setPersonalDetails}
                        personalDataFields={personalDataFields}
                        setDeletedGuests={setDeletedGuests}
                        setReservationDetails={setReservationDetails}
    />

    <button onClick={addANewGuest}
            disabled={personalDetails.length < limit.current ? false : true} 
    > add new guest
    </button>

</div>

<div>

</div>
</div>



        <div>
          <div>
          <button onClick={closeModal}>Save</button>
          <button onClick={() => props.setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:false}))}>Cancel</button>
          </div>
        </div>





      </div>
    </div>
  </>
);
}
export default Modal;

/*
    <label>people</label>
    <input type="number" 
          name="people" 
          value={reservationDetails.people} 
          onChange={handleChange} 
          min="1"
          max={limit.current}
          onKeyDown={(e) => e.preventDefault()}
    />
*/