import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import moment from 'moment'
import Modal from "./components/Modal";

import Timeline from "react-timelines-updated";
import { START_YEAR, NUM_OF_YEARS, NUM_OF_TRACKS } from "./constants";
import { buildTimebar, buildTrack } from "./builders";
import { fill } from "./utils";
import "react-timelines-updated/lib/css/style.css";
import CalendarPageC from "./CalendarPageC";

const apartmentUnits = JSON.parse(localStorage.getItem('apartmentUnitsCached'));

const todayInDateFormat = new Date();
const todayObj = new Date(Date.UTC(todayInDateFormat.getUTCFullYear(), todayInDateFormat.getUTCMonth(), todayInDateFormat.getUTCDate()));
const todayInString = todayObj.toISOString().slice(0,10)

const timebar = buildTimebar();
const MIN_ZOOM = 2;
const MAX_ZOOM = 20;

const clickElement = (element) =>
alert(`Clicked element\n${JSON.stringify(element, null, 2)}`);

const now = new Date();


export function CalendarPage() {
   

  const [openRegisterModal, setOpenRegisterModal] = useState({show:false});
  const [guests, setGuests] = useState([]);

   
    const [days, setDays] = useState([]);
    const [pickedDate, setPickedDate] = useState(new Date());
    const [tableData, setTableData] = useState([]);
      const [datePickerVisibility, setDatePickerVisibility] = useState(false);

      const [reservations, setReservations] = useState([]);
      const [isLoading, setIsLoading] = useState(true);


      const [apartmentGroup, setApartmentGroup] = useState([]);

      useEffect(() => {
        const apartmentUnits = JSON.parse(localStorage.getItem('apartmentUnitsCached'));

        let apGroup = [];
        apartmentUnits.forEach((apartmentUnit) => {
          apGroup.push({ 
            id: apartmentUnit.apartment_unit_ID, 
            title: apartmentUnit.short_name,
            tip: apartmentUnit.long_name,
          height: 60 
          });
        });
        setApartmentGroup(apGroup)



        //console.log(apartmentUnits);
        const currentDate = new Date();
        const currentMonth = currentDate.getUTCMonth();
        const currentYear = currentDate.getUTCFullYear();
        const daysInMonth = new Date(Date.UTC(currentYear, currentMonth + 1, 0)).getUTCDate();
        const daysArray = [];
        
        const dayShortNames = ["V", "H", "K", "Sze", "Cs", "P", "Szo"];
        
        for (let i = 1; i <= daysInMonth; i++) {
            const currDate = new Date(Date.UTC(currentYear, currentMonth, i));
            let dayDate = currDate.toISOString().substring(0,10);
           daysArray.push({ dayNumber: i, dayLetter: dayShortNames[currDate.getUTCDay()], dayDate: dayDate });
        }
        setDays(daysArray);
        
        const firstDayOfMonth = new Date(Date.UTC(currentYear, currentMonth, 1));
        let firstDayOfMonthInString = firstDayOfMonth.toISOString().substring(0,10);
        
        const lastDayOfMonth = new Date(Date.UTC(currentYear, currentMonth + 1, 0));
        let lastDayOfMonthInString = lastDayOfMonth.toISOString().substring(0,10);
        
        //console.log(firstDayOfMonthInString + " - " + lastDayOfMonthInString);
        
        let url = process.env.REACT_APP_BACKEND_LINK + "/queryallarrivingguests.php";
        let bodyText = `startdate=${firstDayOfMonthInString}&enddate=${lastDayOfMonthInString}`; 
        
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
          .then((res) => res.json())
          .then((response) => {

          
let responseArr = [];
response.map((item) => {  
responseArr.push({
  res_ID: item.res_ID,
  resname: item.resname,
  arrivaldate: item.arrivaldate,
  departuredate: item.departuredate,
  apartment_unit_id: item.apartment_unit_id,
  commentsArray: item.commentsArray || '',
  commentIDsArray: item.commentIDsArray || '',
  targetsArray: item.targetsArray,
  guests: [],
})
})



responseArr.forEach((item) => {    //apartment_unit_id átírása short_name-re
              let apartmentName = '';
              apartmentUnits.forEach((apartment) => {
                if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                  apartmentName = apartment.short_name;
                }
              })
              item.apartment_unit_id = apartmentName;
            })
          //  console.log(responseArr);
            
            responseArr.forEach((item) => {                                          //commentek hozzáadása
              if(item.commentsArray !== '' && item.commentsArray !== null) {
                let commentsObj = {}
                let commentsArr = item.commentsArray.split('<>');
                let commentsIDsArr = item.commentIDsArray.split('<>');
                let targetsArr = item.targetsArray.split('<>');

                commentsArr.forEach((comment, index) => {
                  commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                })
                item['commentsObj'] = commentsObj;
              } else {
                item['commentsObj'] = {};
              }
                delete item['commentIDsArray'];
                delete item['commentsArray'];
                delete item['targetsArray'];
            })

          //  console.log(responseArr);
        
            setGuests(responseArr)








            let items = [];
              response.forEach((reservation, resIndex) => {
                apartmentUnits.forEach((apartmentUnit, index) => {
                  if (reservation.apartment_unit_id === apartmentUnit.apartment_unit_ID) {
                    items.push({
                      id:  resIndex,
                      res_ID: reservation.res_ID,
                      group: apartmentUnit.apartment_unit_ID,
                      title: reservation.resname,
//                      start_time: moment(reservation.arrivaldate, "YYYY-MM-DD"),
//                      end_time: moment(reservation.departuredate, "YYYY-MM-DD"),
                      start_time: moment(reservation.arrivaldate, "YYYY-MM-DD").add(14, 'hour'),
                      end_time: moment(reservation.departuredate, "YYYY-MM-DD").add(12, 'hour'),
                      canMove: false,
                    });
                  }
                });
              });


/*
              const newItems = [...items].sort((a, b) => {
                if (a.group !== b.group) {
                  return a.group - b.group;
                }
                if (a.start_time.isBefore(b.start_time)) {
                  return -1;
                }
                if (a.start_time.isAfter(b.start_time)) {
                  return 1;
                }
                if (a.end_time.isBefore(b.end_time)) {
                  return -1;
                }
                if (a.end_time.isAfter(b.end_time)) {
                  return 1;
                }
                return 0;
              }).map((item, index) => ({
                ...item,
                id: index,
              }));

            setReservations(newItems);
*/            
setReservations(items);
            setIsLoading(false);
          })
        
        }, []);
        






        const changer = (pickedDateValue) => {
            setPickedDate(pickedDateValue)
            setDatePickerVisibility(false)
        }
        
        
        const dayChanger = (direction = 0) => {  
          const newDate = new Date(pickedDate);
          const diff = direction === 0 ? newDate.setDate(newDate.getDate() - 1) : newDate.setDate(newDate.getDate() + 1)
          changer(new Date(diff))
        }
        
        
        
        function createTableData(apartmentUnits, daysArray, reservations) {
            const tableData = apartmentUnits.map((apartmentUnit) => {
              const apartmentUnitReservations = daysArray.map((day) => {
                return {
                  date: day.dayDate,
                  am: "",
                  pm: "",
                };
              });
              reservations.forEach((reservation) => {
                if (reservation.apartment_unit_id === apartmentUnit.apartment_unit_ID) {
                  const arrivalDayIndex = daysArray.findIndex((day) => day.dayDate === reservation.arrivaldate);
                  const departureDayIndex = daysArray.findIndex((day) => day.dayDate === reservation.departuredate);
                  if (arrivalDayIndex !== -1 && departureDayIndex !== -1) {
                    for (let i = arrivalDayIndex; i <= departureDayIndex; i++) {
                      if (i === arrivalDayIndex) {
                        apartmentUnitReservations[i].pm = reservation.res_ID;
                      } else if (i === departureDayIndex) {
                        apartmentUnitReservations[i].am = reservation.res_ID;
                      }
                      /*
                      else {
                        apartmentUnitReservations[i].am = reservation.res_ID;
                        apartmentUnitReservations[i].pm = reservation.res_ID;
                      }
                      */
                    }
                  }
                }
              });
              return {
                apartment_short_name: apartmentUnit.short_name,
                apartment_long_name: apartmentUnit.long_name,
                apartment_unit_reservations: apartmentUnitReservations,
              };
            });
           // console.log(tableData);
            return tableData;
          }
        
          function isWeekend(dateString) {
            const date = new Date(dateString);
            const dayOfWeek = date.getDay();
            return dayOfWeek === 0 || dayOfWeek === 6;
          }
    

          const handleClick = (res_ID) =>{
//console.log(res_ID)
//console.log(guests)
            let url = process.env.REACT_APP_BACKEND_LINK + "/queryreservation.php";
            let bodyText = `res_ID=${res_ID}`; 
        
            fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
              },
              body: bodyText
            })
            .then((res) => res.json())
            .then((response) => {
              setGuests(guests => {
                const newGuests = [...guests];
                newGuests.forEach((guest) => {
                  if (guest.res_ID === res_ID) {
                    guest['guests'] = response;
                  }
                })
                return newGuests;
              })
        
              setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:true, res_ID: res_ID}))
        
            })
          }

//console.log(reservations)

    return (
<>
<div>
{openRegisterModal.show && openRegisterModal.show && (
              <Modal
                openRegisterModal={openRegisterModal}
                setOpenRegisterModal={setOpenRegisterModal}
                setGuests={setGuests}
                guests={guests}   
              />
           )}

    <h1>Calendar Page</h1>
    {isLoading ? (
        <p>Loading...</p>
      ) : (
        <CalendarPageC apartmentGroup={apartmentGroup}
                       reservations={reservations}
                       setOpenRegisterModal={setOpenRegisterModal}
                       handleClick={handleClick}
         />
      )}
</div>
</>
    );

}