import React, { useState, useEffect  } from "react";
import GuestsTable from "./components/GuestsTable";
import 'react-dropdown/style.css';
//import AddANewGuestForm from './components/AddANewGuestForm'
import Calendar from 'react-calendar';

    export const StartPage = () => {

      const [pickedDate, setPickedDate] = useState(new Date());
      const [guests, setGuests] = useState([]);
      const [hosts, setHosts] = useState([{id:0, name:"Select a host"}]);
      const [datePickerVisibility, setDatePickerVisibility] = useState(false);
      const [queryDate, setQueryDate] = useState();

      useEffect(() => {
        let url2 = process.env.REACT_APP_BACKEND_LINK + "/queryHosts.php";

        fetch(url2)
          .then((res) => res.json())
          .then((response) => {                         
            response.map((item, index) => (
              setHosts(arr => [...arr, {id: item.id, name: item.name}]),
              setHosts(arr => [...arr, {id: item.id, name: "ügyelet " + item.name}])
            ));           
        })
      }, [])


      useEffect(() => {
        const arrivalsOnThisDayInDate = new Date(pickedDate);
        arrivalsOnThisDayInDate.setMinutes(arrivalsOnThisDayInDate.getMinutes() - arrivalsOnThisDayInDate.getTimezoneOffset()); 
        const arrivalsOnThisDayString = arrivalsOnThisDayInDate.toISOString().slice(0,10)
        setQueryDate(arrivalsOnThisDayString)

        let url = process.env.REACT_APP_BACKEND_LINK + "/queryguests.php";
        let bodyText = `arrivaldate=${arrivalsOnThisDayString}`; 

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
          .then((res) => res.json())
          .then((response) => {
            setGuests(response)
        })
      }, [pickedDate])
      

    
      const columns = [
        {
          Header: "rescode",
          accessor: "rescode" 
        },
    
        {
          Header: "name",
          accessor: "name",
          width: 400
        },
        {
          Header: "arrivaldate",
          accessor: "arrivaldate"
        },
        {
          Header: "departuredate",
          accessor: "departuredate"
        },
        {
          Header: "people",
          accessor: "people"
        },
        {
          Header: "apartman",
          accessor: "apartman"
        },
        {
          Header: "host",
          accessor: "host",
        }      
      ];









    const changer = (pickedDateValue) => {
        setPickedDate(pickedDateValue)
        setDatePickerVisibility(false)
    }
    
    const dayChanger = (direction = 0) => {  
      const newDate = new Date(pickedDate);
      const diff = direction === 0 ? newDate.setDate(newDate.getDate() - 1) : newDate.setDate(newDate.getDate() + 1)
      changer(new Date(diff))
    }
    




      return (
        <>
        <h1>StartPage</h1>

<table>
  <tbody>
  <tr>
  <td>
          {/*<AddANewGuestForm guestUpdater={setGuests} /> */}
  </td>
  </tr>
  <tr>
    <td>
  {datePickerVisibility && (
        <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={pickedDate} locale="hu-HU" />
                            )}
            <button onClick={() => dayChanger()} >ELŐZŐ nap</button>
            <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
            <button onClick={() => dayChanger(1)} >KÖVETKEZŐ nap</button>
            </td>
            <td>{queryDate}</td>
  </tr>
  <tr>
    <td>
                <GuestsTable
                    columns={columns}
                    data={guests}   
                    hosts = {hosts}        
                  />
    </td>                  
  </tr>    
  </tbody>           
</table>                  
        </>
      );     
}

