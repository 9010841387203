import React, { useState, useEffect  } from "react";

export function ApartmentCodePage() {

    const [apartmentUnits, setApartmentUnits] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_LINK}/queryApartmentCodes.php`)
            .then((res) => res.json())
            .then((data) => {
                setApartmentUnits(data);
            })
            .catch((err) => console.log(err.message));
    }, []);


    const handleDataChange = (apartment_unit_ID, newCode) => {
        setApartmentUnits((prevState) => {
            const newData = [...prevState];
            newData.map((item) => {
                if (item.apartment_unit_ID === apartment_unit_ID) {
                    item.key_safe_code = newCode;
                }
            });
            return newData;
        });       
    };

    const handleDataChangeBlur = (code_id, newcode) => {        
        let url = process.env.REACT_APP_BACKEND_LINK + "/updateApartmentCodes.php";
        let bodyText = `code_id=${code_id}&newcode=${newcode}`;
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: bodyText,
                })
                .then((res) => res.json())
                .then((response) => {
       //             console.log(response);
                });
                
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation(); // Add this line to stop event bubbling
          event.target.blur();
        }
    }
    
    const generateRandomCode = (apartment_unit_ID, code_id, key_safe_code) => {
        let numLenght = key_safe_code.length;
            if (typeof numLenght !== 'undefined') {
                let randomCode = Math.floor(Math.random() * (Math.pow(10, numLenght) - Math.pow(10, numLenght - 1)) + Math.pow(10, numLenght - 1));
                console.log(randomCode)
                handleDataChange(apartment_unit_ID, randomCode)
                handleDataChangeBlur(code_id, randomCode)
            }
    }

    return (
        <div>

            <h1>Apartman kódok</h1>

        <div style={{display: 'flex', width: '100%'}} >
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
            <div style={{ padding: '5px', width: '100%', fontWeight: 'bold' }}>
            rövid név
            </div>

            <div style={{ padding: '5px', width: '100%', fontWeight: 'bold' }}>
            kód
            </div>

            <div style={{ padding: '5px', width: '100%', fontWeight: 'bold' }}>
            kulcsszéf kódja
            </div>
            <div style={{ padding: '5px', width: '100%', fontWeight: 'bold' }}>            
            </div>
          </div>
        </div>



{apartmentUnits.map((apartment, index) => {
    return (
        <div
            key={index}
            style={{ display: 'flex', width: '100%', }}
        >

            <div
                key={index}
                style={{ display: 'flex', flexDirection: 'row', width: '100%', }}
            >
                <div style={{ padding: '5px', width: '100%' }}>
                    {apartment.short_name}
                </div>

                <div style={{ padding: '5px', width: '100%' }}>
                    {apartment.code}
                </div>

                <div style={{ padding: '5px', width: '100%' }}>
                    <input  type='text' 
                            onChange={e => handleDataChange(apartment.apartment_unit_ID, e.target.value)}
                            value={apartment.key_safe_code}
                            onBlur={e => handleDataChangeBlur(apartment.code_id, e.target.value)}
                            style={{ width: '70px', textAlign: 'center' }}
                            onKeyDown={(e)=>(handleKeyDown(e))}
                    />
                </div>                    

                <div style={{ padding: '5px', width: '100%' }}>
                    <button value={apartment.key_safe_code}
                            onClick={e => generateRandomCode(apartment.apartment_unit_ID, apartment.code_id, e.target.value)} > RANDOM SZÁM </button>
                </div>

            </div>
        </div>
)
})}
                
        </div>
    )

}