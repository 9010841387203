import { BsStickyFill } from "react-icons/bs";

function AddNewCommentButton ({ commentfieldState, setCommentfieldState, row }) {
    
    const handleAddComment = (index) => {
        if(commentfieldState.show) {
            setCommentfieldState({show:false, rowIndex:null});
        } else {
            setCommentfieldState({show:true, rowIndex:index});
        }
      };

return(
    <span className="note_icon">
    <BsStickyFill onClick={() => {handleAddComment(Number(row.id)) }} />              
    </span>
)

    }
    export default AddNewCommentButton;