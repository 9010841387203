import React, { useState, useEffect, useRef  } from "react";
import Calendar from 'react-calendar';

export const AddNewCleaningTask = () => {

    let cachedApartmentsData = JSON.parse(localStorage.getItem('apartmentUnitsCached'));
    let cachedCleanersData = JSON.parse(localStorage.getItem('cleanersCached'));
    let cachedCleaningPrices = JSON.parse(localStorage.getItem('cleaningPricesCached'));

    const [selectedApartment, setSelectedApartment] = useState(0);
    const [selectedCleaner, setSelectedCleaner] = useState(0);
    const [selectedCleaningTask, setSelectedCleaningTask] = useState(0);
    const [price, setPrice] = useState(0);
    const [comment, setComment] = useState('');
    const [datePickerVisibility, setDatePickerVisibility] = useState(false);
    const [queryDate, setQueryDate] = useState({});
    const [pickedDate, setPickedDate] = useState(new Date());

    const submitButton = useRef(null);
    let cleaningTaskForTheCurrentApartment = [];

    useEffect(() => {
        const arrivalsOnThisDayInDate = new Date(pickedDate);

        const options = { weekday: 'long' };
        const dayName = arrivalsOnThisDayInDate.toLocaleDateString('hu-HU', options);

        arrivalsOnThisDayInDate.setMinutes(arrivalsOnThisDayInDate.getMinutes() - arrivalsOnThisDayInDate.getTimezoneOffset()); 
        const arrivalsOnThisDayString = arrivalsOnThisDayInDate.toISOString().slice(0,10);
        setQueryDate({date: arrivalsOnThisDayString, dayName: dayName});
      }, [pickedDate])
     
    useEffect(() => {
        if(parseInt(selectedApartment) !== 0 && parseInt(selectedCleaner) !== 0 && parseInt(selectedCleaningTask) !== 0 && parseInt(price) !== 0){
            submitButton.current.disabled = false;
        } else {
            submitButton.current.disabled = true;
        }
    }, [selectedApartment, selectedCleaner, selectedCleaningTask, price])


    const changer = (pickedDateValue) => {
        setPickedDate(pickedDateValue)
        setDatePickerVisibility(false)
    }


    const dayChanger = (direction = 0) => {  
      const newDate = new Date(pickedDate);
      const diff = direction === 0 ? newDate.setDate(newDate.getDate() - 1) : newDate.setDate(newDate.getDate() + 1)
      changer(new Date(diff))
    }

    if(selectedApartment !== 0){
         cleaningTaskForTheCurrentApartment = cachedCleaningPrices.filter((task) => task.apartment_unit_id === selectedApartment);
    }

const handleCleaningTaskChange = (event) => {
    setSelectedCleaningTask(event.target.value);
    if(event.target.value !== '0'){
    setPrice(cleaningTaskForTheCurrentApartment.find((task) => task.cleaning_ID === event.target.value).price);
    }
}

const handleAddNewCleaningTask = () => {
    let commentToSave = comment ? comment : '-';
    let url = process.env.REACT_APP_BACKEND_LINK + "/addnewcleaningtask.php";
    let bodyText = `apartment_unit_ID=${selectedApartment}&cleaning_ID=${selectedCleaningTask}&employee_ID=${selectedCleaner}&price=${price}&date=${queryDate.date}&comment=${commentToSave}`;
    
    fetch(url, {
        method: "POST",
        headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: bodyText
        })
    .then((res) => res.json() )
    .then(response => {
        setSelectedCleaningTask(0);
        setSelectedCleaner(0);
        setPrice(0);
        setComment('');
        setSelectedApartment(0);
    })
    .catch(error => console.log(error));
}

const handleApartmentChange = (event) => {
    setSelectedApartment(event.target.value);
        if(event.target.value === '0'){
            setSelectedCleaningTask(0);
            setSelectedCleaner(0);
            setPrice(0);
        }
}

    return(
        <div>
            <h1>Új takarítás hozzáadása</h1>

            <div>
                <>
                    {datePickerVisibility && (
                            <Calendar onChange={(value, event)=> {changer(value)}}  defaultValue={pickedDate} locale="hu-HU" />
                    )}
                        <button onClick={() => dayChanger()} >ELŐZŐ nap</button>
                        <button onClick={() => datePickerVisibility ? setDatePickerVisibility(false) : setDatePickerVisibility(true)} >dátumválasztó</button>
                        <button onClick={() => dayChanger(1)} >KÖVETKEZŐ nap</button>
                        <button onClick={() => changer(new Date())} >MAI NAPRA UGRÁS</button>
                        
                        {queryDate.date}
                        - 
                        {queryDate.dayName}
                </>            
            </div>

            <div>
                <select value={selectedApartment} 
//                        onChange={(event) => setSelectedApartment(event.target.value)}
                        onChange={handleApartmentChange}
                >
                    <option value='0'>válassz apartmant</option>
                    {cachedApartmentsData.map((apartment, index) => (
                        <option key={index+1} value={apartment.apartment_unit_ID}>
                        {apartment.short_name}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <select value={selectedCleaner} onChange={(event) => setSelectedCleaner(event.target.value)}>
                    <option value='0'>válassz takarítót</option>
                    {cachedCleanersData.map((cleaner, index) => (
                        <option key={index+1} value={cleaner.employee_ID}>
                        {cleaner.name}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <select value={selectedCleaningTask} 
                        onChange={handleCleaningTaskChange}
                        disabled={!selectedApartment} 
                >
                    <option value='0'>válassz takarítást</option>
                    {cleaningTaskForTheCurrentApartment.map((cleaningTask, index) => (
                        <option key={index+1} value={cleaningTask.cleaning_ID}>
                        {cleaningTask.cleaning_name}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <input type="text"
                            placeholder="ár"
                            value={price}
                            onChange={(event) => setPrice(event.target.value)}
                            disabled={!selectedCleaningTask}
                    />
            </div>

            <div>
                <textarea   rows="4" 
                            cols="50"
                            value={comment}
                            onChange={(event)=>(setComment(event.target.value))}
                />

            </div>
            <div>
                <button onClick={handleAddNewCleaningTask} ref={submitButton}>mentés</button>
            </div>
        </div>
    )
}
export default AddNewCleaningTask;


