import { Outlet, Navigate } from "react-router-dom"

export const PrivateRoutes = () => {

   let auth = localStorage.getItem('menu');
   
    return(
        auth !== null ? <Outlet/> : <Navigate to="/login" />
    )  
}

export default PrivateRoutes;