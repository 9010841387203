import ShiftCalendar from "./components/ShiftCalendar"
import HostCalendar from "./components/HostCalendar"

export function Payment(){
    
    let userName = localStorage.getItem('name');
    let userMenu = localStorage.getItem('menu');

    let PaymentCalendar;

    if (userMenu === "1") {
        PaymentCalendar = <HostCalendar />
    }
    if (userMenu === "2") {
        PaymentCalendar = <ShiftCalendar />
    }


    return (
    <>
    <h1>{userName} Beosztása</h1>
        {PaymentCalendar}
    </>
    )
}
