import React, { useState  } from "react";

export function Tours() {
  const [activeTab, setActiveTab] = useState("tab1");

  let tabC;
  switch (activeTab) {
    case "tab1":
      tabC = <div>
              <h1>Transzfer árak</h1>
                <ul>
                  <li> 1-4 fő  15000ft (40 EUR)</li>
                  <li> 5-8 fő  20000ft (55 EUR)</li>
                  <li> 9-12 fő  25000ft (70 EUR)</li>
                </ul>
            </div>;
      break;
    case "tab2":
      tabC = <div>
                <h1>sétahajó</h1>
                <ul>
                  <li> 1-4 fő  715000ft (40 EUR)</li>
                  <li> 5-8 fő  820000ft (55 EUR)</li>
                  <li> 9-12 fő  925000ft (70 EUR)</li>
                </ul>
              </div>;
      break;
    case "tab3":
      tabC = <div>
                <h1>buszjegy</h1>
                <ul>
                  <li> 1-4 fő  55000ft (40 EUR)</li>
                  <li> 5-8 fő  620000ft (55 EUR)</li>
                  <li> 9-12 fő  625000ft (70 EUR)</li>
                </ul>
              </div>;
      break;
    case "tab4":
      tabC = <div>
                <h1>ajánlott éttermek</h1>
                <ul>
                  <li> - étterem 1 </li>
                  <li> - étterem 2 </li>
                  <li> - étterem 3 </li>
                  <li> - étterem 4 </li>                                        
                </ul>
              </div>;
      break;
    default:
      tabC = null;
  }

  return (
    <div>
   
    <div className="Tabs">
      <ul className="nav">
        <li
          className={activeTab === "tab1" ? "active" : ""}
          onClick={() => setActiveTab("tab1")}
        >
          Transzfer árak
        </li>
        <li
          className={activeTab === "tab2" ? "active" : ""}
          onClick={() => setActiveTab("tab2")}
        >
          sétahajó
        </li>
        <li
          className={activeTab === "tab3" ? "active" : ""}
          onClick={() => setActiveTab("tab3")}
        >
          busz
        </li>
        <li
          className={activeTab === "tab4" ? "active" : ""}
          onClick={() => setActiveTab("tab4")}
        >
          ajánlott éttermek
        </li>
      </ul>
  
    </div>
      <div className="outlet">{tabC}</div>
      </div>
      );
  
    }

