import React, { useState, useEffect  } from "react";
import GuestsTable from "./GuestsTable";
import Modal from "./Modal";
import GuestPropButton from "./GuestPropButton";
import AddNewCommentButton from "./AddNewCommentButton";
import HostSelector from "./HostSelector";
import ArrivalButton from "./ArrivalButton";
import TimeInputField from "./TimeInputField";

const ArrivingGuestsTable = ({pickedDate, hosts, apartments}) => {

    const [guests, setGuests] = useState([]);
    const [tableTitles, setTableTitles] = useState('a mai nap érkező vendégek');
    const [commentfieldState, setCommentfieldState] = useState({show:false, rowIndex:null});
    const [openRegisterModal, setOpenRegisterModal] = useState({show:false});




    useEffect(() => {
      if (apartments.length !== 0 && hosts.length !== 0) {
        setCommentfieldState({show:false, rowIndex:null});

      const pickedDateInDateFormat = new Date(pickedDate);
      pickedDateInDateFormat.setMinutes(pickedDateInDateFormat.getMinutes() - pickedDateInDateFormat.getTimezoneOffset()); 
      const pickedDateInString = pickedDateInDateFormat.toISOString().slice(0,10)

      const todayInDateFormat = new Date();
      todayInDateFormat.setMinutes(todayInDateFormat.getMinutes() - todayInDateFormat.getTimezoneOffset()); 
      const todayInString = todayInDateFormat.toISOString().slice(0,10)

      let pickedDateObj = new Date(pickedDateInString);
      let todayObj = new Date(todayInString);
            
      let guestStatus = 10;

      if (pickedDateObj < todayObj) {
        console.log('MÚLT');
        setTableTitles('ezen a napon érkezett vendégek')
      } else if (pickedDateObj > todayObj) {
        console.log('JÖVŐ');
        setTableTitles('ezen a napon érkező vendégek')
      } else {
        console.log('JELEN');
        setTableTitles('a mai nap érkező vendégek')
        guestStatus = 0;
      }
      
        let url = process.env.REACT_APP_BACKEND_LINK + "/queryarrivingguests.php";
        let bodyText = `today=${pickedDateInString}&status=${guestStatus}`; 

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: bodyText
          })
          .then((res) => res.json())
          .then((response) => {

            response.forEach((item) => {    //apartment_unit_id átírása short_name-re
              let apartmentName = '';
              apartments.forEach((apartment) => {
                if (apartment.apartment_unit_ID === item.apartment_unit_id) {
                  apartmentName = apartment.short_name;
                }
              })
              item.apartment_unit_id = apartmentName;
            })

            response.forEach((item) => {                                          //commentek hozzáadása
              if(item.commentsArray !== '' && item.commentsArray !== null) {
                let commentsObj = {}
                let commentsArr = item.commentsArray.split('<>');
                let commentsIDsArr = item.commentIDsArray.split('<>');
                let targetsArr = item.targetsArray.split('<>');

                commentsArr.forEach((comment, index) => {
                  commentsObj[commentsIDsArr[index]] = {comment: comment, commentID: commentsIDsArr[index], target: targetsArr[index]};
                })
                item['commentsObj'] = commentsObj;
              } else {
                item['commentsObj'] = {};
              }
                delete item['commentIDsArray'];
                delete item['commentsArray'];
                delete item['targetsArray'];
            })

            //console.log(response);
        
            setGuests(response)
            //console.log('guests: ', guests);
        })

      }
        
      }, [pickedDate, apartments, hosts])



const guestPropButtonClick = (res_ID) => {

  let url = process.env.REACT_APP_BACKEND_LINK + "/queryreservation.php";
  let bodyText = `res_ID=${res_ID}`; 

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: bodyText
  })
  .then((res) => res.json())
  .then((response) => {
    setGuests(guests => {
      const newGuests = [...guests];
      newGuests.forEach((guest) => {
        if (guest.res_ID === res_ID) {
          guest['guests'] = response;
        }
      })
      return newGuests;
    })

    setOpenRegisterModal(openRegisterModal => ({...openRegisterModal, show:true, res_ID:res_ID}))

  })

 // setKey(key + 1);
}



      const columns = [
        {
          Header: "rescode",
          accessor: "rescode",
          disableSortBy: true,
        },    
        {
          Header: "personal infos",
          accessor: "personalinfos",
          disableSortBy: true,
          Cell: ({ row })  => 
          (
                  <GuestPropButton guestPropButtonClick={guestPropButtonClick} 
                                  res_ID={row.original.res_ID}
                                  personal_infos_completeness={row.original.personal_infos_completeness}
                  />  
          )                        


          
          /*
          (
                                 <GuestPropButton row={row} 
                                                  setGuests={setGuests} 
                                                  setOpenRegisterModal={setOpenRegisterModal} 
                                />  )                        
          */                      
        },
        {
          Header: "name",
          accessor: "resname",
          width: 400
        },
        {
          Header: "arriving time",
          accessor: "arrivaltime",
          Cell: ({ row })  => (<TimeInputField row={row} 
                                               setGuests={setGuests} 
                                               action={'arrivaltime'}
                               />   )     

        },
        {
          Header: "departuredate",
          accessor: "departuredate"
        },
        {
          Header: "departure time",
          accessor: "departuretime",
          Cell: ({ row })  => (<TimeInputField row={row} 
                                               setGuests={setGuests} 
                                               action={'departuretime'}
                               />   )     

        },
        {
          Header: "people",
          accessor: "people",
          width: 90,
        },
        {
          Header: "apartment",
          accessor: "apartment_unit_id"
        },
        {
          Header: "host",
          accessor: "host",
          Cell: ({ row })  => { 
            
          //  console.log(row.original.host)
            
            return ( <HostSelector hosts={hosts} 
                                              res_ID={row.original.res_ID} 
                                              rowIndex={row.id}
                                              defaultHost={row.original.host}
                                              defaultTask={row.original.hosttask}
                                /> )}

        },      
        {
          Header: "",
          accessor: "comment",
          disableSortBy: true,
          Cell: ({ row })  => (<AddNewCommentButton commentfieldState={commentfieldState}
                                                    setCommentfieldState={setCommentfieldState}
                                                    row={row}
                               />   )     
        },      
        {
          Header: "button",
          accessor: "button",
          disableSortBy: true,
          Cell: ({ row })  => ( <ArrivalButton row={row} 
                                               setGuests={setGuests} 
                                />   )
        }      
      ];
     

console.log(guests)
      return ( 
        <div>

           {openRegisterModal.show && openRegisterModal.show && (
              <Modal
                openRegisterModal={openRegisterModal}
                setOpenRegisterModal={setOpenRegisterModal}
                setGuests={setGuests}
                guests={guests}   
              />
           )}


          <h2>{tableTitles}</h2>
                  <GuestsTable
                      columns={columns}
                      data={guests}   
                      commentfieldState={commentfieldState}  
                      setCommentfieldState={setCommentfieldState}   
                      setGuests={setGuests}
                  />
        </div>
      )
}

export default ArrivingGuestsTable;

